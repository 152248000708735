import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Field,
  FieldArray,
  getFormValues,
  reduxForm,
  reset,
  change,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  ACTIVE,
  addVenueForm,
  alertNew,
  venueFormName,
  modalUpdateServerErrorMessage,
  statePropType,
} from "../../constants";
import {
  facilityOtherAdditionalInsuredZipCode,
  atLeastOneGLMustBePicked,
  contactEmailsUnique,
} from "../../Helpers/validators";
import StateField from "./FormElements/StateField";
import { normalizeZip } from "../../normalizer";
import { VENUES_PAGE, getUrl } from "../../Helpers/URLParser";
import {
  setVenueStatusMessage,
  errorResponse,
  showVenueStatusAlert,
} from "../../Actions/actions";
import VenueService from "../../Helpers/VenueService";
import API from "../../Helpers/API";
import CountryField from "./FormElements/CountryField";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import GLChecklist from "./FormElements/GLChecklist";
import PlacesFields from "./FormElements/PlacesFields";
import { TextInput, CheckboxInput, Button } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
import FacilityService from "../../Helpers/FacilityService";
import EventTypesOrder from "./EventTypesOrder";
import { ExclusiveAccessClaimContext } from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";
const { required } = validators;

const allFormValues = getFormValues(addVenueForm);

const SectionHeader = ({ text }) => (
  <div className="card-header bg-transparent">
    <strong>{text}</strong>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const AddVenue = ({
  facilityId,
  pristine,
  valid,
  states,
  goToSearchPage,
  addVenue,
  eventTypes,
  handleSortEnd,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [nextVenueNumber, setNextVenueNumber] = useState(undefined);

  const { hasChecked, claimValid } = useContext(ExclusiveAccessClaimContext);
  const history = useHistory();
  useEffect(() => {
    if (hasChecked && !claimValid) {
      history.push(getUrl(VENUES_PAGE, facilityId));
    }
  }, [hasChecked, claimValid, facilityId, history]);

  useEffect(() => {
    (async () => {
      setNextVenueNumber(
        (await new FacilityService(new API()).getNextVenueNumber(facilityId))
          .data.venueNumber
      );
    })();
  }, [setNextVenueNumber, facilityId]);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">Add New Venue</h3>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Venue"
              className="btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addVenue(handleShowError)}
              disabled={pristine || !valid}
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        {nextVenueNumber && (
          <div className="mb-2">
            Next available venue number: {nextVenueNumber}
          </div>
        )}
        <div className="card w-100">
          <SectionHeader text="Venue Information" />
          <div className="card-body mw--635">
            <div className="label__subtext">
              As it should appear on certificate of insurance
            </div>
            <Field
              component={TextInput}
              validate={required}
              name="venueAddress.companyName"
              subtext="Use the actual location of the event and not a mailing address or post
              office (P.O.) box."
              label="Venue Name"
              ariaLabel="Name"
              subtextClassName="mb-2"
              errorClassName="d-inline ml-2"
              inputClassName="form-control-lg mb-4"
            />
            <Field
              component={TextInput}
              validate={required}
              name="venueAddress.address1"
              label="Street address"
              ariaLabel="Address"
              inputClassName="form-control-lg mb-2"
            />
            <div className="form-row">
              <Field
                component={TextInput}
                validate={required}
                name="venueAddress.city"
                label="City"
                ariaLabel="City"
                className="col-sm"
                inputClassName="form-control-lg"
                wrapperClassName="col-sm"
              />
              <Field
                name="venueAddress.state"
                component={StateField}
                type="select"
                states={states}
                validate={required}
              />
              <Field
                component={TextInput}
                validate={[required, facilityOtherAdditionalInsuredZipCode]}
                normalize={normalizeZip}
                name="venueAddress.zip"
                label="Zipcode"
                ariaLabel="Zip"
                inputClassName="form-control-lg"
                wrapperlassName="col-sm"
              />
              <div hidden>
                <Field
                  name="venueAddress.country"
                  component={CountryField}
                  type="select"
                  input={{ disabled: true }}
                  hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <FieldArray name="places" component={PlacesFields} />

        <div className="card mt-4">
          <SectionHeader text="Required Venue Coverage" />
          <div className="card-body mw--635">
            <FieldArray
              name="glLimits"
              component={GLChecklist}
              validate={atLeastOneGLMustBePicked}
            />
            <div className="font-weight-bold mt-4 mb-3">
              Additional Coverage
            </div>
            <Field
              name="damageToRentedProperty"
              component={CheckboxInput}
              type="checkbox"
              className="mb-3"
              labelClassName="pl-2"
              label="Damage to Rented Premises - $1,000,000"
            />
            <Field
              name="liquorLiability"
              component={CheckboxInput}
              className="mb-3"
              labelClassName="pl-2"
              type="checkbox"
              label="Liquor Liability - $1,000,000"
            />
            <Field
              name="hideHostLiquor"
              component={CheckboxInput}
              type="checkbox"
              className=" mb-3"
              labelClassName="pl-2"
              label="Hide Host Liquor on COI?"
            />
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header bg-transparent">
            <strong>Additional Insured</strong>
            <span className="small font-italic mx-2">(optional)</span>
          </div>
          <div className="card-body mw--635">
            <div className="label__subtext">
              As it should appear on all certificates of insurance
            </div>
            <Field
              component={TextInput}
              name="additionalInsured.companyName"
              label="Name"
              ariaLabel="Company name"
              inputClassName="form-control-lg"
            />
            <Field
              component={TextInput}
              name="additionalInsured.address1"
              label="Street address"
              ariaLabel="Address"
              wrapperClassName="mb-0"
              inputClassName="form-control-lg mb-2"
            />
            <Field
              component={TextInput}
              name="additionalInsured.address2"
              ariaLabel="Address"
              inputClassName="form-control-lg mb-2"
            />
            <div className="form-row">
              <Field
                component={TextInput}
                name="additionalInsured.city"
                label="City"
                ariaLabel="City"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                name="additionalInsured.state"
                component={StateField}
                type="select"
                states={states}
              />
              <Field
                component={TextInput}
                validate={[facilityOtherAdditionalInsuredZipCode]}
                normalize={normalizeZip}
                name="additionalInsured.zip"
                label="Zipcode"
                ariaLabel="Zip"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
              <div hidden>
                <Field
                  name="additionalInsured.country"
                  component={CountryField}
                  type="select"
                  input={{ disabled: true }}
                  hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <EventTypesOrder
          editing={true}
          eventTypes={eventTypes}
          handleSortEnd={handleSortEnd}
        />

        <FieldArray name="contacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        category={venueFormName}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

AddVenue.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addVenue: PropTypes.func.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSortEnd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const hasVenueContacts =
    state.form.addVenue.values && state.form.addVenue.values.contacts;
  return {
    facilityId: state.facilities.currentFacility.id,
    states: state.app.states,
    contacts: hasVenueContacts ? state.form.addVenue.values.contacts : [],
    eventTypes: state.app.eventTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: (facilityId) => {
    dispatch(reset(addVenueForm));
    dispatch(ConnectedRouter.push(getUrl(VENUES_PAGE, facilityId)));
  },
  addVenue: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const state = getState();
      const values = allFormValues(state);
      const facilityId = getState().facilities.currentFacility.id;
      const data = {
        status: ACTIVE, // TODO: temporary, anticipate status selector in future
        address: values.venueAddress,
        hostLiquor: true,
        damageToRentedProperty: values.damageToRentedProperty,
        liquorLiability: values.liquorLiability,
        hideHostLiquor: values.hideHostLiquor,
        contacts: values.contacts,
        otherInsured: values.additionalInsured,
        eventTypes: values.eventTypes || state.app.eventTypes,
        eventTileShowCount: values.eventTileShowCount,
      };

      const places = [];
      if (values.places) {
        values.places.map((place) => {
          return places.push({
            description: place.description,
            placeId: place.placeId.value,
          });
        });
        data.places = places;
      }

      const gl = [];
      if (values.glLimits[0]) {
        gl.push(1000000, 2000000);
      }
      if (values.glLimits[1]) {
        gl.push(3000000);
      }
      if (values.glLimits[2]) {
        gl.push(4000000);
      }
      if (values.glLimits[3]) {
        gl.push(5000000);
      }
      data.glLimits = gl;

      return new VenueService(new API())
        .postAddNewVenueDetails(data, facilityId)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showVenueStatusAlert(true));
            dispatch(
              setVenueStatusMessage(alertNew(values.venueAddress.companyName))
            );
            goToSearchPage(facilityId);
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  handleSortEnd: (events) => {
    dispatch(change(addVenueForm, "eventTypes", events));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  addVenue: (handleShowError) =>
    dispatchProps.addVenue(dispatchProps.goToSearchPage, handleShowError),
  goToSearchPage: () => dispatchProps.goToSearchPage(ownProps.facilityId),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addVenueForm,
    initialValues: {
      venueAddress: {
        companyName: "",
        address1: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      places: [],
      glLimits: [true, false, false, false],
      damageToRentedProperty: false,
      liquorLiability: false,
      hideHostLiquor: false,
      additionalInsured: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      contacts: [],
      eventTileShowCount: 12,
    },
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(AddVenue);
