import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { getUrl, ADD_USER_PAGE } from "../../Helpers/URLParser";
import {
  loadUsers,
  deleteUser,
  setDeleteUserError,
} from "../../Actions/actions";
import { adminUserPropType } from "../../Helpers/UserModel";
import ResultsTable from "./ResultsTable";
import ModalRemove from "./ModalRemove";
import { Pagination } from "@jauntin/react-ui";
import { PERMISSION_MANAGE_USERS, paginationProps } from "../../constants";

const AdminUsers = ({
  users,
  pagination,
  currentUser,
  isLoading,
  error,
  onMount,
  onUserDelete,
  dismissError,
  onGoToPage,
}) => {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const removeUser = (user) => {
    dismissError();
    onUserDelete(user);
    setShowModalRemove(false);
  };

  return currentUser ? (
    <div className="scroll-part">
      <div className="users-container p-5">
        <div className="d-flex align-items-center mb-5">
          <h1 className="mb-0 mr-5">Admins</h1>
          <NavLink
            to={getUrl(ADD_USER_PAGE)}
            className="btn btn-primary px-4 ml-auto"
          >
            ADD ADMIN
          </NavLink>
        </div>
        {error ? (
          <Alert variant="danger" onClose={() => dismissError()} dismissible>
            <Alert.Heading>You got an error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        ) : null}
        <ResultsTable
          users={users}
          currentUserId={currentUser.id}
          isLoading={isLoading}
          actionsColumn={currentUser.permissions.includes(
            PERMISSION_MANAGE_USERS
          )}
          onRemove={(user) => {
            setShowModalRemove(true);
            setUserToRemove(user);
          }}
        />

        <ModalRemove
          user={userToRemove}
          show={showModalRemove}
          handleRemove={removeUser}
          handleClose={() => setShowModalRemove(false)}
        />
      </div>
      <div className="d-flex justify-content-end px-5 pb-5">
        <Pagination pagination={pagination} goToPage={onGoToPage} />
      </div>
    </div>
  ) : null;
};

AdminUsers.propTypes = {
  users: PropTypes.arrayOf(adminUserPropType).isRequired,
  pagination: paginationProps.isRequired,
  currentUser: adminUserPropType,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onMount: PropTypes.func.isRequired,
  onUserDelete: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  dismissError: PropTypes.func.isRequired,
};

AdminUsers.defaultProps = {
  error: "",
};

const mapStateToProps = (state) => ({
  users: state.users.usersTableData,
  currentUser: state.app.currentUser,
  isLoading: state.users.usersLoading,
  error: state.users.deleteUserError,
  pagination: state.users.usersPagination,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(loadUsers());
  },
  onUserDelete: (user) => {
    dispatch(deleteUser(user));
  },
  onGoToPage: (newPage) => {
    dispatch(loadUsers(newPage));
  },
  dismissError: () => {
    dispatch(setDeleteUserError(""));
  },
});

const AdminUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsers);

export default AdminUsersContainer;
