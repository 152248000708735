import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card } from "react-bootstrap";

const CardCollapse = ({
  headerContent,
  children,
  className = "w-100 my-4",
}) => {
  const [expandCard, setExpandCard] = useState(false);

  return (
    <Card className={className}>
      <Card.Header
        className={`bg-transparent d-flex justify-content-between ${
          !expandCard ? "border-bottom-0" : ""
        }`}
      >
        <div className="my-auto">{headerContent} </div>
        <div className="my-auto">
          <Button variant="link" onClick={() => setExpandCard(!expandCard)}>
            <i
              className={`fal icon--large ${
                expandCard ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </Button>
        </div>
      </Card.Header>
      <Card.Body style={{ display: expandCard ? "block" : "none" }}>
        {children}
      </Card.Body>
    </Card>
  );
};

CardCollapse.propTypes = {
  headerContent: PropTypes.node, // React JSX element
  children: PropTypes.node, // React JSX element
  className: PropTypes.string,
};

export default CardCollapse;
