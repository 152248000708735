import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, reset, initialize, getFormValues } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { compose } from "redux";
import {
  modalUpdateServerErrorMessage,
  producerFormName,
} from "../../constants";
import Header from "./Header";
import Status from "./Status";
import Information from "./Information";
import Contact from "./Contact";
import { producerPropType } from "../../Helpers/ProducerModel";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import ProducerService from "../../Helpers/ProducerService";
import API from "../../Helpers/API";
import {
  checkIsValidCommissionId,
  errorResponse,
  getProducer,
  setEditingAction,
  logout as logoutAction,
} from "../../Actions/actions";
import ModalUpdateError from "../../Components/ModalUpdateError";
import RouteLeavingEditGuard from "../../Components/RouteLeavingEditGuard";
import { getUrl, LOGIN_PAGE } from "../../Helpers/URLParser";
import { contactEmailsUnique } from "../../Helpers/validators";

const allFormValues = getFormValues(producerFormName);

const Producer = ({
  editReset,
  producer,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  checkAndSetValidCommissionId,
  navToPath,
  logout,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validCommissionId, setValidCommissionId] = useState(true);
  const [hasCheckedCommissionId, setHasCheckedCommissionId] = useState(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");

  return (
    <>
      <Header
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
        hasCheckedCommissionId={hasCheckedCommissionId}
        validCommissionId={validCommissionId}
        producer={producer}
      />

      <div className="content__body">
        <Status editing={editing} producer={producer} />
        <Information
          editing={editing}
          producer={producer}
          validCommissionId={validCommissionId}
          setValidCommissionId={setValidCommissionId}
          hasCheckedCommissionId={hasCheckedCommissionId}
          setHasCheckedCommissionId={setHasCheckedCommissionId}
          checkAndSetValidCommissionId={checkAndSetValidCommissionId}
        />
        <Contact editing={editing} producer={producer} />
      </div>

      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />

      <ModalUpdate
        show={showModalUpdate}
        action={() => update(handleCloseUpdate, handleShowError, setEditing)}
        handleClose={handleCloseUpdate}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Producer.propTypes = {
  editReset: PropTypes.func.isRequired,
  producer: producerPropType.isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  checkAndSetValidCommissionId: PropTypes.func.isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  producer: state.producers.currentProducer,
  contacts: state.producers.values
    ? state.producers.values.producerContacts
    : [{}],
  valid: state.producers.valid,
  editing: state.producers.editing,
});

const mapDispatchToProps = (dispatch) => ({
  editReset: (producer) => {
    dispatch(reset(producerFormName));
    dispatch(
      initialize(producerFormName, {
        producerStatus: producer.status,
        producerName: producer.name,
        producerCommissionId: producer.commissionId,
        producerContacts: producer.producerContacts,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  checkAndSetValidCommissionId: (
    id,
    setHasCheckedCommissionId,
    setValidCommissionId
  ) =>
    dispatch(
      checkIsValidCommissionId(
        id,
        setHasCheckedCommissionId,
        setValidCommissionId
      )
    ),
  update: (handleCloseUpdate, handleShowError, setEditing) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const state = getState();
      const producer = state.producers.currentProducer;
      const { id } = producer;
      const data = {
        id,
        status: values.producerStatus,
        name: values.producerName,
        commissionId: values.producerCommissionId,
        contacts: values.producerContacts,
      };
      return new ProducerService(new API())
        .putProducerEditDetails(data)
        .then((response) => {
          if (response.status === 204) {
            handleCloseUpdate();
            dispatch(getProducer(id));
            setEditing(false);
          }
        })
        .catch((err) => {
          handleCloseUpdate();
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.producer),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: producerFormName,
    validate: (values) => ({
      ...contactEmailsUnique(values, "producerContacts"),
    }),
  })
)(Producer);
