import React, { useContext } from "react";
import { initialize, reset } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { format } from "date-fns";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { getUrl, VENUE_PAGE } from "../../Helpers/URLParser";
import {
  editVenueInitialFormValues,
  venuePropType,
} from "../../Helpers/VenueModel";
import { setEditingAction } from "../../Actions/actions";
import {
  venueFormName,
  modalUpdateServerErrorMessage,
  VENUE_REASSIGN_SEARCH,
} from "../../constants";
import ModalUpdateError from "../../Components/ModalUpdateError";
import { Button } from "@jauntin/react-ui";
import { ExclusiveAccessClaimContext } from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";

const PopoverContent = ({
  facilityId,
  venue,
  handleShow,
  setSelectedVenue,
  editReset,
  setEditing,
  setModalState,
}) => {
  const history = useHistory();
  const { checkClaim } = useContext(ExclusiveAccessClaimContext);
  return (
    <>
      <div className="mb-2">
        <Button
          className="popover-item__btn"
          onClick={() =>
            checkClaim(() => {
              editReset(venue);
              setEditing(true);
              history.push(getUrl(VENUE_PAGE, facilityId, venue.id));
            }) || document.body.click()
          }
          text={
            <>
              <i className="fal fa-edit mr-2 col-1 p-0 popover-item__icon" />
              <span className="col-auto p-0">Edit venue details</span>
            </>
          }
        />
      </div>
      <div>
        <Button
          className="popover-item__btn"
          text={
            <>
              <i className="fal fa-arrow-to-right mr-2 col-1 p-0 popover-item__icon" />
              <span className="col-auto p-0">Assign to Facility</span>
            </>
          }
          onClick={() => {
            setModalState(VENUE_REASSIGN_SEARCH);
            handleShow();
            setSelectedVenue(venue);
            document.body.click();
          }}
        />
      </div>
    </>
  );
};

PopoverContent.propTypes = {
  facilityId: PropTypes.number.isRequired,
  venue: venuePropType.isRequired,
  handleShow: PropTypes.func.isRequired,
  setSelectedVenue: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
};

const SearchResults = ({
  venuesList,
  facilityId,
  setSelectedFacilityResult,
  setSelectedVenue,
  editReset,
  setEditing,
  handleShowReassign,
  showModalError,
  handleCloseError,
  setModalState,
}) => {
  return (
    <>
      <table className="table subsection-table mt-4">
        <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Venue Information</th>
            <th scope="col">Status</th>
            <th scope="col">Date Modified</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {venuesList.map((venue) => (
            <tr key={venue.id}>
              <td>
                <Link to={getUrl(VENUE_PAGE, facilityId, venue.id)}>
                  {venue.venueCode}
                </Link>
              </td>
              <td>
                <Link to={getUrl(VENUE_PAGE, facilityId, venue.id)}>
                  {venue.address.companyName}
                </Link>
                <div>{venue.address.address1}</div>
                {venue.address.address2 && <div>{venue.address.address2}</div>}
                <div>{`${venue.address.city}, ${venue.address.state} ${venue.address.zip}`}</div>
              </td>
              <td className="text-capitalize">
                {venue.status}
                {venue.blockedAt && (
                  <span className="subsection-table__badge subsection-table__badge--blocked">
                    Blocked
                  </span>
                )}
              </td>
              <td>{format(new Date(venue.updated), "MM/dd/yy")}</td>
              <td>
                <PopoverTrigger
                  content={
                    <PopoverContent
                      venue={venue}
                      handleShow={handleShowReassign}
                      setSelectedVenue={setSelectedVenue}
                      editReset={editReset}
                      setEditing={setEditing}
                      facilityId={facilityId}
                      setResults={setSelectedFacilityResult}
                      setModalState={setModalState}
                    />
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

SearchResults.propTypes = {
  venuesList: PropTypes.any.isRequired,
  facilityId: PropTypes.number.isRequired,
  setSelectedFacilityResult: PropTypes.func.isRequired,
  setSelectedVenue: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  handleShowReassign: PropTypes.func.isRequired,
  showModalError: PropTypes.bool.isRequired,
  handleCloseError: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (venue) => {
    const { otherInsured, address } = venue;

    const gl = [false, false, false, false];
    venue.glLimits.forEach((limit) => {
      if (limit.id === 1 || limit.id === 2) {
        gl[0] = true;
      } else {
        gl[limit.id - 2] = true;
      }
    });

    const googlePlaces = [];
    venue.places.map((place) => {
      return googlePlaces.push({
        description: place.description,
        placeId: { label: place.placeId, value: place.placeId },
      });
    });
    dispatch(reset(venueFormName));
    dispatch(
      initialize(
        venueFormName,
        editVenueInitialFormValues(
          venue,
          address,
          googlePlaces,
          gl,
          otherInsured
        )
      )
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
