import React from "react";
import { ACTIVE } from "../../../constants";
import { FieldInputProps } from "../../../Helpers/ReduxFormPropTypes";

const StatusField = ({ input }) => (
  <>
    <label htmlFor={input.name} className="label mr-2">
      Status
    </label>
    <select
      {...input}
      id={input.name}
      className="custom-select custom-select-lg w--150"
    >
      <option value={ACTIVE}>Active</option>
    </select>
  </>
);
StatusField.propTypes = {
  input: FieldInputProps.isRequired,
};

export default StatusField;
