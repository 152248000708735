import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {
  Field,
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { Card, Form, Collapse } from "react-bootstrap";
import {
  errorResponse,
  setUserRoleSelectionVisible,
  loadUsers,
  userSaveSuccess,
} from "../../Actions/actions";
import API from "../../Helpers/API";
import UserService from "../../Helpers/UserService";
import { newUserForm, ROLE_ADMIN, ROLE_SUPERADMIN } from "../../constants";
import { adminUserPropType } from "../../Helpers/UserModel";
import { TextInput, EmailInput, Button } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
const { normalizeEmail } = normalizers;
const { required, email } = validators;

const userService = new UserService(new API());

const AddNewUserForm = ({
  valid,
  errors,
  newUser,
  roleCardVisible,
  selectedRole,
  handleSubmit,
  setRoleCardVisible,
}) => {
  const isRoleSelectionDisabled =
    !!errors.email || !!errors.firstName || !!errors.lastName;
  const isRoleSelected = (role) => role === selectedRole;

  return (
    <div className="users-form-container">
      <Form className="form--mb-100" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col-lg-10 col-sm-12">
            <div className="form-row">
              <Field
                component={EmailInput}
                validate={[required, email]}
                normalize={normalizeEmail}
                name="email"
                label=""
                placeholder="Email"
                className="form-group__user-input col-sm-12 col-lg-4"
                errorClassName="form-error__user-input"
                inputClassName="form-control__user-input"
              />
              <Field
                component={TextInput}
                validate={required}
                name="firstName"
                placeholder="First Name"
                ariaLabel="First Name"
                className="form-group__user-input col-sm-6 col-lg-4"
                errorClassName="form-error__user-input"
                inputClassName="form-control__user-input"
              />
              <Field
                component={TextInput}
                validate={required}
                name="lastName"
                placeholder="Last Name"
                ariaLabel="Last Name"
                className="form-group__user-input col-sm-6 col-lg-4"
                errorClassName="form-error__user-input"
                inputClassName="form-control__user-input"
              />
            </div>
          </div>
          <div className="col-lg-2">
            <Button
              disabled={isRoleSelectionDisabled}
              onClick={() => setRoleCardVisible(true)}
              className="btn btn-primary"
              text="Admin Type"
            />
          </div>
        </div>
        <div className="type-container mb-4">
          {!isRoleSelectionDisabled ? (
            <Collapse in={roleCardVisible}>
              <Card>
                <Card.Body className="px-1 py-3">
                  <div className="d-flex justify-content-between">
                    <div
                      className={`font-weight-bold ${
                        isRoleSelected(ROLE_SUPERADMIN) &&
                        "color-blue-secondary"
                      }`}
                    >
                      Add/Remove other admin(s), add/remove users, view/edit
                      user profile, policies, add notes to policies + email
                      PDF&apos;s.
                    </div>
                    <Form.Group className="form-group--w-160">
                      <div className="radio-large custom-control custom-radio custom-control-inline">
                        <Field
                          id="userRoleSuperadmin"
                          name="role"
                          validate={required}
                          component="input"
                          className="custom-control-input"
                          type="radio"
                          value="Super Admin"
                        />
                        <label
                          title=""
                          htmlFor="userRoleSuperadmin"
                          className="custom-control-label"
                        >
                          Super Admin
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className={
                        isRoleSelected(ROLE_ADMIN)
                          ? "color-blue-secondary"
                          : undefined
                      }
                    >
                      View/edit user profile, policies, add notes to policies +
                      email PDF&apos;s.
                    </div>
                    <Form.Group className="form-group--w-160">
                      <div className="radio-large custom-control custom-radio custom-control-inline">
                        <Field
                          id="userRoleAdmin"
                          name="role"
                          validate={required}
                          component="input"
                          className="custom-control-input"
                          type="radio"
                          value="Admin"
                        />
                        <label
                          title=""
                          htmlFor="userRoleAdmin"
                          className="custom-control-label"
                        >
                          Admin
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            </Collapse>
          ) : null}
          {!roleCardVisible && newUser ? (
            <div className="pt-4 new-user-success">
              <p className="text-success">{newUser.name} has been added!</p>
              <p>
                To add more admins simply type in the text box provided above.
              </p>
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-end">
          <Button
            text="Add"
            type="submit"
            className={`btn btn-primary ${
              !roleCardVisible ? "invisible" : "visible"
            }`}
            disabled={!valid}
          />
        </div>
      </Form>
    </div>
  );
};

AddNewUserForm.propTypes = {
  newUser: adminUserPropType,
  roleCardVisible: PropTypes.bool.isRequired,
  setRoleCardVisible: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedRole: PropTypes.string,
  valid: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

AddNewUserForm.defaultProps = {
  newUser: null,
  selectedRole: null,
};

const mapStateToProps = (state) => ({
  errors: getFormSyncErrors(newUserForm)(state),
  newUser: state.users.newUser,
  roleCardVisible: state.users.roleSelectionVisible,
  selectedRole: formValueSelector(newUserForm)(state, "role"),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (data) => {
    try {
      const { data: user } = await userService.postAddNewUser(data);
      dispatch(userSaveSuccess(user));
      dispatch(loadUsers());
    } catch (err) {
      dispatch(errorResponse(err));
      const message = err.response?.data?.message || "User creation failed.";
      const errors = {};
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).forEach((error) => {
          [errors[error]] = err.response.data.errors[error];
        });
      }
      throw new SubmissionError({ ...errors, _error: message });
    }
  },
  setRoleCardVisible: (visible) => {
    dispatch(setUserRoleSelectionVisible(visible));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: newUserForm,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    },
  })
)(AddNewUserForm);
