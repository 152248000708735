import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import {
  DateRangePicker,
  CustomDatePicker,
  TextInput,
} from "@jauntin/react-ui";
import { coveragePropType } from "../../Helpers/CoverageModel";
import ButtonCollapse from "../../Components/ButtonCollapse";
import DateList from "./DateList";
import EditDateList from "./EditDateList";
import { currencyFromInt } from "../../Helpers/CurrencyHelper";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import {
  atLeastOneDayMustBePicked,
  requiredDates,
} from "../../Helpers/validators";
import EventFrequencyField from "./FormElements/EventFrequencyField";
import DaysOfWeekField from "./FormElements/DaysOfWeekField";
import GuestsField from "../../Components/GuestsField";
import { normalizeEventName } from "../../normalizer";
import {
  FOOD_VENDOR,
  GOODS_VENDOR,
  PERFORMER,
  EXHIBITOR,
} from "../../constants";
import listToStringDays from "../../Helpers/ListToString";
import { getAttendanceRange } from "../../Helpers/AttendenceRangeHelper";
import { getPolicyTotalEventAttendees } from "../../Helpers/StoreSelectors";
import { validators, dateHelpers } from "@jauntin/utilities";
import {
  policyDisabledDatesForRangePicker,
  policyDisabledDatesForCustomPicker,
} from "../../Helpers/DateHelpers";
const { required, notZero, arrayNotEmpty, minValue } = validators;

const minValue1 = minValue(1);

const maxAttendees = (value, allValues) => {
  return value * allValues.eventDates.length > 5000
    ? "Total event attendance cannot exceed 5000"
    : undefined;
};

const duration = (eventDates, timezone) => {
  const days = eventDates.length;
  let firstDay;
  let lastDay;
  if (days) {
    firstDay = new Date(eventDates[0]);
    lastDay = new Date(eventDates[eventDates.length - 1]);
  } else {
    firstDay = new Date();
    lastDay = new Date();
  }
  if (days === 1) {
    return `1 day long - ${dateHelpers.lettersAndNumbers(firstDay, timezone)}`;
  }
  return `${days} days long - ${dateHelpers.lettersAndNumbers(
    firstDay,
    timezone
  )} to ${dateHelpers.lettersAndNumbers(lastDay, timezone)}`;
};

const providerText = (type, count, frequency, list) => {
  let provider = type;
  const plural = count > 1 ? "s" : "";

  if (type === PERFORMER) {
    provider = `performer${plural}`;
  }
  if (type === GOODS_VENDOR) {
    provider = `vendor${plural} selling goods`;
  }
  if (type === FOOD_VENDOR) {
    provider = `vendor${plural} selling food or beverages`;
  }
  if (type === EXHIBITOR) {
    provider = `exhibitor${plural}`;
  }

  // Check if count is 7:
  if (count === 7) {
    return `There will be 7 or more ${provider}.`;
  }

  // Check if 'everyday' is selected
  if (frequency === "everyday") {
    return `There will be ${count} ${provider} at the event everyday.`;
  }

  // Show detailed provider list
  return `There will be ${count} ${provider} at the event: ${listToStringDays(
    list
  )}.`;
};

const Event = ({
  coverage,
  eventFrequency,
  eventDates,
  eventDateRange,
  editing,
  eventRef,
  expandDateList,
  setExpandDateList,
  deleteDate,
  showListOfDates,
  showDatePicker,
  blockedDays,
  showCustomDatePicker,
  eventNameCounter,
  eventPerformers,
  eventGoods,
  eventFood,
  eventExhibitors,
  performersFrequency,
  goodsVendorsFrequency,
  foodVendorsFrequency,
  exhibitorsFrequency,
  performersList,
  foodVendorsList,
  goodsVendorsList,
  exhibitorsList,
  isPremiumChanged,
}) => {
  const hasDates = eventDates.length > 0;
  const eventTotalGuests = useSelector(getPolicyTotalEventAttendees);

  const disabledDatesRangePicker = useCallback(
    (day) =>
      policyDisabledDatesForRangePicker({
        startDate: eventDateRange.startDate,
        blockedDays,
      })(day),
    [eventDateRange, blockedDays]
  );

  const timezone = useSelector((state) => state.app.timezone);

  const disabledCustomDatePicker = useCallback(
    (day) => policyDisabledDatesForCustomPicker(eventDates)(day),
    [eventDates]
  );

  return (
    <div className="card mt-4" ref={eventRef}>
      <div className="card-header bg-transparent">
        <strong>Event</strong>
      </div>
      <div className="card-body">
        {editing ? (
          <>
            <Field
              component={TextInput}
              validate={[required]}
              normalize={normalizeEventName}
              name="eventName"
              label="Event Name"
              ariaLabel="Event Name"
              maxLength={50}
              inputClassName="form-control-lg mb-1"
              lengthClassName="policy--charCount"
              wrapperClassName="mb-0"
            />
            {(!expandDateList || !hasDates) && (
              <>
                <div className="form-group">
                  <Field
                    component={EventFrequencyField}
                    validate={[notZero]}
                    name="eventFrequencyField"
                    type="select"
                  />

                  {eventFrequency === "weekly" && (
                    <DaysOfWeekField
                      name="daysOfWeekField"
                      validate={atLeastOneDayMustBePicked}
                    />
                  )}

                  {showDatePicker && (
                    <>
                      <div className="mb-2">
                        Include the days required for setup and take down for
                        the event. If the event will extend past midnight,
                        include the following day.
                      </div>
                      <Field
                        component={DateRangePicker}
                        validate={requiredDates}
                        disabledDates={disabledDatesRangePicker}
                        inputClassName="form-control-lg"
                        name="eventDateRange"
                        showErrors={(meta) =>
                          (meta.touched && meta.error) ||
                          (meta.dirty && isPremiumChanged)
                        }
                      />
                    </>
                  )}
                  {showCustomDatePicker && (
                    <>
                      <div className="mb-2">
                        Include the days required for setup and take down for
                        the event. If the event will extend past midnight,
                        include the following day.
                      </div>
                      <div className="d-inline-flex">
                        <Field
                          component={CustomDatePicker}
                          validate={arrayNotEmpty}
                          disabledDates={disabledCustomDatePicker}
                          numberOfMonths={2}
                          name="eventDates"
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {hasDates && showListOfDates && (
              <div className="mb-2">
                <ButtonCollapse
                  clickFunction={() => setExpandDateList(!expandDateList)}
                  showList={expandDateList}
                  title={`${eventDates.length} date(s) selected`}
                />

                {expandDateList && (
                  <EditDateList dates={eventDates} deleteDate={deleteDate} />
                )}
              </div>
            )}
            <div className="form-group mb-0">
              <Field
                component={GuestsField}
                validate={[required, minValue1, maxAttendees]}
                name="eventDailyGuests"
                disabled={false}
                eventTotalGuests={eventTotalGuests}
                eventDates={eventDates}
                type="select"
                isPremiumChanged={isPremiumChanged}
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-row justify-content-between">
              <div className="col-auto">
                {stringHelpers.renterName(coverage)}
                {`'s`}
                {` ${coverage.eventTypeName}`}
              </div>
              <div className="col-auto">
                <strong>
                  <DollarsAndCents
                    currency={currencyFromInt(
                      coverage.quoteBasicCoverageAmount
                    )}
                  />
                </strong>
              </div>
            </div>
            <div className="form-row">
              <div className="col-auto">{coverage.eventName}</div>
            </div>
            {coverage.isOneTime ? (
              <div className="form-row">
                <div className="col-auto">
                  {duration(coverage.eventDates, timezone)}
                </div>
              </div>
            ) : (
              <>
                <div className="form-row">
                  <div className="col-auto">
                    {`Starts on ${dateHelpers.lettersAndNumbers(
                      coverage.eventDateRange.startDate,
                      timezone
                    )} `}
                  </div>
                  <div className="col-auto">
                    {`Ends on ${dateHelpers.lettersAndNumbers(
                      coverage.eventDateRange.endDate,
                      timezone
                    )}`}
                  </div>
                </div>
                <div className="form-row mb-1">
                  <ButtonCollapse
                    clickFunction={() => setExpandDateList(!expandDateList)}
                    showList={expandDateList}
                    title={`${coverage.eventDates.length} date(s) selected`}
                  />

                  {expandDateList && <DateList dates={coverage.eventDates} />}
                </div>
              </>
            )}
            {coverage.eventDailyGuests ? (
              <>
                <div className="form-row">
                  <div className="col-auto">
                    <span className="font-weight-bold">
                      Daily Average Attendance
                    </span>
                    :{" "}
                    <span className="ml-2 mr-3">
                      {coverage.eventDailyGuests}
                    </span>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-auto">
                    <span className="font-weight-bold">Total Attendance</span>:{" "}
                    <span className="ml-2 mr-3">
                      {coverage.eventDailyGuests * coverage.eventDates.length}
                    </span>
                    <span className="font-weight-bold">Attendance Range</span>:{" "}
                    <span className="ml-2 mr-3">
                      {getAttendanceRange(
                        coverage.eventDailyGuests * coverage.eventDates.length
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              // Legacy policies do not have averageDailyAttendance so use groupSize
              <div className="form-row">
                <div className="col-auto">
                  <span className="font-weight-bold">Attendance Range</span>:{" "}
                  <span className="ml-2 mr-3">
                    {getAttendanceRange(coverage.groupSize)}
                  </span>
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="col-auto">
                <div>
                  {eventPerformers > 0 && (
                    <div>
                      {providerText(
                        PERFORMER,
                        eventPerformers,
                        performersFrequency,
                        performersList
                      )}
                    </div>
                  )}
                  {eventGoods > 0 && (
                    <div>
                      {providerText(
                        GOODS_VENDOR,
                        eventGoods,
                        goodsVendorsFrequency,
                        goodsVendorsList
                      )}
                    </div>
                  )}
                  {eventFood > 0 && (
                    <div>
                      {providerText(
                        FOOD_VENDOR,
                        eventFood,
                        foodVendorsFrequency,
                        foodVendorsList
                      )}
                    </div>
                  )}
                  {eventExhibitors > 0 && (
                    <div>
                      {providerText(
                        EXHIBITOR,
                        eventExhibitors,
                        exhibitorsFrequency,
                        exhibitorsList
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Event.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  eventFrequency: PropTypes.string.isRequired,
  eventDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  expandDateList: PropTypes.bool.isRequired,
  setExpandDateList: PropTypes.func.isRequired,
  showListOfDates: PropTypes.bool.isRequired,
  deleteDate: PropTypes.func.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  blockedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  showCustomDatePicker: PropTypes.bool.isRequired,
  eventNameCounter: PropTypes.number.isRequired,
  eventPerformers: PropTypes.number.isRequired,
  eventGoods: PropTypes.number.isRequired,
  eventFood: PropTypes.number.isRequired,
  eventExhibitors: PropTypes.number.isRequired,
  performersFrequency: PropTypes.string.isRequired,
  goodsVendorsFrequency: PropTypes.string.isRequired,
  foodVendorsFrequency: PropTypes.string.isRequired,
  exhibitorsFrequency: PropTypes.string.isRequired,
  performersList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  foodVendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  goodsVendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  exhibitorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  isPremiumChanged: PropTypes.bool.isRequired,
};

Event.defaultProps = {
  performersList: null,
  foodVendorsList: null,
  goodsVendorsList: null,
  exhibitorsList: null,
};

export default Event;
