import React from "react";
import { dateHelpers } from "@jauntin/utilities";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { currencyFromInt } from "../../Helpers/CurrencyHelper";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import { KENTUCKY_STATE_CODE } from "../../constants";

const Payment = ({ coverage }) => {
  let taxType = "State Tax";
  if (coverage.venueState.toUpperCase() === "FL") {
    taxType = "State Surcharge";
  }
  if (coverage.venueState.toUpperCase() === "KY") {
    taxType = "KY Municipal Tax";
  }
  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Payment</strong>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between payment__item">
          <div>Purchase date</div>
          <div>
            <strong>
              <TimezoneFormatted
                date={coverage.created}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Basic Coverage</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteBasicCoverageAmount)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between payment__item pt-2">
          <div>Additional Coverages</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(
                  coverage.quoteAdditionalCoverageAmount
                )}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Subtotal</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteSubtotal)}
              />
            </strong>
          </div>
        </div>
        {coverage.quoteSurcharges.federalSurcharge > 0 && (
          <div
            className={`d-flex justify-content-between ${
              coverage.venueState !== KENTUCKY_STATE_CODE && `payment__item`
            } pt-2`}
          >
            <div>State Surcharge</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    coverage.quoteSurcharges.federalSurcharge
                  )}
                />
              </strong>
            </div>
          </div>
        )}
        {coverage.quoteSurcharges.stateSurcharge > 0 && (
          <div className="d-flex justify-content-between payment__item pt-2">
            <div>{taxType}</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    coverage.quoteSurcharges.stateSurcharge
                  )}
                />
              </strong>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-content-middle payment__item pb-2">
          <div>Total</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteTotal)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex py-3">
          <div className="col-4 pl-0">
            <div>
              <strong>Credit Card</strong>
            </div>
            <div>{coverage.nameOnCard}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Billing to</strong>
            </div>
            <div>{coverage.billingTo}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Transaction ID</strong>
            </div>
            <div>{coverage.transactionId}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Payment.propTypes = {
  coverage: coveragePropType.isRequired,
};

export default Payment;
