import React from "react";
import { connect } from "react-redux";
import { emptyVenue, venuePropType } from "../../Helpers/VenueModel";
import SearchBlockedVenuesContainer from "../SearchBlockedVenuesContainer/SearchBlockedVenuesContainer";

const BlockedVenues = ({ venue }) => {
  return <SearchBlockedVenuesContainer venueId={venue.id} />;
};

BlockedVenues.propTypes = {
  venue: venuePropType,
};

BlockedVenues.defaultProps = {
  venue: emptyVenue,
};

const mapStateToProps = (state) => ({
  venue: state.venues.currentVenue,
});

const BlockedVenuesContainer = connect(mapStateToProps)(BlockedVenues);

export default BlockedVenuesContainer;
