import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";
import { producersPropType } from "../../../constants";
import customStyles from "../../../Helpers/ReactSelectStyle";

const Producer = ({
  input,
  meta,
  getProducers,
  producersList,
  setResults,
  rateOptions,
}) => (
  <>
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Producer
    </label>
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    <div className="mb-2">Search by producer name or code</div>
    <Select
      className="producerSelect mb-2"
      styles={customStyles}
      value={input.value}
      options={producersList}
      onChange={(value) => {
        input.onChange(value);
        setResults(value, rateOptions);
      }}
      onBlur={() => input.onBlur(input.value)}
      onInputChange={(inputValue) => {
        if (inputValue) {
          getProducers(inputValue);
        } else {
          setResults(input.value, rateOptions);
        }
      }}
      onFocus={() => setResults(input.value, rateOptions)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        NoOptionsMessage: () => (
          <div className="text-center">No producers found</div>
        ),
      }}
      isClearable
      aria-label="Search by producer name or code"
    />
  </>
);
Producer.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  getProducers: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType),
  setResults: PropTypes.func.isRequired,
  rateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Producer.defaultProps = {
  producersList: [],
};

export default Producer;
