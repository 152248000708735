/**
 *
 * Middleware that gets additionalCoverageLiquorLiability value from policies reducer state and add it to form reducer state
 *
 */
import { SET_VALID_LL, setLiquorLiability } from "../Actions/actions";

const InitializeLiquorLiabilityMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  if (action.type === SET_VALID_LL) {
    store.dispatch(
      setLiquorLiability(
        state.policies.currentCoverage.additionalCoverageLiquorLiability
      )
    );
    return next(action);
  }
  return next(action);
};

export default InitializeLiquorLiabilityMiddleware;
