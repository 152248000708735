import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routeGuardBlocked } from "../Actions/actions";

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRouteGuardBlocked: (path) => dispatch(routeGuardBlocked(path)),
});

const GuardedRoute = ({
  component: Component,
  path,
  guards,
  state,
  dispatchRouteGuardBlocked,
  redirectComponent: RedirectComponent,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (guards.every((guard) => guard(state))) {
          return (
            <>
              <Component {...props} />
              {children}
            </>
          );
        }

        dispatchRouteGuardBlocked(path);
        return <RedirectComponent />;
      }}
    />
  );
};

GuardedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType])
    .isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  guards: PropTypes.arrayOf(PropTypes.func).isRequired,
  redirectComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
  ]),
};

GuardedRoute.defaultProps = {
  exact: false,
  redirectComponent: () => <Redirect to="/" />,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardedRoute);
