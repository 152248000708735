import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, isInvalid } from "redux-form";
import { isEqual } from "lodash";
import {
  insuranceContactForm,
  modalDocsSentMessage,
  modalSendDocsErrorMessage,
  producerContactsForm,
  facilityContactsForm,
  venueContactsForm,
  customContactsForm,
} from "../../constants";
import InsuranceContactContainer from "../InsuranceContact/InsuranceContactContainer";
import ProducerContactsContainer from "../ProducerContacts/ProducerContactsContainer";
import FacilityContactsContainer from "../FacilityContacts/FacilityContactsContainer";
import VenueContactsContainer from "../VenueContacts/VenueContactsContainer";
import CustomContactsContainer from "../CustomContacts/CustomContactsContainer";
import PolicyUpdateAlert from "./PolicyUpdateAlert";
import {
  getPolicy,
  toggleModal,
  errorResponse,
  updateCustomContacts,
  updateInitialCustomContacts,
  toggleAllContactCheckboxes,
} from "../../Actions/actions";
import CoverageService from "../../Helpers/CoverageService";
import API from "../../Helpers/API";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { dateHelpers } from "@jauntin/utilities";
import ModalDocsSent from "./ModalDocsSent";
import ModalSendDocsError from "./ModalSendDocsError";
import LoadingSpinner from "../../Components/LoadingSpinner";
import {
  downloadDocument,
  getDocumentsStatus,
} from "../../Helpers/DocumentDownload";
import { usePollDocuments, Button, AsyncButton } from "@jauntin/react-ui";
import download from "downloadjs";

const insuranceContactFormValues = formValueSelector(insuranceContactForm);
const producerContactsFormValues = formValueSelector(producerContactsForm);
const facilityContactsFormValues = formValueSelector(facilityContactsForm);
const venueContactsFormValues = formValueSelector(venueContactsForm);
const customContactsFormValues = formValueSelector(customContactsForm);

const getSendIds = (checklist, valuesList) => {
  if (checklist && checklist.includes(true)) {
    const sendList = checklist
      .map((contact, i) =>
        contact === true && valuesList[i] ? valuesList[i].email : null
      )
      .filter((a) => a !== null);
    return sendList;
  }
  return [];
};

const Contacts = ({
  action,
  latestOrderNumber,
  coverage,
  acknowledgePolicyUpdated,
  insuranceContact,
  producerContacts,
  facilityContacts,
  venueContacts,
  customContacts,
  showModalDocsSent,
  showModalSendDocsError,
  deleteCoverageContact,
  toggleAllCheckboxes,
  checkAll,
  customContactsInvalid,
  policyDocumentFilename,
  timezone,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const { downloading, startDownload } = usePollDocuments({
    getDocumentsStatus: () => getDocumentsStatus(latestOrderNumber),
    getDocuments: () => downloadDocument(coverage),
    downloadFn: download,
    fileDownloadName: policyDocumentFilename,
  });

  return (
    <>
      {submitting && (
        <div className="disable-overlay">
          <div className="disable-overlay__inner">
            <LoadingSpinner />
            <div className="h6 mt-2">Sending...</div>
          </div>
        </div>
      )}
      <div className="content__header col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Contacts</h4>
        </div>
      </div>
      <div className="content__body">
        {coverage.policyHasUnacknowledgedChanges && (
          <PolicyUpdateAlert close={acknowledgePolicyUpdated} />
        )}
        {coverage.status !== "expired" && (
          <div className="mb-4">
            <Button
              onClick={() => toggleAllCheckboxes()}
              text={checkAll ? "Select All" : "Deselect All"}
              className="btn-outline-primary"
            />
          </div>
        )}
        <InsuranceContactContainer />
        <ProducerContactsContainer />
        <FacilityContactsContainer />
        <VenueContactsContainer />
        <CustomContactsContainer
          deleteCoverageContact={deleteCoverageContact}
          coverage={coverage}
        />

        <div className="d-flex mt-3">
          <span className="d-flex align-items-center p-3">
            <i className="far fa-file-pdf fa-2x" />
          </span>
          <div className="py-3">
            <AsyncButton
              spinning={downloading}
              disabled={downloading}
              onClick={async () => {
                setTimeout(startDownload, 2000);
              }}
              className="btn btn-link policy__download d-flex"
            >
              Latest Policy Document
            </AsyncButton>
            <div className="contacts__updated">
              <em>
                {`Updated on ${dateHelpers.lettersAndNumbers(
                  coverage.updated,
                  timezone
                )} at ${dateHelpers.simpleTime(coverage.updated, timezone)}`}
              </em>
            </div>
          </div>
        </div>
        {coverage.status !== "expired" && (
          <Button
            disabled={
              (!insuranceContact &&
                !producerContacts.includes(true) &&
                !facilityContacts.includes(true) &&
                !venueContacts.includes(true) &&
                !customContacts.includes(true)) ||
              customContactsInvalid ||
              submitting
            }
            onClick={() => {
              action(setSubmitting);
            }}
            text="Send Document"
            className="btn-primary m-3 px-4"
          />
        )}
      </div>
      <ModalDocsSent show={showModalDocsSent} text={modalDocsSentMessage} />
      <ModalSendDocsError
        show={showModalSendDocsError}
        text={modalSendDocsErrorMessage}
      />
    </>
  );
};

Contacts.propTypes = {
  action: PropTypes.func.isRequired,
  acknowledgePolicyUpdated: PropTypes.func.isRequired,
  latestOrderNumber: PropTypes.number.isRequired,
  coverage: coveragePropType.isRequired,
  insuranceContact: PropTypes.bool.isRequired,
  producerContacts: PropTypes.arrayOf(PropTypes.bool),
  facilityContacts: PropTypes.arrayOf(PropTypes.bool),
  venueContacts: PropTypes.arrayOf(PropTypes.bool),
  customContacts: PropTypes.arrayOf(PropTypes.bool),
  showModalDocsSent: PropTypes.bool.isRequired,
  showModalSendDocsError: PropTypes.bool.isRequired,
  deleteCoverageContact: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  checkAll: PropTypes.bool.isRequired,
  customContactsInvalid: PropTypes.bool.isRequired,
  policyDocumentFilename: PropTypes.string.isRequired,
};

Contacts.defaultProps = {
  producerContacts: [],
  facilityContacts: [],
  venueContacts: [],
  customContacts: [],
};

const mapStateToProps = (state) => {
  const allContactCheckboxes = [
    insuranceContactFormValues(state, "emailInsuranceContact") || false,
  ].concat(
    producerContactsFormValues(state, "emailProducerContact") || [],
    facilityContactsFormValues(state, "emailFacilityContact") || [],
    venueContactsFormValues(state, "emailVenueContact") || [],
    customContactsFormValues(state, "emailCustomContact") || []
  );

  return {
    latestOrderNumber: state.policies.currentCoverage.latestOrderNumber,
    coverage: state.policies.currentCoverage,
    insuranceContact:
      insuranceContactFormValues(state, "emailInsuranceContact") || false,
    producerContacts:
      producerContactsFormValues(state, "emailProducerContact") || [],
    facilityContacts:
      facilityContactsFormValues(state, "emailFacilityContact") || [],
    venueContacts: venueContactsFormValues(state, "emailVenueContact") || [],
    // Check for the value of emailCustomContact, but ensure that value
    // is mapped to an actual customContact
    customContacts: (
      customContactsFormValues(state, "emailCustomContact") || []
    ).map(
      (v, i) =>
        v && customContactsFormValues(state, "customContacts")[i] !== undefined
    ),
    showModalDocsSent: state.policies.modal.docsSent,
    showModalSendDocsError: state.policies.modal.sendDocsError,
    checkAll: allContactCheckboxes.includes(false),
    customContactsInvalid: isInvalid("customContactsForm")(state),
    policyDocumentFilename:
      state.policies.currentCoverage.policyDocumentFilename,
    timezone: state.app.timezone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: (setSubmitting) =>
    dispatch(async (_, getState) => {
      setSubmitting(true);
      const state = getState();
      const coverage = state.policies.currentCoverage;
      const { id } = coverage;

      // Check if Custom Contacts exist, process them
      const customContactsAll = customContactsFormValues(
        state,
        "customContacts"
      );
      if (!isEqual(customContactsAll, coverage.customContacts)) {
        await new CoverageService(new API())
          .postCustomContacts(id, customContactsAll)
          .then((result) => {
            dispatch(updateInitialCustomContacts(result.data));
            dispatch(updateCustomContacts(result.data));
          });
      }

      const to = insuranceContactFormValues(state, "emailInsuranceContact")
        ? [coverage.insuranceContactEmail]
        : [];

      // ProducerContacts
      const sendProducerContacts = getSendIds(
        producerContactsFormValues(state, "emailProducerContact"),
        coverage.producerContacts
      );
      // FacilityContacts
      const sendFacilityContacts = getSendIds(
        facilityContactsFormValues(state, "emailFacilityContact"),
        coverage.facilityContacts
      );
      // VenueContacts
      const sendVenueContacts = getSendIds(
        venueContactsFormValues(state, "emailVenueContact"),
        coverage.venueContacts
      );
      // CustomContacts
      const sendCustomContacts = getSendIds(
        customContactsFormValues(state, "emailCustomContact"),
        customContactsFormValues(state, "customContacts")
      );

      const data = {
        id,
        mainRecipients: to,
        bccRecipients: [
          ...sendProducerContacts,
          ...sendFacilityContacts,
          ...sendVenueContacts,
          ...sendCustomContacts,
        ],
      };

      return new CoverageService(new API())
        .postSendPolicyEmails(data)
        .then((results) => {
          if (results.status === 204) {
            dispatch(toggleModal("docsSent", true));
          }
          dispatch(getPolicy(id));
          setSubmitting(false);
        })
        .catch((err) => {
          dispatch(toggleModal("sendDocsError", true));
          dispatch(errorResponse(err));
          setSubmitting(false);
        });
    }),
  deleteCoverageContact: () =>
    dispatch((_, getState) => {
      const state = getState();
      const coverage = state.policies.currentCoverage;
      const { id } = coverage;
      const customContactsInitial = coverage.customContacts;
      const customContactsAll = customContactsFormValues(
        state,
        "customContacts"
      );

      if (
        customContactsInitial.length > 0 &&
        !isEqual(customContactsAll, customContactsInitial)
      ) {
        const keepList = customContactsInitial.filter((contact) => {
          return customContactsAll.some((item) => item.email === contact.email);
        });
        return new CoverageService(new API())
          .postCustomContacts(id, keepList)
          .then((result) => {
            dispatch(updateInitialCustomContacts(result.data));
            dispatch(getPolicy(id));
          });
      }
      return null;
    }),
  toggleAllCheckboxes: () =>
    dispatch((_, getState) => {
      const state = getState();
      const setValue = [
        state.form.insuranceContactForm.values.emailInsuranceContact,
      ]
        .concat(
          state.form.producerContactsForm.values.emailProducerContact,
          state.form.facilityContactsForm.values.emailFacilityContact,
          state.form.venueContactsForm.values.emailVenueContact,
          state.form.customContactsForm.values.emailCustomContact
        )
        .includes(false);

      dispatch(toggleAllContactCheckboxes(setValue));
    }),
  acknowledgePolicyUpdated: (coverage) =>
    dispatch(() => {
      return new CoverageService(new API())
        .postAcknowledgeChanges(coverage)
        .then(() => {
          dispatch(getPolicy(coverage.id));
        });
    }),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  acknowledgePolicyUpdated: () =>
    dispatchProps.acknowledgePolicyUpdated(stateProps.coverage),
  deleteCoverageContact: () => dispatchProps.deleteCoverageContact(),
});

const ContactsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Contacts);

export default ContactsContainer;
