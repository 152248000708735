import { formValueSelector } from "redux-form";
import { policyFormName } from "../constants";
import { calculateChanges } from "../Actions/actions";
import Debounce from "./Debounce";
import { BY_MANUAL_ADDRESS } from "./VenueSearchTypes";
import { dateHelpers } from "@jauntin/utilities";

/**
 *
 * Redux observer that checks for form state changes relevant to price
 * If some value changes, then ask the server for an updated quote
 *
 */

let currentValue;
const formValues = formValueSelector(policyFormName);
const debouncePeriod = 500;

function getValueFromState(state, formName) {
  let isChecked = true;
  if (formName === "federalEntity") {
    isChecked = state.policies.currentCoverage.isFederalEntity;
  } else if (formName === "kentuckyStateEntity") {
    isChecked = state.policies.currentCoverage.isKentuckyEntity;
  }
  return isChecked;
}

function getFormRadioValue(state, formName) {
  let isChecked = true;
  const hasFormValue = formValues(state, formName);

  if (hasFormValue) {
    isChecked = formValues(state, formName) === "yes";
  } else {
    isChecked = getValueFromState(state, formName);
  }
  return isChecked;
}

function select(state) {
  return {
    coverageId: state.policies.currentCoverage.id,
    eventDates: formValues(state, "eventDates")
      ? formValues(state, "eventDates").map((d) =>
          dateHelpers.dateOnlyStringFormat(d, state.app.timezone)
        )
      : [],
    numberOfDays: formValues(state, "eventDates")
      ? formValues(state, "eventDates").length
      : 0,
    averageDailyAttendance:
      parseInt(formValues(state, "eventDailyGuests"), 10) || 1,
    venueState:
      (formValues(state, "venueSearchTypeRadio") === BY_MANUAL_ADDRESS
        ? formValues(state, "manualVenueState")
        : formValues(state, "venueState")) || "TX",
    renterState: formValues(state, "renterState"),
    federalEntity: getFormRadioValue(state, "federalEntity") ? 1 : 0,
    kentuckyStateEntity: getFormRadioValue(state, "kentuckyStateEntity")
      ? 1
      : 0,
    venueMunicipalityCode: formValues(state, "venueMunicipalityCode") || "",
    gll: formValues(state, "venueGll") || "1000000",
  };
}

function getIsFormPristine(state) {
  return state.policies.isFormPristine;
}

function handleChange({ store }) {
  const previousValue = currentValue;
  currentValue = select(store.getState());
  const isFormPristine = getIsFormPristine(store.getState());
  if (
    JSON.stringify(previousValue) !== JSON.stringify(currentValue) &&
    Object.keys(store.getState().form.policy).length
  ) {
    store.dispatch(calculateChanges(currentValue, isFormPristine));
  }
}

const PriceChangeObserver = (store) => {
  const debounce = new Debounce({ period: debouncePeriod });
  store.subscribe(() => {
    debounce.do(handleChange, { store });
  });
};

export default PriceChangeObserver;
