import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import VenueService from "../../Helpers/VenueService";
import API from "../../Helpers/API";
import customStyles from "../../Helpers/ReactSelectStyle";
import { AsyncButton, Button } from "@jauntin/react-ui";
import { XLSX_RAW_DATA_TYPE, XLSX_READER_FRIENDLY_TYPE } from "../../constants";
import { isEmpty } from "lodash";

const defaultState = { value: "", label: "All States" };
const defaultDataType = {
  value: XLSX_RAW_DATA_TYPE,
  label: XLSX_RAW_DATA_TYPE,
};
const defaultAdditionalInsuredCity = { value: "", label: "All Cities" };

const dataTypeOptions = [
  {
    value: XLSX_RAW_DATA_TYPE,
    label: XLSX_RAW_DATA_TYPE,
  },
  {
    value: XLSX_READER_FRIENDLY_TYPE,
    label: XLSX_READER_FRIENDLY_TYPE,
  },
];

const ModalExport = ({
  show,
  handleClose,
  facilityId,
  states,
  additionalInsuredCities,
}) => {
  const [selectedState, setSelectedState] = useState(defaultState);
  const [selectedDataType, setSelectedDataType] = useState(defaultDataType);
  const [selectedAdditionalInsuredCity, setSelectedAdditionalInsuredCity] =
    useState(defaultAdditionalInsuredCity);
  const [downloading, setDownloading] = useState(false);

  const body = {
    state: selectedState.value,
    dataType: selectedDataType.value,
    additionalInsuredCity: selectedAdditionalInsuredCity.value,
  };

  const downloadExport = async () => {
    try {
      setDownloading(true);
      await new VenueService(new API()).downloadExportVenues(facilityId, body);
    } finally {
      setDownloading(false);
    }
  };

  const getStateOptions = () =>
    [defaultState].concat(
      states.map((state) => ({ value: state.code, label: state.name }))
    );

  const getAdditionalInsuredCitiesOptions = () =>
    [defaultAdditionalInsuredCity].concat(
      additionalInsuredCities.map((city) => ({ value: city, label: city }))
    );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 pb-4 pt-4">
        <Modal.Title>Export venues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="4">
            <label htmlFor="state-select" className="label">
              State
            </label>
            <Select
              name="state-select"
              styles={customStyles}
              value={selectedState}
              options={getStateOptions()}
              onChange={setSelectedState}
            />
          </Col>
          <Col md="4">
            <label htmlFor="data-type-select" className="label">
              Data Type
            </label>
            <Select
              name="data-type-select"
              styles={customStyles}
              value={selectedDataType}
              options={dataTypeOptions}
              onChange={setSelectedDataType}
            />
          </Col>
          {!isEmpty(additionalInsuredCities) && (
            <Col md="4">
              <label htmlFor="additional-insured-city-select" className="label">
                Additional Insured City
              </label>
              <Select
                name="additional-insured-city-select"
                styles={customStyles}
                value={selectedAdditionalInsuredCity}
                options={getAdditionalInsuredCitiesOptions()}
                onChange={setSelectedAdditionalInsuredCity}
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text="Cancel"
          className="btn-outline-secondary px-4 mx-2"
          onClick={() => {
            handleClose();
          }}
        />
        <AsyncButton
          spinning={downloading}
          disabled={downloading}
          onClick={downloadExport}
          className="btn btn-primary d-flex"
          spinnerClassName="d-flex pt-1 mr-2"
        >
          Download venues XLS
        </AsyncButton>
      </Modal.Footer>
    </Modal>
  );
};

ModalExport.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  additionalInsuredCities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ModalExport.defaultProps = {
  show: false,
};

export default ModalExport;
