import React from "react";
import { useSelector } from "react-redux";
import PolicyPurchaseReport from "./PolicyPurchaseReport";
import { canViewReports as canViewReportsSelector } from "../../Helpers/StoreSelectors";

const ReportsContainer = () => {
  const canViewReports = useSelector(canViewReportsSelector);

  return (
    <>
      <div className="p-5 scroll-part">
        <h1 className="mb-5">Reports</h1>
        <p>Accessing these reports requires login to the Intact network.</p>
        <ul className="list-unstyled mt-5">
          <li className="mb-4">
            <a
              href="http://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Program_Admin_Report"
              target="_blank"
              rel="noopener noreferrer"
            >
              Program Administrator Report
            </a>
            <p>All certificates across all facilities.</p>
          </li>
          <li className="mb-4">
            <a
              href="http://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Facility_Report"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facility Report
            </a>
            <p>All certificates within a date range for a specific facility.</p>
          </li>
          <li className="mb-4">
            <a
              href="http://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Location_Report"
              target="_blank"
              rel="noopener noreferrer"
            >
              Venue Report
            </a>
            <p>Certificates for specific venues within a certain facility.</p>
          </li>
          <li className="mb-4">
            <a
              href="http://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Insured_Report"
              target="_blank"
              rel="noopener noreferrer"
            >
              Producer/Insured Report
            </a>
            <p>
              Certificates by producer that shows insured information and
              commission.
            </p>
          </li>
          <li className="mb-4">
            <a
              href="http://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Report"
              target="_blank"
              rel="noopener noreferrer"
            >
              Producer Report
            </a>
            <p>
              Certificates by producer with commission but without insured
              information.
            </p>
          </li>
          <li className="mb-4">
            <a
              href="https://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Group_Summary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Producer Group Summary
            </a>
            <p>Franchise-level producer report.</p>
            <ul>
              <li className="mb-3 mt-3">
                <a
                  href="https://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Group_List"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Producer Group List
                </a>
                <p />
              </li>
              <li className="mb-3">
                <a
                  href="https://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Group_Detail"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Producer Group Detail
                </a>
                <p />
              </li>
              <li className="mb-3">
                <a
                  href="https://sqlrsprod.internal.local/Home/report/Prod/DataServices/OBE/GatherGuard/GatherGuard_Producer_Group_Policy_Detail"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Producer Group Policy Detail
                </a>
                <p />
              </li>
            </ul>
          </li>
          {canViewReports && (
            <li className="mb-4">
              <PolicyPurchaseReport />
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ReportsContainer;
