/**
 *
 * Services provided by the onebeacon server related to `Producer`
 *
 */
class ProducerService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getProducersSearchResults = (input = "", page, perPage) => {
    let apiParams = { page, perPage };
    if (input) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }
    return this.api.get({
      location: "/producer",
      params: apiParams,
    });
  };

  getIsValidCommissionId = (id) =>
    this.api.get({
      location: `/producer/commissionId/${id}`,
    });

  putProducerEditDetails = (data) =>
    this.api.put({
      location: `/producer/${data.id}/producerDetails`,
      body: data,
    });

  postAddNewProducerDetails = (data) =>
    this.api.post({
      location: `/producer/create`,
      body: data,
    });

  postAddNote = (data) =>
    this.api.post({
      location: `/producer/${data.id}/addNote`,
      body: data,
    });

  getProducer = (id) =>
    this.api.get({
      location: `/producer/${id}`,
    });
}

export default ProducerService;
