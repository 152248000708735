import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { producerContactsForm } from "../../constants";
import { CheckboxInput } from "@jauntin/react-ui";
import { coveragePropType } from "../../Helpers/CoverageModel";

const Producer = ({ coverage }) => {
  return (
    <>
      {coverage.producerContacts.map((contact, i) => (
        <div className="d-flex mt-4" key={contact.id}>
          {coverage.status !== "expired" && (
            <Field
              component={CheckboxInput}
              className="mt-4 ml-2 mr-4"
              name={`emailProducerContact[${i}]`}
              type="checkbox"
            />
          )}
          <div className="card w-100">
            <div className="card-header bg-transparent d-flex justify-content-between">
              <div className="my-auto contacts__cardTitle">
                <strong>Producer Contact &#35;{i + 1}</strong>
              </div>
            </div>
            <div className="card-body my-2">
              {contact.fullName && <div>{contact.fullName}</div>}
              {contact.producerName && <div>{contact.producerName}</div>}
              {contact.role && <div>{contact.role}</div>}
              <div>{contact.email}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

Producer.propTypes = {
  coverage: coveragePropType,
};

Producer.defaultProps = {
  coverage: [],
};

const mapStateToProps = (state) => ({
  coverage: state.policies.currentCoverage,
  initialValues: {
    producerContacts: state.policies.currentCoverage.producerContacts,
    emailProducerContact: state.policies.currentCoverage.producerContacts.map(
      () => true
    ),
  },
});

const ProducerContact = compose(
  connect(mapStateToProps),
  reduxForm({
    form: producerContactsForm,
  })
)(Producer);

export default ProducerContact;
