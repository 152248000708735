import React from "react";
import { coveragePropType } from "../../Helpers/CoverageModel";
import {
  currencyFromDecimal,
  DOLLAR_ONLY_FORMAT,
} from "../../Helpers/CurrencyHelper";

const BasicCoverage = ({ coverage }) => {
  return (
    <div className="card">
      <div className="card-header bg-transparent">
        <strong>Basic Coverage</strong>
      </div>
      <div className="card-body">
        <div className="form-row justify-content-between mb-4">
          <div className="col-auto my-auto bold-1">Event type</div>
          <div className="col-auto">{coverage.eventTypeName}</div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">General Liability Coverage</div>
          <div className="col-auto my-auto mr-auto policy__reference">
            {coverage.policyNumberGll}
          </div>
          <div className="col-auto bold-1 my-auto">
            {currencyFromDecimal(coverage.venueGll).format(DOLLAR_ONLY_FORMAT)}
          </div>
        </div>
        <div className="form-row">
          <div className="col-auto">Limit for each incident (Occurrence)</div>
        </div>
        <div className="form-row">
          <div className="col-auto mt-3">Includes:</div>
        </div>
        <div className="form-row">
          {!coverage.quoteLiquorLiabilityAmount && (
            <div className="col-auto">Host Liquor</div>
          )}
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">
            Damage to rented premises
            {coverage.damageToRentedPropertyCoverageAmount === 1000000
              ? " (up to $1M)"
              : ` (up to ${currencyFromDecimal(
                  coverage.damageToRentedPropertyCoverageAmount
                ).format(DOLLAR_ONLY_FORMAT)})`}
          </div>
          <div className="col-auto bold-1">
            {currencyFromDecimal(
              coverage.damageToRentedPropertyCoverageAmount
            ).format(DOLLAR_ONLY_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  );
};

BasicCoverage.propTypes = {
  coverage: coveragePropType.isRequired,
};

export default BasicCoverage;
