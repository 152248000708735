import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StatusField from "./FormElements/StatusField";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { producerPropType } from "../../Helpers/ProducerModel";

const ProducerStatus = ({ editing, producer }) => (
  <>
    <div className="card-body">
      {editing ? (
        <Field component={StatusField} name="producerStatus" type="select" />
      ) : (
        <>
          <span className="label mr-4">Status:</span>
          {stringHelpers.firstToUpper(producer.status)}
        </>
      )}
    </div>
  </>
);

ProducerStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
};

export default ProducerStatus;
