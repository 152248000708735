import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Field } from "redux-form";
import { PRODUCER_CONTACT_TYPE } from "../../constants";
import { contactEmailUpdate } from "../../Helpers/validators";
import SelectField from "./FormElements/SelectField";
import { TextInput, EmailInput, Button } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
const { email } = validators;
const { normalizeEmail } = normalizers;

const ModalOverwrite = ({
  show,
  action,
  handleClose,
  cancel,
  singleEmail,
  onShow,
  isValid,
  contactType,
}) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <Modal
      show={show}
      onExited={() => setConfirm(false)}
      onHide={handleClose}
      size="lg"
      onShow={onShow}
    >
      <Modal.Header className="py-4" closeButton>
        <h3 className="mb-0">
          Overwrite contact{!singleEmail && "s"} information
        </h3>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-5">
        {confirm ? (
          <p>
            Are you sure you wish to overwrite the contact information for{" "}
            {singleEmail ?? "the contacts selected"}?
            <br />
            This action can not be undone.
          </p>
        ) : (
          <>
            <p className="mb-4">
              This contact information will apply to{" "}
              {singleEmail ?? "all contacts selected"}.
            </p>
            <Field
              component={TextInput}
              name="overwriteName"
              label="Name"
              ariaLabel="Name"
              inputClassName="form-control-lg mb-4"
              errorClassName="mb-2"
            />
            {contactType !== PRODUCER_CONTACT_TYPE && (
              <Field
                component={TextInput}
                name="overwriteRole"
                label="Role"
                ariaLabel="Role"
                inputClassName="form-control-lg mb-4"
                errorClassName="mb-2"
              />
            )}
            <Field
              component={EmailInput}
              validate={[email, contactEmailUpdate]}
              normalize={normalizeEmail}
              name="overwriteEmail"
              placeholder=""
              inputMode="email"
              inputClassName="form-control-lg mb-4"
              errorClassName="mb-2"
            />
            {contactType !== PRODUCER_CONTACT_TYPE && (
              <Field
                component={SelectField}
                name="overwriteCopyOnEmails"
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  { label: "No", value: "no" },
                  { label: "Do not change current selection", value: "dnc" },
                ]}
                className="w-100 mw--300 mb-2"
                placeholder="Select"
                label="Copy on emails containing event coverage information"
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="py-4">
        <Button
          className="btn-outline-secondary"
          text="Cancel"
          onClick={() => {
            cancel();
            handleClose();
          }}
        />
        {confirm ? (
          <Button
            text="Yes, overwrite"
            onClick={() => action()}
            className="btn-primary"
          />
        ) : (
          <Button
            onClick={() => setConfirm(true)}
            text="Next"
            disabled={!isValid}
            className="btn-primary"
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalOverwrite.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  singleEmail: PropTypes.string,
  onShow: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  contactType: PropTypes.string.isRequired,
};
ModalOverwrite.defaultProps = {
  show: false,
  singleEmail: null,
};

export default ModalOverwrite;
