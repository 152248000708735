import React from "react";
import { FieldInputProps } from "../../../Helpers/ReduxFormPropTypes";

const AdditionalInsuredField = ({ input }) => (
  <textarea
    {...input}
    id={input.name}
    className="form-control form-control-lg mb-4 additionalInsured--textarea"
    aria-label="Other additional insured"
    rows="4"
  />
);
AdditionalInsuredField.propTypes = {
  input: FieldInputProps.isRequired,
};

export default AdditionalInsuredField;
