import React from "react";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { currencyFromInt } from "../../Helpers/CurrencyHelper";
import DollarsAndCents from "../../Helpers/DollarsAndCents";

const AdditionalCoverage = ({ coverage }) => {
  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Additional Coverages</strong>
      </div>
      <div className="card-body">
        {coverage.additionalCoverageHostLiquor &&
          !coverage.additionalCoverageLiquorLiability && (
            <div className="d-flex justify-content-between">
              <div>Host Liquor (included)</div>
              <div>
                <strong>
                  <DollarsAndCents
                    currency={currencyFromInt(coverage.quoteHostLiquorAmount)}
                  />
                </strong>
              </div>
            </div>
          )}
        {coverage.additionalCoverageTerrorism && (
          <div className="d-flex justify-content-between">
            <div>Terrorism (included)</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(coverage.quoteTerrorismAmount)}
                />
              </strong>
            </div>
          </div>
        )}
        {coverage.additionalCoverageDamageToRentedProperty && (
          <div className="d-flex justify-content-between">
            <div>Damage to Rented Premises</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    coverage.quoteDamageToRentedPropertyAmount
                  )}
                />
              </strong>
            </div>
          </div>
        )}
        {coverage.additionalCoverageLiquorLiability && (
          <div className="d-flex justify-content-between">
            <div>Selling Liquor (liquor liability)</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    coverage.quoteLiquorLiabilityAmount
                  )}
                />
              </strong>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AdditionalCoverage.propTypes = {
  coverage: coveragePropType.isRequired,
};

export default AdditionalCoverage;
