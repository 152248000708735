import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import FacilitiesDetailsHeader from "./FacilitiesDetailsHeader";
import {
  getUrl,
  FACILITY_PAGE,
  FACILITY_NOTES_PAGE,
  VENUES_PAGE,
} from "../../Helpers/URLParser";

const FacilitiesMenu = ({ id }) => {
  return (
    <div className="subsection-menu">
      <FacilitiesDetailsHeader />
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(VENUES_PAGE, id, 0)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Venues
        </NavLink>
        <NavLink
          to={getUrl(FACILITY_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Facility Overview
        </NavLink>
        <NavLink
          to={getUrl(FACILITY_NOTES_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Facility Notes
        </NavLink>
      </div>
    </div>
  );
};

FacilitiesMenu.propTypes = {
  id: PropTypes.number.isRequired,
};

export default FacilitiesMenu;
