import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter, ToastBody } from "@jauntin/react-ui";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toNumber } from "lodash";
import {
  setPage,
  getSearchVenues,
  setVenueStatusMessage,
  showVenueStatusAlert,
  clearVenuesTable,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import {
  getUrl,
  ADD_BLOCKED_VENUE_PAGE,
  BLOCKED_VENUES_PAGE,
} from "../../Helpers/URLParser";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import { venuePropType } from "../../Helpers/VenueModel";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });
const alertDelayClose = 5000; // milliseconds

class Search extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  componentDidUpdate(prevProps) {
    const { page: prevPage } = prevProps;
    const { page, onPageChange } = this.props;
    if (page !== prevPage) {
      onPageChange(prevPage);
    }
  }

  componentWillUnmount() {
    const { clearBlockedVenues } = this.props;
    clearBlockedVenues();
  }

  render() {
    const {
      venueTableData,
      pagination,
      searchTerm,
      change,
      goToPage,
      showAlert,
      setShowAlert,
      venueStatusMessage,
      resetVenueStatusMessage,
      venueId,
    } = this.props;

    let resultsMessage = "";
    let venuesText = "";
    if (pagination.total === 0) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    venuesText = pagination.total === 1 ? "venue" : "venues";
    return (
      <>
        <div className="p-5 scroll-part">
          <Row className="justify-content-between">
            <Col xs="auto">
              <h1>Blocked Venues</h1>
            </Col>
            <Col className="text-right my-auto">
              <NavLink
                to={getUrl(ADD_BLOCKED_VENUE_PAGE, venueId)}
                className="btn btn-primary mb-2 ml-2"
              >
                Block a Venue
              </NavLink>
            </Col>
          </Row>
          <SearchField
            label="Search by venue name, address or code"
            fieldName="venuesSearchQuery"
            change={change}
            totalText={`${stringHelpers.commaSeparatedNumber(
              pagination.total
            )} ${venuesText}`}
          />
          <ToastBody
            show={showAlert}
            autohide={true}
            showCloseButton={true}
            text={venueStatusMessage}
            delay={alertDelayClose}
            onClose={() => {
              setShowAlert(false);
              resetVenueStatusMessage();
            }}
            iconClassName="fal fa-check-circle icon--large"
            onCloseButtonClick={() => setShowAlert(false)}
          />
          {(venueTableData.length === 0 &&
            pagination.totalPages > 1 &&
            pagination.page === pagination.totalPages + 1) ||
          venueTableData.length !== 0 ? (
            <>
              <SearchResultsTable
                venuesList={venueTableData}
                change={change}
                searchTerm={searchTerm}
                venueId={venueId}
                setShowAlert={setShowAlert}
                pagination={pagination}
                goToPage={goToPage}
              />
              <div className="d-flex justify-content-end">
                <Pagination pagination={pagination} goToPage={goToPage} />
              </div>
            </>
          ) : (
            <div className="col-sm-8 h4">{resultsMessage}</div>
          )}
        </div>
      </>
    );
  }
}

Search.propTypes = {
  venueTableData: PropTypes.arrayOf(venuePropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  venueId: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  venueStatusMessage: PropTypes.string.isRequired,
  resetVenueStatusMessage: PropTypes.func.isRequired,
  clearBlockedVenues: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  venueTableData: state.venues.venueTableData,
  pagination: state.venues.venuePagination,
  searchTerm: state.venues.searchTerm,
  showAlert: state.venues.newVenueStatus,
  venueStatusMessage: state.venues.venueStatusMessage,
  page: toNumber(state.router.location.query.page),
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) => {
    dispatch(
      ConnectedRouter.push(`${getUrl(BLOCKED_VENUES_PAGE)}?page=${page}`)
    );
    debouncer.do(
      (searchInput) => dispatch(getSearchVenues(searchInput, page, perPage)),
      inputValue
    );
  },
  goToPage: (searchTerm, newPage, perPage, venueId) => {
    dispatch(setPage(newPage));
    dispatch(
      ConnectedRouter.push(`${getUrl(BLOCKED_VENUES_PAGE)}?page=${newPage}`)
    );
    dispatch(getSearchVenues(searchTerm, newPage, perPage, venueId));
  },
  setShowAlert: (val) => dispatch(showVenueStatusAlert(val)),
  resetVenueStatusMessage: () => dispatch(setVenueStatusMessage("")),
  clearBlockedVenues: () => dispatch(clearVenuesTable()),
  loadData: (searchTerm, newPage, perPage) => {
    dispatch(getSearchVenues(searchTerm, newPage, perPage));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(
      inputValue,
      1,
      stateProps.pagination.perPage,
      ownProps.venueId
    ),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage,
      ownProps.venueId
    ),
  onMount: () => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    dispatchProps.loadData(term, page || 1, pagination.perPage);
  },
  onPageChange: (prevPage) => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (
      (page && page !== prevPage && page !== pagination.page) ||
      (!page && prevPage > 1)
    ) {
      dispatchProps.loadData(term, page || 1, pagination.perPage);
    }
  },
});

const SearchBlockedVenuesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchBlockedVenuesContainer;
