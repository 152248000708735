import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const CountryField = ({ input, meta }) => (
  <div className="col form-group mw--300">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Country
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg disabled"
    >
      <option value="US" checked>
        United States
      </option>
    </select>
  </div>
);
CountryField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default CountryField;
