export default function listToStringDays(list) {
  return (list || [])
    .reduce(
      (c, e, i) =>
        `${c}${i ? ", " : ""}${e.name} (${e.days} ${
          Number(e.days) > 1 ? "days" : "day"
        })`,
      ""
    )
    .replace(/, ([^,]*)$/, " and $1");
}
