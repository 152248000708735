import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { dateHelpers } from "@jauntin/utilities";
import { Button, TimezoneFormatted } from "@jauntin/react-ui";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { getUrl, VENUE_PAGE } from "../../Helpers/URLParser";

const policyLink = (policy) => {
  if (policy.venueFacilityId && policy.venueId && policy.venueCompanyName) {
    return (
      <Link to={getUrl(VENUE_PAGE, policy.venueFacilityId, policy.venueId)}>
        {policy.venueCompanyName}
      </Link>
    );
  }
  return "";
};

const SearchResults = ({ policiesList, loadPolicy }) => {
  return (
    <table className="table subsection-table">
      <thead>
        <tr>
          <th scope="col">Policy Number</th>
          <th scope="col">Purchased</th>
          <th scope="col">Effective</th>
          <th scope="col">Insured</th>
          <th scope="col" width="130">
            Event Name
          </th>
          <th scope="col">Venue</th>
          <th scope="col">Date Modified</th>
          <th scope="col">Status</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {policiesList.map((policy) => (
          <tr key={`row${policy.id}`}>
            <td>
              <Button
                className="btn-link btn--edit text-left"
                text={policy.policyNumberGll}
                onClick={() => loadPolicy(policy.id)}
              />
            </td>
            <td>
              <TimezoneFormatted
                date={policy.purchaseDate}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </td>
            <td>
              <TimezoneFormatted
                date={policy.effectiveDate}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </td>
            <td>{stringHelpers.renterName(policy)}</td>
            <td>{policy.eventName}</td>
            <td>{policyLink(policy)}</td>
            <td>
              <TimezoneFormatted
                date={policy.updated || policy.created}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </td>
            <td>{stringHelpers.firstToUpper(policy.status)}</td>
            <td>
              {policy.policyHasUnacknowledgedChanges && (
                <i className="fas fa-exclamation-triangle subsection-table__caution-icon" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SearchResults.propTypes = {
  policiesList: PropTypes.any.isRequired,
  loadPolicy: PropTypes.func.isRequired,
};

export default SearchResults;
