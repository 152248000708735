import { connect } from "react-redux";
import React from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import { loginFormName } from "../../constants";
import LoginField from "./LoginField";
import PasswordField from "./PasswordField";
import { required, email } from "../../Helpers/validators";
import AuthService from "../../Helpers/AuthService";
import API from "../../Helpers/API";
import {
  getUrl,
  POLICIES_PAGE,
  TWO_FACTOR_PAGE,
  REQUEST_RESET_PASSWORD,
} from "../../Helpers/URLParser";
import { FormPropTypes } from "../../Helpers/ReduxFormPropTypes";
import {
  updateLoginStatus,
  setLoginLockedOut,
  setCurrentUser,
} from "../../Actions/actions";
import GatherGuard from "../../Assets/Images/gatherguard-lockup.svg";
import LoginLockOut from "./LoginLockOut";
import { Button } from "@jauntin/react-ui";

const { grecaptcha } = window;

let LoginForm = ({
  valid,
  handleSubmit,
  submitting,
  error,
  lockedOut,
  setShowLockout,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="container text-center pt-2 mt-2">
        <img
          src={GatherGuard}
          alt="Logo"
          className="img-fluid my-4 pt-5 col-md-8 col-lg-6"
        />
        {(!lockedOut && (
          <div className="col-sm-6 col-lg-4 mx-auto pt-5">
            <div>
              <Field
                component={LoginField}
                validate={[required, email]}
                name="email"
              />
            </div>
            <div>
              <Field
                component={PasswordField}
                validate={[required]}
                name="password"
              />
            </div>
            <Link to={getUrl(REQUEST_RESET_PASSWORD)}>Reset Password</Link>
            {error && (
              <div className="form-error my-1 ml-2 text-left">{error}</div>
            )}
            <div>
              <Button
                disabled={!valid || submitting}
                text="LOGIN"
                type="submit"
                className="btn-primary w-100 my-3 py-2"
              />
            </div>
          </div>
        )) || (
          <LoginLockOut
            setShowLockout={setShowLockout}
            title="Return to Login"
          />
        )}
      </div>
    </form>
  );
};
LoginForm = reduxForm({ form: loginFormName })(LoginForm);
LoginForm.propTypes = FormPropTypes;

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(async () => {
      const token = await grecaptcha.execute(
        process.env.REACT_APP_GOOGLE_CAPTCHA_KEY,
        {
          action: "submit",
        }
      );
      const { returnUrl } = qs.parse(window.location.search);
      try {
        const response = await new AuthService(new API()).login({
          ...values,
          captcha: token,
        });
        dispatch(updateLoginStatus(true));
        dispatch(setLoginLockedOut(false));
        dispatch(setCurrentUser(response.data.user));
        return dispatch(
          ConnectedRouter.push(returnUrl || getUrl(POLICIES_PAGE))
        );
      } catch (e) {
        const { response } = e;
        const { data } = response;
        const { errors } = data;

        if (response.status === 302) {
          dispatch(setCurrentUser(response.data.user));
          return dispatch(
            ConnectedRouter.push(
              getUrl(TWO_FACTOR_PAGE) +
                (returnUrl ? `?returnUrl=${returnUrl}` : "")
            )
          );
        }

        let message = "Login Failed";
        let lockedOut = false;

        if (
          response.status === 429 &&
          data &&
          errors &&
          errors.email.length > 0
        ) {
          const [attempts] = errors.email;
          message = attempts;
          lockedOut = true;
        }
        dispatch(setLoginLockedOut(lockedOut));
        dispatch(updateLoginStatus(false));
        throw new SubmissionError({
          _error: message,
        });
      }
    }),
  setShowLockout: (val) => dispatch(setLoginLockedOut(val)),
});

const mapStateToProps = (state) => ({
  lockedOut: state.app.isLockedOut,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
