import { Form } from "react-bootstrap";
import { compose } from "redux";
import { alertKeywordsForm } from "../../constants";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import React, { useEffect, useState } from "react";
import AlertKeywordsService from "../../Helpers/AlertKeywordsService";
import API from "../../Helpers/API";
import { setAlertKeywords } from "../../Actions/actions";
import { Button, ToastBody } from "@jauntin/react-ui";

const alertDelayClose = 5000; // milliseconds

const AlertKeywordsForm = ({
  fetchAlertKeywords,
  valid,
  dirty,
  handleSubmit,
}) => {
  useEffect(() => {
    fetchAlertKeywords();
  }, [fetchAlertKeywords]);

  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className="p-5 scroll-part d-flex flex-column">
      <Form
        onSubmit={async (event) => {
          await handleSubmit(event);
          setShowSuccess(true);
        }}
        className="d-flex flex-column flex-grow-1"
      >
        <div className="d-flex justify-content-between align-items-center">
          <h1>Alert Keywords</h1>
          <Button
            text="Save"
            type="submit"
            className="btn-primary"
            disabled={!valid || !dirty}
          />
        </div>
        <div>Enter keywords below (one per line)</div>
        <div>
          <ToastBody
            show={showSuccess}
            autohide={true}
            showCloseButton={true}
            text="Alert Keywords have been successfully updated."
            delay={alertDelayClose}
            onClose={() => {
              setShowSuccess(false);
            }}
            iconClassName="fal fa-check-circle icon--large"
            onCloseButtonClick={() => setShowSuccess(false)}
          />
        </div>
        <div className="flex-grow-1 mt-2">
          <Field
            component="textarea"
            className="w-100 h-100 form-control"
            name="words"
          />
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialValues: state.alertKeywords.data,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAlertKeywords: async () => {
    dispatch(
      setAlertKeywords((await new AlertKeywordsService(new API()).all()).data)
    );
  },
  onSubmit: async (data) => {
    await new AlertKeywordsService(new API()).save(data);
    dispatch(
      setAlertKeywords((await new AlertKeywordsService(new API()).all()).data)
    );
    dispatch(reset(alertKeywordsForm));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: alertKeywordsForm,
    enableReinitialize: true,
  })
)(AlertKeywordsForm);
