import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { CheckboxInput, TimezoneFormatted } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { getUrl, PRODUCER_PAGE, FACILITY_PAGE } from "../../Helpers/URLParser";
import PopoverContent from "./PopoverContent";

const ProducerContactsSearchResultsTable = ({
  contactsList,
  setShowModalRemove,
  setSelectedContacts,
  setShowModalOverwrite,
  checkedContacts,
}) => {
  return (
    <>
      <table className="table subsection-table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Producer</th>
            <th scope="col">Facility</th>
            <th scope="col">Date Modified</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {contactsList.map((contact, i) => (
            <tr
              key={contact.id}
              className={
                checkedContacts[i] === true ? "row-highlight" : undefined
              }
            >
              <td>
                <div className="mb-2">{contact.email}</div>
                <div>{contact.fullName ? contact.fullName : `\u2014`}</div>
              </td>
              <td>
                <Link
                  className="d-inline-block mb-2"
                  to={getUrl(PRODUCER_PAGE, contact.producerId)}
                >
                  {`${contact.producerName}`}
                </Link>
              </td>
              <td>
                {contact.facilityAddress &&
                  contact.facilityAddress.map((facility) => (
                    <div key={facility.id} className="mb-3">
                      <Link
                        className="d-inline-block mb-2"
                        to={getUrl(FACILITY_PAGE, facility.id)}
                      >
                        {`${facility.companyName}`}
                      </Link>
                      {facility.address1 && <div>{facility.address1}</div>}
                      {facility.address2 && <div>{facility.address2}</div>}
                      {(facility.city || facility.state || facility.zip) && (
                        <div>
                          {facility.city && <>{facility.city}</>}
                          {facility.city && facility.state && ", "}
                          {facility.state && <>{facility.state}</>}
                          {facility.zip && <> {facility.zip}</>}
                        </div>
                      )}
                    </div>
                  ))}
              </td>
              <td>
                <TimezoneFormatted
                  date={contact.updated}
                  format={dateHelpers.SLASH_SEPARATED_FORMAT}
                />
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <Field
                    component={CheckboxInput}
                    className="mr-2"
                    name={`selectedContacts[${i}]`}
                    type="checkbox"
                  />
                  <PopoverTrigger
                    content={
                      <PopoverContent
                        contactId={contact.id}
                        setShowModalRemove={setShowModalRemove}
                        setSelectedContacts={setSelectedContacts}
                        setShowModalOverwrite={setShowModalOverwrite}
                      />
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

ProducerContactsSearchResultsTable.propTypes = {
  contactsList: PropTypes.any.isRequired,
  setShowModalRemove: PropTypes.func.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
  checkedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default ProducerContactsSearchResultsTable;
