import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";
import { ExclusiveAccessClaimContext } from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";

const Header = ({
  editing,
  handleShowDiscard,
  handleShowUpdate,
  setEditing,
  editReset,
  pristine,
  valid,
}) => {
  const { checkClaim } = useContext(ExclusiveAccessClaimContext);
  return (
    <div className="content__header col-auto">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Venue</h3>
        <div className="d-flex float-right">
          {editing ? (
            <>
              <Button
                text="Discard Changes"
                className="btn-outline-secondary px-4 mx-2"
                onClick={handleShowDiscard}
              />
              <Button
                text="Save Venue"
                className="btn-primary px-4 ml-2 text-nowrap"
                onClick={handleShowUpdate}
                disabled={pristine || !valid}
              />
            </>
          ) : (
            <>
              <Button
                text="Edit Venue"
                className="btn-primary px-4 ml-2 text-nowrap"
                onClick={() =>
                  checkClaim(() => {
                    editReset();
                    setEditing(true);
                  })
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleShowDiscard: PropTypes.func.isRequired,
  handleShowUpdate: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default Header;
