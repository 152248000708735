import { startOfDay, addDays, subDays, isWithinInterval } from "date-fns";
import { dateHelpers } from "@jauntin/utilities";

const { getFirstEntry, getLastEntry } = dateHelpers;

const firstValidDay = (today) => today;
const lastValidDay = (today) => addDays(today, 548);
const dayIsInOverallValidRange = (day, start) => {
  const dayMidnight = startOfDay(day);
  const startMidnight = startOfDay(start);

  return isWithinInterval(dayMidnight, {
    start: firstValidDay(startMidnight),
    end: lastValidDay(startMidnight),
  });
};
const dayIsValidForStartAndEndDate = (day, start, end) => {
  if (!start) {
    return true;
  }

  let valid = isWithinInterval(day, { start, end: addDays(start, 89) });
  if (end) {
    valid = valid || isWithinInterval(day, { start: subDays(end, 89), end });
  }
  return valid;
};

const policyDisabledDatesForRangePicker =
  ({ blockedDays, startDate }) =>
  (focusedInput) =>
  (day) => {
    if (blockedDays?.includes(day.getDay())) {
      return true;
    }

    if (focusedInput === "endDate") {
      return !dayIsInOverallValidRange(day, new Date(startDate));
    }

    return false;
  };

const policyDisabledDatesForCustomPicker = (eventDates) => (day) => {
  const datesArray = eventDates.map((d) => new Date(d));
  return !dayIsValidForStartAndEndDate(
    day,
    getFirstEntry(datesArray),
    getLastEntry(datesArray)
  );
};

export {
  firstValidDay,
  lastValidDay,
  dayIsInOverallValidRange,
  dayIsValidForStartAndEndDate,
  policyDisabledDatesForRangePicker,
  policyDisabledDatesForCustomPicker,
};
