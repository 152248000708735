import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";
import { facilitiesPropType } from "../../../constants";
import customStyles from "../../../Helpers/ReactSelectStyle";

const formatOptionLabel = ({ value, label }) => (
  <span>
    <>
      <strong>{label}</strong>
      &nbsp;{`\u2014`}&nbsp;
    </>
    {value}
  </span>
);
formatOptionLabel.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Facility = ({
  input,
  meta,
  getFacilities,
  facilitiesList,
  setResults,
}) => (
  <>
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Choose Facility
    </label>
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    <div className="mb-2">Search by facility name or code</div>
    <Select
      className="producerSelect mb-2"
      styles={customStyles}
      value={input.value}
      options={facilitiesList}
      onChange={(value) => {
        input.onChange(value);
        setResults(value);
      }}
      onBlur={() => input.onBlur(input.value)}
      onInputChange={(inputValue) => {
        if (inputValue) {
          getFacilities(inputValue);
        } else {
          setResults(input.value);
        }
      }}
      onFocus={() => setResults(input.value)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        NoOptionsMessage: () => (
          <div className="text-center">No facilities found</div>
        ),
      }}
      formatOptionLabel={formatOptionLabel}
      isClearable
      aria-label="Search by facility name or code"
    />
  </>
);
Facility.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  getFacilities: PropTypes.func.isRequired,
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  setResults: PropTypes.func.isRequired,
};

Facility.defaultProps = {
  facilitiesList: [],
};

export default Facility;
