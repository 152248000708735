import API from "./API";
import CoverageService from "./CoverageService";
import download from "downloadjs";
import { dateHelpers } from "@jauntin/utilities";

const getDocumentsStatus = (latestOrderNumber) =>
  new CoverageService(new API()).getDocumentsStatus(latestOrderNumber);

const downloadDocument = (coverage) =>
  new CoverageService(new API()).getPolicyDocs(coverage);

const downloadTransactionsXml = (coverage, filename) =>
  new CoverageService(new API())
    .getPolicyTransactions(coverage)
    .then(({ data }) => download(data, filename, "application/zip"));

const downloadPurchaseReportCsv = (startDate, endDate, optimized, timezone) =>
  new CoverageService(new API())
    .getPolicyPurchaseReport(startDate, endDate, optimized)
    .then(({ data }) =>
      download(
        data,
        `PolicyPurchaseReport ${dateHelpers.dateOnlyStringFormat(
          startDate,
          timezone
        )},${dateHelpers.dateOnlyStringFormat(endDate, timezone)}.csv`,
        "text/csv"
      )
    );

export {
  getDocumentsStatus,
  downloadDocument,
  downloadTransactionsXml,
  downloadPurchaseReportCsv,
};
