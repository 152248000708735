import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { compose } from "redux";
import { settingsForm } from "../../constants";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, TextInput } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
import { dateFormat } from "../../Helpers/validators";
import { ToastBody } from "@jauntin/react-ui";
const { required } = validators;
const { normalizeDate } = normalizers;

const alertDelayClose = 3000; // milliseconds

const SettingsForm = ({ valid, dirty }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["current_app_date"]);
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className="p-5 scroll-part d-flex flex-column">
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          setCookie("current_app_date", event.target.date.value, {
            path: "/",
            maxAge: process.env.REACT_APP_TIME_TRAVEL_EXPIRE_TIME,
            domain: process.env.REACT_APP_FRONTEND_BASE_DOMAIN,
          });
          setShowSuccess(true);
        }}
        className="d-flex flex-column"
      >
        <div className="d-flex">
          <h1>Time Travel</h1>
        </div>
        <div>
          <ToastBody
            show={showSuccess}
            autohide={true}
            showCloseButton={false}
            text="Time travel date successfully applied"
            delay={alertDelayClose}
            onClose={() => setShowSuccess(false)}
            iconClassName="fal fa-check icon--large"
            className="referralToast"
            toastBodyClassName="referralToast__body"
          />
        </div>
        <div className="mt-2">
          <Field
            component={TextInput}
            validate={[required, dateFormat]}
            normalize={normalizeDate}
            name="date"
            label="Date"
            ariaLabel="Date"
            placeholder="YYYY-MM-DD"
            maxLength="10"
          />
          {cookies.current_app_date && (
            <p className="mb-4">
              Current app date is: {cookies.current_app_date}
            </p>
          )}
          <Button
            text="Apply"
            type="submit"
            className="btn-primary"
            disabled={!valid || !dirty}
          />
          <Button
            text="Clear"
            type="button"
            className="btn-outline-secondary ml-3"
            disabled={!cookies.current_app_date}
            onClick={() =>
              removeCookie("current_app_date", {
                path: "/",
                maxAge: 0,
                domain: process.env.REACT_APP_FRONTEND_BASE_DOMAIN,
              })
            }
          />
        </div>
      </Form>
    </div>
  );
};

export default compose(
  connect(),
  reduxForm({
    form: settingsForm,
  })
)(SettingsForm);
