/**
 *
 * Services provided by the onebeacon server related to Claims
 *
 */
class ClaimsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  postClaim = (payload) =>
    this.api
      .post({
        location: "/exclusiveAccess/claim",
        body: payload,
      })
      .catch((r) => r.response);

  postRelease = (payload) =>
    this.api
      .post({
        location: "/exclusiveAccess/release",
        body: payload,
      })
      .catch((r) => r.response);
}

export default ClaimsService;
