import React, { useContext } from "react";
import PropTypes from "prop-types";
import { facilityPropType } from "../../Helpers/FacilityModel";
import { DEFAULT_FACILITY_CODE } from "../../constants";
import { Button } from "@jauntin/react-ui";
import { ExclusiveAccessClaimContext } from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";

const Header = ({
  editing,
  handleShowDiscard,
  handleShowUpdate,
  setArchiveStatus,
  setEditing,
  editReset,
  pristine,
  valid,
  hasCheckedFacilityCode,
  validFacilityCode,
  facility,
}) => {
  const { checkClaim } = useContext(ExclusiveAccessClaimContext);
  return (
    <div className="content__header col-auto">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="m-0 font-weight-bold">Facility Overview</h4>
        <div className="d-flex float-right">
          {editing ? (
            <>
              <Button
                text="Discard Changes"
                className="btn-outline-secondary px-4 mx-2"
                onClick={handleShowDiscard}
              />
              <Button
                text="Save Facility"
                className="btn-primary px-4 ml-2 text-nowrap"
                onClick={handleShowUpdate}
                disabled={
                  pristine ||
                  !valid ||
                  !hasCheckedFacilityCode ||
                  !validFacilityCode
                }
              />
            </>
          ) : (
            <>
              {facility.code !== DEFAULT_FACILITY_CODE && (
                <Button
                  text="Edit Facility"
                  className="btn-primary px-4 ml-2 text-nowrap"
                  onClick={() =>
                    checkClaim(() => {
                      setArchiveStatus(false);
                      editReset();
                      setEditing(true);
                    })
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleShowDiscard: PropTypes.func.isRequired,
  handleShowUpdate: PropTypes.func.isRequired,
  setArchiveStatus: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  hasCheckedFacilityCode: PropTypes.bool.isRequired,
  validFacilityCode: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
};

export default Header;
