import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../Helpers/ReduxFormPropTypes";
import { geoCodeByPlaceId } from "../../Helpers/geoCode";
import { placeId, required } from "../../Helpers/validators";
import { getUrl, VENUE_PAGE } from "../../Helpers/URLParser";
import { getVenueForPlaceId } from "../../Actions/actions";
import customStyles from "../../Helpers/ReactSelectStyle";

const GooglePlaceID = ({ input, meta }) => {
  const [placesList, setPlacesList] = useState([]);

  const placeLookup = async (inputValue) => {
    if (!inputValue || required(inputValue) || placeId(inputValue)) {
      return [];
    }
    const geoCode = await geoCodeByPlaceId(inputValue);
    const formattedLabel = geoCode.formatted_address
      ? geoCode.formatted_address
      : inputValue;
    const venue = await getVenueForPlaceId(inputValue);
    return setPlacesList([
      {
        label: formattedLabel,
        value: inputValue,
        checked: true,
        venue,
      },
    ]);
  };

  const formatOptionLabel = ({ value, label }) => (
    <span>
      {label === value ? (
        ""
      ) : (
        <>
          <strong>{label}</strong>
          &nbsp;&nbsp;&nbsp;
        </>
      )}
      {value}
    </span>
  );
  formatOptionLabel.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  return (
    <>
      <label
        htmlFor={input.name}
        className={`d-flex justify-content-between ${
          meta.touched && meta.error ? "label form-error__label" : "label"
        }`}
      >
        <span>Google Place ID</span>
        <a
          href="https://developers.google.com/places/web-service/place-id"
          target="blank"
        >
          Where can I find a Google Place ID?
        </a>
      </label>
      {meta.touched && meta.error && typeof meta.error !== "object" && (
        <span className="form-error">{meta.error}</span>
      )}
      {meta.touched && meta.error && typeof meta.error === "object" && (
        <span className="form-error">
          {meta.error.message}
          <a
            href={getUrl(VENUE_PAGE, meta.error.facilityId, meta.error.venueId)}
            target="blank"
          >
            {meta.error.facilityCode}-{meta.error.venueCode}
          </a>
        </span>
      )}
      <Select
        name={input.name}
        className="producerSelect mb-2"
        styles={customStyles}
        value={input.value}
        options={placesList}
        placeholder=""
        onChange={(value) => {
          input.onChange(value);
        }}
        onBlur={() => {
          input.onBlur();
        }}
        onInputChange={(inputValue) => {
          if (inputValue) {
            placeLookup(inputValue);
          }
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          NoOptionsMessage: () => (
            <div className="text-center my-2">No Google places found</div>
          ),
        }}
        formatOptionLabel={formatOptionLabel}
        isClearable
        aria-label="Search by Google Place ID"
        blurInputOnSelect
      />
    </>
  );
};
GooglePlaceID.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default GooglePlaceID;
