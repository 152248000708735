/**
 *
 * Services provided by the onebeacon server related to `VenueUpload`
 *
 */
class VenueUploadService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  generateUploadLink = (data) => {
    return this.api.post({
      location: "/venueUploader/generateUploadLink",
      body: data,
    });
  };
}

export default VenueUploadService;
