import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Field, FieldArray } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import { atLeastOneGLMustBePicked } from "../../Helpers/validators";
import venuePropType from "../../Helpers/VenueTypes";
import GLChecklist from "./FormElements/GLChecklist";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import { currencyFromDecimal } from "../../Helpers/CurrencyHelper";

const GeneralLiability = ({ editing, venue }) => {
  return (
    <div className="card w-100 my-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Required Venue Coverage</strong>
        </div>
      </div>
      {editing ? (
        <div className="card-body mw--635">
          <FieldArray
            name="glLimits"
            component={GLChecklist}
            validate={atLeastOneGLMustBePicked}
          />
          <div className="font-weight-bold mt-4 mb-3">Additional Coverage</div>
          <Field
            name="damageToRentedProperty"
            component={CheckboxInput}
            type="checkbox"
            className="mb-3"
            labelClassName="pl-2"
            label="Damage to Rented Premises - $1,000,000"
          />
          <Field
            name="liquorLiability"
            component={CheckboxInput}
            type="checkbox"
            className="mb-3"
            labelClassName="pl-2"
            label="Liquor Liability - $1,000,000"
          />
          <Field
            name="hideHostLiquor"
            component={CheckboxInput}
            type="checkbox"
            className="mb-3"
            labelClassName="pl-2"
            label="Hide Host Liquor on COI?"
          />
        </div>
      ) : (
        <div className="card-body">
          <div className="font-weight-bold">General Liability</div>
          <div className="mb-3">Limit for each incident (Occurence)</div>
          {!isEmpty(venue.glLimits) &&
            venue.glLimits.map((limit) => {
              return (
                <div key={limit.id}>
                  <DollarsAndCents
                    currency={currencyFromDecimal(limit.limit)}
                  />
                </div>
              );
            })}
          <div className="font-weight-bold mt-4 mb-3">Additional Coverage</div>
          {venue.damageToRentedProperty && <div>Damage to Rented Premises</div>}
          {venue.liquorLiability && <div>Liquor Liability</div>}
          {venue.hideHostLiquor && <div>Hide Host Liquor on COI</div>}
        </div>
      )}
    </div>
  );
};

GeneralLiability.propTypes = {
  editing: PropTypes.bool.isRequired,
  venue: venuePropType.isRequired,
};

export default GeneralLiability;
