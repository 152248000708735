import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// import PropTypes from 'prop-types';
import { compose } from "redux";
import { facilityContactsForm } from "../../constants";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { CheckboxInput } from "@jauntin/react-ui";

const Facility = ({ coverage }) => {
  return (
    <>
      {coverage.facilityContacts.map((contact, i) => (
        <div className="d-flex mt-4" key={contact.id}>
          {coverage.status !== "expired" && (
            <Field
              component={CheckboxInput}
              className="mt-4 ml-2 mr-4"
              name={`emailFacilityContact[${i}]`}
              type="checkbox"
            />
          )}
          <div className="card w-100">
            <div className="card-header bg-transparent d-flex justify-content-between">
              <div className="my-auto contacts__cardTitle">
                <strong>Facility Contact &#35;{i + 1}</strong>
              </div>
            </div>
            <div className="card-body my-2">
              {contact.fullName && <div>{contact.fullName}</div>}
              {contact.role && <div>{contact.role}</div>}
              <div>{contact.email}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

Facility.propTypes = {
  coverage: coveragePropType.isRequired,
};

const mapStateToProps = (state) => ({
  coverage: state.policies.currentCoverage,
  initialValues: {
    emailFacilityContact: state.policies.currentCoverage.facilityContacts.map(
      () => true
    ),
  },
});

const FacilityContact = compose(
  connect(mapStateToProps),
  reduxForm({
    form: facilityContactsForm,
  })
)(Facility);

export default FacilityContact;
