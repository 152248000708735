import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { FieldArray } from "redux-form";
import { venuePropType } from "../../Helpers/VenueModel";
import PlacesFields from "./FormElements/PlacesFields";

const Places = ({ editing, venue }) => {
  return (
    <>
      {editing ? (
        <FieldArray
          name="places"
          component={PlacesFields}
          props={{
            venue,
          }}
        />
      ) : (
        <>
          {venue.places.map((place, i) => {
            let name = "";

            if (place.description) {
              name = place.description;
            } else {
              name = venue.address.companyName
                ? venue.address.companyName
                : venue.address.address1;
            }

            const encodedQuery = encodeURIComponent(name);

            return (
              <Card key={place.id} className="w-100 my-4">
                <Card.Header className="bg-transparent font-weight-bold">
                  {`Google Place ID #${i + 1}`}
                </Card.Header>
                <Card.Body>
                  {place.description && (
                    <p className="label">{place.description}</p>
                  )}
                  {place.placeId && (
                    <p>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodedQuery}&query_place_id=${place.placeId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {place.placeId}
                      </a>
                    </p>
                  )}
                  {place.formatted_address && <p>{place.formatted_address}</p>}
                </Card.Body>
              </Card>
            );
          })}
        </>
      )}
    </>
  );
};

Places.propTypes = {
  editing: PropTypes.bool.isRequired,
  venue: venuePropType.isRequired,
};

export default Places;
