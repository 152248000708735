import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  reset,
  initialize,
  getFormValues,
  formValueSelector,
} from "redux-form";
import { compose } from "redux";
import { ConnectedRouter, ToastBody } from "@jauntin/react-ui";
import {
  alertArchived,
  alertUpdated,
  DEFAULT_PRODUCER_CODE,
  emailProducerContactsPropType,
  facilityFormName,
  HOLD,
  modalUpdateServerErrorMessage,
  producersPropType,
  statePropType,
} from "../../constants";
import Header from "./Header";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import API from "../../Helpers/API";
import {
  checkIsValidFacilityCode,
  errorResponse,
  getFacility,
  searchProducersForFacility,
  setEditingAction,
  setNewFacilityStatusMessage,
  setSelectedProducerInResults,
  showFacilityStatusAlert,
  logout as logoutAction,
  setDefaultFacilityCommissionRate,
} from "../../Actions/actions";
import {
  facilityPropType,
  additionalInsuredPropType,
  editFacilityInitialFormValues,
} from "../../Helpers/FacilityModel";
import FacilityService from "../../Helpers/FacilityService";
import FacilityStatus from "./FacilityStatus";
import Information from "./Information";
import FacilityProducerContact from "./FacilityProducerContact";
import Contact from "./Contact";
import Debounce from "../../Helpers/Debounce";
import ModalUpdateError from "../../Components/ModalUpdateError";
import ModalArchive from "./ModalArchive";
import RouteLeavingEditGuard from "../../Components/RouteLeavingEditGuard";
import { FACILITIES_PAGE, getUrl, LOGIN_PAGE } from "../../Helpers/URLParser";
import { contactEmailsUnique } from "../../Helpers/validators";

const debouncer = new Debounce({ period: 500 });
const allFormValues = getFormValues(facilityFormName);
const formValues = formValueSelector(facilityFormName);
const alertDelayClose = 5000; // milliseconds

const Facility = ({
  editReset,
  facility,
  producerCode,
  otherInsured,
  states,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  checkAndSetValidFacilityCode,
  getProducers,
  producersList,
  setSelectedProducerResult,
  showAlert,
  setShowAlert,
  newFacilityStatusMessage,
  resetNewFacilityStatusMessage,
  producerContacts,
  facilityProducerContacts,
  commissionRateOptions,
  navToPath,
  logout,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);

  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);

  const [showModalArchive, setShowModalArchive] = useState(false);
  const handleCloseArchive = () => setShowModalArchive(false);
  const handleShowArchive = () => setShowModalArchive(true);
  const [archiveStatus, setArchiveStatus] = useState(false);

  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);

  const [validFacilityCode, setValidFacilityCode] = useState(true);
  const [hasCheckedFacilityCode, setHasCheckedFacilityCode] = useState(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");

  return (
    <>
      <Header
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        handleShowArchive={handleShowArchive}
        setArchiveStatus={setArchiveStatus}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
        hasCheckedFacilityCode={hasCheckedFacilityCode}
        validFacilityCode={validFacilityCode}
        facility={facility}
      />
      <div className="content__body">
        <ToastBody
          show={showAlert}
          autohide={true}
          showCloseButton={true}
          text={newFacilityStatusMessage}
          delay={alertDelayClose}
          onClose={() => {
            setShowAlert(false);
            resetNewFacilityStatusMessage();
          }}
          iconClassName="fal fa-check-circle icon--large"
          onCloseButtonClick={() => setShowAlert(false)}
        />
        <FacilityStatus editing={editing} facility={facility} />
        <Information
          editing={editing}
          facility={facility}
          validFacilityCode={validFacilityCode}
          setValidFacilityCode={setValidFacilityCode}
          hasCheckedFacilityCode={hasCheckedFacilityCode}
          setHasCheckedFacilityCode={setHasCheckedFacilityCode}
          checkAndSetValidFacilityCode={checkAndSetValidFacilityCode}
          otherInsured={otherInsured}
          states={states}
        />
        <FacilityProducerContact
          editing={editing}
          facility={facility}
          getProducers={getProducers}
          producersList={producersList}
          setSelectedProducerResult={setSelectedProducerResult}
          producerCode={producerCode}
          producerContacts={producerContacts}
          facilityProducerContacts={facilityProducerContacts}
          commissionRateOptions={commissionRateOptions}
        />
        <Contact editing={editing} facility={facility} />
      </div>
      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />
      <ModalUpdate
        show={showModalUpdate}
        action={() =>
          update(handleCloseUpdate, handleShowError, setEditing, archiveStatus)
        }
        handleClose={handleCloseUpdate}
      />
      <ModalArchive
        show={showModalArchive}
        handleCloseArchive={handleCloseArchive}
        facility={facility}
        archive={() =>
          update(handleCloseArchive, handleShowError, setEditing, archiveStatus)
        }
      />
      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Facility.propTypes = {
  editReset: PropTypes.func.isRequired,
  facility: facilityPropType.isRequired,
  producerCode: PropTypes.string,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  getProducers: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType),
  setSelectedProducerResult: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newFacilityStatusMessage: PropTypes.string.isRequired,
  resetNewFacilityStatusMessage: PropTypes.func.isRequired,
  producerContacts: PropTypes.arrayOf(emailProducerContactsPropType).isRequired,
  facilityProducerContacts: PropTypes.arrayOf(emailProducerContactsPropType)
    .isRequired,
  commissionRateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

Facility.defaultProps = {
  producersList: [],
};

const mapStateToProps = (state) => ({
  facility: state.facilities.currentFacility,
  producerCode:
    formValues(state, "producer") && formValues(state, "producer").value,
  otherInsured: state.facilities.currentFacility.otherAdditionalInsured,
  states: state.app.states,
  editing: state.facilities.editing,
  producersList: state.facilities.producersSearchResults,
  contacts: state.form.facility.values
    ? state.form.facility.values.contacts
    : [],
  showAlert: state.facilities.newFacilityStatus,
  newFacilityStatusMessage: state.facilities.newFacilityStatusMessage,
  producerContacts:
    state.form.facility.values &&
    state.form.facility.values.producer &&
    state.form.facility.values.producer.producerContacts
      ? state.form.facility.values.producer.producerContacts
      : [],
  facilityProducerContacts: state.facilities.currentFacility.producer
    ? state.facilities.currentFacility.facilityProducerContacts
    : [],
  commissionRateOptions: state.facilities.commissionRates,
});

const mapDispatchToProps = (dispatch) => ({
  editReset: (facility) => {
    const otherInsured = facility.otherAdditionalInsured;
    dispatch(reset(facilityFormName));
    dispatch(
      initialize(
        facilityFormName,
        editFacilityInitialFormValues(facility, otherInsured)
      )
    );
    dispatch(
      setSelectedProducerInResults({
        value: facility.producerCommissionId,
        label: `${facility.producerName} — ${facility.producerCommissionId}`,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  checkAndSetValidFacilityCode: (
    id,
    setHasCheckedFacilityCode,
    setValidFacilityCode
  ) =>
    dispatch(
      checkIsValidFacilityCode(
        id,
        setHasCheckedFacilityCode,
        setValidFacilityCode
      )
    ),
  setSelectedProducerResult: (producer, rateOptions) => {
    dispatch(setSelectedProducerInResults(producer));
    dispatch(
      setDefaultFacilityCommissionRate(
        producer && producer.value === DEFAULT_PRODUCER_CODE
          ? rateOptions[0]
          : rateOptions[rateOptions.length - 1]
      )
    );
  },
  getProducers: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(searchProducersForFacility(searchInput)),
      inputValue
    ),
  update: (handleCloseModal, handleShowError, setEditing, archive) => {
    dispatch((_, getState) => {
      const state = getState();
      const facility = state.facilities.currentFacility;
      const { id } = facility;
      let data = [];
      let values = [];

      if (archive) {
        const emailProducerContacts = facility.producer.producerContacts.filter(
          (contact) => contact.copyOnEmails
        );
        data = {
          id: facility.id,
          status: HOLD,
          archive: true,
          code: facility.code,
          hideFacilityInfo: facility.hideFacilityInfo,
          otherInsured: facility.otherAdditionalInsured,
          producerCommissionId: facility.producerCommissionId,
          emailProducerContacts,
          commissionRate: facility.facilityCommissionRate / 100,
          contacts: facility.facilityContacts,
        };
      } else {
        values = allFormValues(getState());
        const emailProducerContacts = values.producer.producerContacts.filter(
          (contact) => contact.copyOnEmails
        );
        data = {
          id,
          status: values.facilityStatus,
          code: values.facilityCode,
          hideFacilityInfo: values.hideFacilityInfo,
          otherInsured: {
            id: facility.otherAdditionalInsured.id,
            ...values.otherInsured,
          },
          producerCommissionId: values.producer.value,
          emailProducerContacts,
          commissionRate: values.facilityCommissionRate / 100,
          contacts: values.contacts,
        };
      }

      return new FacilityService(new API())
        .putFacilityEditDetails(data)
        .then((response) => {
          if (response.status === 204) {
            handleCloseModal();
            dispatch(getFacility(id));
            dispatch(showFacilityStatusAlert(true));
            dispatch(
              setNewFacilityStatusMessage(
                archive
                  ? alertArchived(facility.otherAdditionalInsured.companyName)
                  : alertUpdated(values.otherInsured.companyName)
              )
            );
            setEditing(false);
            if (archive) {
              dispatch(ConnectedRouter.push(getUrl(FACILITIES_PAGE)));
            }
          }
        })
        .catch((err) => {
          handleCloseModal();
          handleShowError();
          dispatch(errorResponse(err));
        });
    });
  },
  setShowAlert: (val) => dispatch(showFacilityStatusAlert(val)),
  resetNewFacilityStatusMessage: () =>
    dispatch(setNewFacilityStatusMessage("")),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.facility),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: facilityFormName,
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(Facility);
