import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const FacilityCode = ({
  input,
  meta,
  hasCheckedFacilityCode,
  validFacilityCode,
}) => (
  <>
    <label
      htmlFor={input.name}
      className={
        (meta.touched && meta.error) ||
        (hasCheckedFacilityCode && !validFacilityCode)
          ? "label form-error__label"
          : "label"
      }
    >
      Facility ID
    </label>
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    {!meta.error && !hasCheckedFacilityCode && (
      <span className="form-error ml-2">Validating code...</span>
    )}
    {hasCheckedFacilityCode && !validFacilityCode && (
      <div className="form-row">
        <div className="col-sm form-group form-error">
          The Facility Code has already been taken.
        </div>
      </div>
    )}
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg mw--91 mb-2"
      aria-label="Code"
      type="text"
    />
  </>
);
FacilityCode.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  validFacilityCode: PropTypes.bool,
  hasCheckedFacilityCode: PropTypes.bool,
};

FacilityCode.defaultProps = {
  hasCheckedFacilityCode: false,
  validFacilityCode: true,
};

export default FacilityCode;
