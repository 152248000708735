import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FieldMetaProps } from "../../../Helpers/ReduxFormPropTypes";
import { CheckboxInput } from "@jauntin/react-ui";

const texts = [
  "$ 1,000,000 and $ 2,000,000 (default)",
  "$ 3,000,000",
  "$ 4,000,000",
  "$ 5,000,000",
];

const GLChecklist = ({ fields, meta }) => (
  <div className="form-group">
    <div
      className={
        meta.invalid && meta.error ? "label form-error__label" : "label"
      }
    >
      General Liability Coverage
    </div>
    {meta.invalid && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <div className="font-weight-bold mb-3">
      Limit for each incident (Occurence)
    </div>
    {fields.map((glLimit, index) => (
      <div key={glLimit}>
        <Field
          name={glLimit}
          className="mb-3"
          component={CheckboxInput}
          label={texts[index]}
          labelClassName="pl-2"
          type="checkbox"
        />
      </div>
    ))}
  </div>
);

GLChecklist.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  meta: FieldMetaProps.isRequired,
};

export default GLChecklist;
