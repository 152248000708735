import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button } from "react-bootstrap";
import { facilityOtherAdditionalInsuredZipCode } from "../../Helpers/validators";
import { additionalInsuredPropType } from "../../Helpers/FacilityModel";
import StateField from "./FormElements/StateField";
import { statePropType } from "../../constants";
import { normalizeZip } from "../../normalizer";
import { TextInput } from "@jauntin/react-ui";

const VenueOtherAdditionalInsured = ({
  editing,
  otherInsured,
  states,
  clearAdditionalInsured,
}) => {
  return (
    <div className="card w-100 my-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Additional Insured </strong>
          <span className="small font-italic">(optional)</span>
        </div>
        {editing && (
          <Button variant="link" onClick={() => clearAdditionalInsured()}>
            Clear content
          </Button>
        )}
      </div>
      {editing ? (
        <div className="card-body mw--635">
          <div className="label__subtext">
            As it should appear on all certificates of insurance
          </div>
          <Field
            name="otherInsured.companyName"
            component={TextInput}
            label="Name"
            ariaLabel="Company name"
            inputClassName="form-control-lg"
          />
          <Field
            component={TextInput}
            name="otherInsured.address1"
            label="Street address"
            ariaLabel="Address"
            inputClassName="form-control-lg mb-2"
            wrapperClassName="mb-0"
          />
          <Field
            component={TextInput}
            name="otherInsured.address2"
            ariaLabel="Address"
            inputClassName="form-control-lg mb-2"
          />
          <div className="form-row">
            <Field
              component={TextInput}
              name="otherInsured.city"
              label="City"
              ariaLabel="City"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
            <Field
              name="otherInsured.state"
              component={StateField}
              type="select"
              states={states}
            />
            <Field
              component={TextInput}
              validate={[facilityOtherAdditionalInsuredZipCode]}
              normalize={normalizeZip}
              name="otherInsured.zip"
              label="Zipcode"
              ariaLabel="Zip"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
            <div hidden>
              {/* <Field
                  name="otherInsuredCountry"
                  component={CountryField}
                  validate={[required]}
                  type="select"
                  input={{ disabled: true }}
                  hidden="true"
                /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="card-body">
          {!!otherInsured.id && (
            <>
              <div>
                <strong>{otherInsured.companyName}</strong>
              </div>
              <div>
                {`${otherInsured.address1}${
                  otherInsured.address2 ? `, ${otherInsured.address2}` : ""
                }`}
              </div>
              <div>
                {`${otherInsured.city && `${otherInsured.city},`} ${
                  otherInsured.state
                }`}
                {"  "}
                {otherInsured.zip}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

VenueOtherAdditionalInsured.propTypes = {
  editing: PropTypes.bool.isRequired,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  clearAdditionalInsured: PropTypes.func.isRequired,
};

export default VenueOtherAdditionalInsured;
