import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const ModalUpdateError = ({ show, text, handleCloseError }) => (
  <Modal show={show} onHide={handleCloseError} size="lg">
    <Modal.Header className="w-100 border-0 pb-0" closeButton />
    <Modal.Body className="text-center px-5">
      <p>{text}</p>
      <br />
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4 mb-3">
      <Button
        className="btn-danger px-5"
        onClick={handleCloseError}
        text="Close"
      />
    </Modal.Footer>
  </Modal>
);

ModalUpdateError.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleCloseError: PropTypes.func.isRequired,
};

export default ModalUpdateError;
