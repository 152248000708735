import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { statePropType } from "../../constants";
import { canChange } from "../../Helpers/PolicyChangeBusinessRules";
import InsuranceContactBody from "./InsuranceContactBody";
import { Button } from "@jauntin/react-ui";

const InsuranceContact = ({
  edit,
  update,
  states,
  coverage,
  valid,
  insuranceContactType,
  contactSame,
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <div className="d-flex">
      {coverage.status !== "expired" && (
        <Field
          component={CheckboxInput}
          className="mt-4 ml-2 mr-4"
          name="emailInsuranceContact"
          type="checkbox"
          disabled={editing}
        />
      )}
      <div className="card w-100">
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Insurance Contact</strong>
          </div>
          {canChange(coverage) && coverage.status !== "expired" && !editing && (
            <Button
              text="Edit"
              onClick={() => {
                setEditing(true);
                edit();
              }}
              className="btn-link btn--edit"
            />
          )}
        </div>
        <div className="card-body">
          {(editing && (
            <>
              <InsuranceContactBody
                states={states}
                insuranceContactType={insuranceContactType}
                contactSame={contactSame}
              />
              <hr />
              <div className="form-group float-right">
                <Button
                  onClick={() => {
                    setEditing(false);
                    edit();
                  }}
                  text="Discard"
                  className="btn-outline-secondary px-4 mx-3"
                />
                <Button
                  disabled={!valid}
                  onClick={() => update().then(setEditing(false))}
                  text="Update Contact"
                  className="btn-primary text-nowrap"
                />
              </div>
            </>
          )) || (
            <>
              <p>
                {coverage.insuranceContactFirstName}{" "}
                {coverage.insuranceContactLastName}
              </p>
              {contactSame !== "yes" && insuranceContactType !== "Person" && (
                <p>{coverage.insuranceContactCompanyName}</p>
              )}
              {contactSame !== "yes" && (
                <p>
                  {stringHelpers.address(
                    coverage.insuranceContactAddress1,
                    coverage.insuranceContactAddress2,
                    coverage.insuranceContactCity,
                    coverage.insuranceContactState,
                    coverage.insuranceContactCountry,
                    coverage.insuranceContactZip
                  )}
                </p>
              )}
              <p>{`${coverage.insuranceContactPhone} - ${coverage.insuranceContactEmail}`}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
InsuranceContact.propTypes = {
  valid: PropTypes.bool,
  edit: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  update: PropTypes.func.isRequired,
  coverage: coveragePropType.isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  contactSame: PropTypes.string.isRequired,
};
InsuranceContact.defaultProps = {
  valid: false,
};

export default InsuranceContact;
