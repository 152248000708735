import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { MAX_FACILITY_CONTACTS } from "../../../constants";
import { contactEmailsUniqueMessage } from "../../../Helpers/validators";
import { TextInput, EmailInput, CheckboxInput } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
const { required, email } = validators;
const { normalizeEmail } = normalizers;

const ContactFields = ({ fields, wide }) => (
  <>
    {fields.map((contact, i) => {
      return (
        <Card
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="w-100 mt-4"
        >
          <Card.Header className="bg-transparent d-flex justify-content-between">
            <div className="font-weight-bold  ">
              {`Facility Contact #${i + 1}`}
            </div>
            <Button
              variant="link"
              className="btn-link--delete"
              onClick={() => fields.remove(i)}
            >
              Delete
            </Button>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={wide ? "10" : "7"}>
                <Field
                  component={TextInput}
                  name={`${contact}.fullName`}
                  label="Full name"
                  ariaLabel="Full name"
                  inputClassName="form-control-lg mb-4"
                />
                <Field
                  component={TextInput}
                  name={`${contact}.role`}
                  label="Role"
                  ariaLabel="Role"
                  inputClassName="form-control-lg mb-4"
                />
                <Field
                  component={EmailInput}
                  validate={[required, email]}
                  normalize={normalizeEmail}
                  name={`${contact}.email`}
                  placeholder=""
                  errorClassName="mb-2"
                  inputClassName="form-control-lg mb-4"
                  showErrors={(meta) =>
                    (meta.error && meta.touched) ||
                    meta.error === contactEmailsUniqueMessage
                  }
                />
                <div className="form-group">
                  <Field
                    component={CheckboxInput}
                    className="d-flex align-items-center"
                    labelClassName="pl-2 contacts__copyOnEmail"
                    ariaLabel="Copy on emails checkbox"
                    type="checkbox"
                    name={`${contact}.copyOnEmails`}
                    label="Copy on emails containing event coverage information."
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    })}
    {fields.length < MAX_FACILITY_CONTACTS && (
      <Button
        block
        variant="outline-secondary"
        className="px-4 mt-4"
        onClick={() => fields.push({})}
      >
        Add contact
      </Button>
    )}
  </>
);

ContactFields.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  wide: PropTypes.bool,
};
ContactFields.defaultProps = { wide: false };

export default ContactFields;
