import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

const ContactSameField = ({ name }) => (
  <>
    <div className="font-weight-bold">Is the contact address the same as </div>
    <div className="font-weight-bold mb-3">the insured?</div>
    <div className="form-group form-row mw--455">
      <div className="col-auto my-auto">
        <div className="custom-control custom-radio custom-control-inline">
          <Field
            name={name}
            value="yes"
            id={`${name}Yes`}
            component="input"
            type="radio"
            className="custom-control-input"
          />
          <label className="custom-control-label label" htmlFor={`${name}Yes`}>
            Yes
          </label>
        </div>
      </div>
      <div className="col-auto my-auto">
        <div className="custom-control custom-radio custom-control-inline">
          <Field
            name={name}
            value="no"
            id={`${name}No`}
            component="input"
            type="radio"
            className="custom-control-input"
          />
          <label className="custom-control-label label" htmlFor={`${name}No`}>
            No
          </label>
        </div>
      </div>
    </div>
  </>
);
ContactSameField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ContactSameField;
