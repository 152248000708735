import React, { useState, useCallback } from "react";
import { DateRangePicker } from "@jauntin/react-ui";
import { downloadPurchaseReportCsv } from "../../Helpers/DocumentDownload";
import { policyDisabledDatesForRangePicker } from "../../Helpers/DateHelpers";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { policyPurchaseReportForm } from "../../constants";

const PolicyPurchaseReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { startDate, endDate } = dateRange;
  const [optimized, setOptimized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const disabledDates = useCallback(
    (day) => policyDisabledDatesForRangePicker({ startDate })(day),
    [startDate]
  );
  const timezone = useSelector((state) => state.app.timezone);

  return (
    <div>
      <strong>Policy Purchase Report</strong>
      <p className="mb-3">Policies purchased within a certain period</p>
      <div className="row">
        <div className="col-md-6">
          <div className="custom-control custom-checkbox mb-4 pt-4 zi-0">
            <Field
              component={DateRangePicker}
              input={{
                value: dateRange,
                onChange: setDateRange,
              }}
              disabledDates={disabledDates}
              inputClassName="form-control-lg"
            />
            <input
              id="useOptimizedReport"
              className="custom-control-input"
              type="checkbox"
              checked={optimized}
              onChange={() => setOptimized(!optimized)}
            />
            <label
              htmlFor="useOptimizedReport"
              className="custom-control-label font-weight-bold pl-2"
            >
              Use Optimized Report
            </label>
          </div>
          <div className="mt-4">
            <button
              className="btn btn-primary py-2"
              disabled={!startDate || !endDate || fetching}
              onClick={() => {
                setFetching(true);
                downloadPurchaseReportCsv(
                  startDate,
                  endDate,
                  optimized,
                  timezone
                ).then(() => setFetching(false));
              }}
            >
              {fetching && (
                <span
                  className="policy__download spinner-border spinner-border-sm align-middle mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(),
  reduxForm({
    form: policyPurchaseReportForm,
  })
)(PolicyPurchaseReport);
