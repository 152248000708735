import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StatusField from "./FormElements/StatusField";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { facilityPropType } from "../../Helpers/FacilityModel";
import copyToClipboard from "../../Helpers/CopyToClipboard";
import { facilityFormName } from "../../constants";
import { ToastBody } from "@jauntin/react-ui";
import API from "../../Helpers/API";
import VenueUploadService from "../../Helpers/VenueUploadService";

const alertDelayClose = 3000; // milliseconds

const FacilityStatus = ({ editing, facility }) => {
  const { referralLink, id } = facility;
  const [showToast, setShowToast] = useState(false);
  const [showUploadLinkToast, setShowUploadLinkToast] = useState(false);
  const [showUploadLinkToastError, setShowUploadLinkToastError] =
    useState(false);

  return (
    <>
      <div className="card border-0">
        <Form.Row className="justify-content-between align-items-center mb-4">
          <Col xs="auto">
            {editing ? (
              <Field
                component={StatusField}
                name="facilityStatus"
                type="select"
              />
            ) : (
              <>
                <span className="label mr-2">Status:</span>
                <span>{stringHelpers.firstToUpper(facility.status)}</span>
              </>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Referral link:</strong>
              <em>{` ${referralLink}`}</em>
            </div>
            <div className="my-auto">
              <Button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  copyToClipboard(referralLink);
                  setShowToast(true);
                }}
              >
                Copy
              </Button>
            </div>
          </Col>
        </Form.Row>
        <Form.Row className="justify-content-end align-items-center mb-4">
          <Col className="d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Venue upload link:</strong>
              <em></em>
            </div>
            <div className="my-auto">
              <Button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  new VenueUploadService(new API())
                    .generateUploadLink({
                      facility_id: id,
                    })
                    .then((result) => {
                      if (result.status === 201) {
                        copyToClipboard(result.data?.uploadLink);
                        setShowUploadLinkToast(true);
                      }
                    })
                    .catch((err) => {
                      setShowUploadLinkToastError(true);
                    });
                }}
              >
                Copy
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>
      <ToastBody
        show={showToast}
        autohide={true}
        showCloseButton={false}
        text={
          <div>
            <div>
              <strong>
                {`${stringHelpers.firstToUpper(
                  facilityFormName
                )} referral link`}
              </strong>
            </div>
            <div>Copied!</div>
          </div>
        }
        delay={alertDelayClose}
        onClose={() => setShowToast(false)}
        iconClassName="fal fa-check icon--large"
        className="referralToast"
        toastBodyClassName="referralToast__body"
      />
      <ToastBody
        show={showUploadLinkToast}
        autohide={true}
        showCloseButton={false}
        text={
          <div>
            <div>
              <strong>Venue upload link</strong>
            </div>
            <div>Copied!</div>
          </div>
        }
        delay={alertDelayClose}
        onClose={() => setShowUploadLinkToast(false)}
        iconClassName="fal fa-check icon--large"
        className="referralToast"
        toastBodyClassName="referralToast__body"
      />
      <ToastBody
        show={showUploadLinkToastError}
        autohide={true}
        showCloseButton={false}
        text="There was an error while link was generated"
        delay={alertDelayClose}
        onClose={() => setShowUploadLinkToastError(false)}
        iconClassName="fal fa-check icon--large"
        className="referralToast"
        toastBodyClassName="referralToast__body error"
      />
    </>
  );
};

FacilityStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
};

export default FacilityStatus;
