/**
 * Takes currency number values and manipulates them, e.g. display as $100
 * Can deal with decimals or ints (ints represent smallest unit, ie cents)
 *
 * Use the helper functions to instantiate an object.
 * e.g. currencyFromDecimal(1000).format(DOLLAR_ONLY_FORMAT); // 10
 */
import PropTypes from "prop-types";

export const currencyHelperPropType = PropTypes.shape({
  dollars: PropTypes.func,
  cents: PropTypes.func,
});

export const DOLLAR_ONLY_FORMAT = "DOLLAR_ONLY_FORMAT";
export const CENTS_ONLY_FORMAT = "CENTS_ONLY_FORMAT";

class CurrencyHelper {
  constructor() {
    return this;
  }

  setValue(value) {
    this.value = value;
    return this;
  }

  convertFromInt() {
    this.value = (this.value * 1.0) / 100;
    return this;
  }

  format(format) {
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(this.value);
    switch (format) {
      case DOLLAR_ONLY_FORMAT:
        return formatted.replace(/\.[0-9]{2}$/, "");
      case CENTS_ONLY_FORMAT:
        return formatted.replace(/^.*(\.[0-9]{2})$/, "$1");
      default:
        return formatted;
    }
  }

  dollars() {
    // Remove decimal - just dollar
    return this.formatDollars().replace(/\.[0-9]{2}$/, "");
  }

  cents() {
    return this.formatDollars().replace(/^.*(\.[0-9]{2})$/, "$1");
  }

  formatDollars() {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(this.value);
  }
}

export const currencyFromDecimal = (value) => {
  return new CurrencyHelper().setValue(value);
};
export const currencyFromInt = (value) => {
  return new CurrencyHelper().setValue(value).convertFromInt();
};
