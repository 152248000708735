import React from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";

const PopoverContent = ({
  contactId,
  setShowModalRemove,
  setSelectedContacts,
  setShowModalOverwrite,
}) => (
  <>
    <div className="mb-2">
      <Button
        className="popover-item__btn "
        text={
          <>
            <i className="far fa-edit mr-2 col-1 p-0 popover-item__icon" />
            <span className="col-auto p-0">
              Overwrite&nbsp;contact&nbsp;information
            </span>
          </>
        }
        onClick={() => {
          setSelectedContacts([contactId]);
          setShowModalOverwrite(true);
          setTimeout(() => {
            document.body.click();
          }, 300);
        }}
      />
    </div>
    <div>
      <Button
        className="popover-item__btn"
        text={
          <>
            <i className="far fa-user-minus mr-2 col-1 p-0 popover-item__icon" />
            <span className="col-auto p-0">Remove contact</span>
          </>
        }
        onClick={() => {
          setSelectedContacts([contactId]);
          setShowModalRemove(true);
          setTimeout(() => {
            document.body.click();
          }, 300);
        }}
      />
    </div>
  </>
);

PopoverContent.propTypes = {
  contactId: PropTypes.number.isRequired,
  setShowModalRemove: PropTypes.func.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
};

export default PopoverContent;
