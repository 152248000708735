import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, reset } from "redux-form";
import { Row, Col, Button } from "react-bootstrap";
import {
  setPage,
  getSearchVenues as getSearchVenuesAction,
  setVenueStatusMessage,
  showVenueStatusAlert,
  searchFacilitiesForVenue,
  setSelectedFacilityInResults,
  reassignVenueFacility,
  resetReassignedVenueData,
  clearVenuesTable,
  getFacility,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import { getUrl, ADD_VENUE_PAGE } from "../../Helpers/URLParser";
import { Pagination, ToastBody } from "@jauntin/react-ui";
import {
  alertReassignedVenueFacility,
  facilitiesPropType,
  paginationProps,
  reassignVenueFormName,
  VENUE_REASSIGN_SEARCH,
} from "../../constants";
import { emptyVenue, venuePropType } from "../../Helpers/VenueModel";
import ModalUpload from "./ModalUpload";
import SearchField from "../../Components/SearchField";
import ModalReassignVenue from "./ModalReassignVenue";
import StickyAlertMessage from "./FormElements/StickyAlertMessage";
import ModalExport from "./ModalExport";
import { ExclusiveAccessClaimContext } from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";
import { useHistory } from "react-router-dom";

const debouncer = new Debounce({ period: 500 });
const formValues = formValueSelector(reassignVenueFormName);
const alertDelayClose = 5000; // milliseconds

const Search = ({
  venueTableData,
  pagination,
  searchTerm,
  states,
  stateCodes,
  change,
  loadFacility,
  goToPage,
  showAlert,
  setShowAlert,
  venueStatusMessage,
  resetVenueStatusMessage,
  facilityId,
  getFacilities,
  facilitiesList,
  currentFacilityName,
  hideFacilityInfo,
  setSelectedFacilityResult,
  facilityCode,
  facilityName,
  reassignVenueReset,
  reassignVenue,
  reassignedVenue,
  showReassignedVenueAlert,
  getSearchVenues,
  clearVenues,
  additionalInsuredCities,
}) => {
  const history = useHistory();
  useEffect(() => {
    getSearchVenues("", 1, pagination.perPage, facilityId);

    return () => {
      clearVenues();
    };
  }, [getSearchVenues, pagination.perPage, facilityId, clearVenues]);

  const [showUpload, setShowUpload] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showModalReassign, setShowModalReassign] = useState(false);
  const handleCloseReassign = () => setShowModalReassign(false);
  const handleShowReassign = () => setShowModalReassign(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [selectedVenue, setSelectedVenue] = useState(emptyVenue);
  const [modalState, setModalState] = useState(VENUE_REASSIGN_SEARCH);
  const { checkClaim } = useContext(ExclusiveAccessClaimContext);

  let resultsMessage = "";
  let venuesText = "";
  if (pagination.total === 0) {
    resultsMessage =
      "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
  }
  venuesText = pagination.total === 1 ? "venue" : "venues";
  const hideFacilityInfoMessage =
    "Facility Information is set to not print on any certificates. You can change that setting in the Facility Overview page.";

  return (
    <>
      <div className="p-5">
        <Row className="justify-content-between">
          <Col xs="auto">
            <h1>Venues</h1>
          </Col>
          <Col className="text-right my-auto">
            <Button
              onClick={() => setShowExport(true)}
              className="ml-2 mb-2"
              variant="outline-primary"
            >
              Export venues
            </Button>
            <Button
              onClick={() => checkClaim(() => setShowUpload(true))}
              className="ml-2 mb-2"
            >
              Upload venues by XLS
            </Button>
            <Button
              onClick={() =>
                checkClaim(() =>
                  history.push(getUrl(ADD_VENUE_PAGE, facilityId))
                )
              }
              className="btn btn-primary mb-2 ml-2"
            >
              Add venue manually
            </Button>
          </Col>
        </Row>
        <StickyAlertMessage
          show={hideFacilityInfo}
          message={hideFacilityInfoMessage}
        />
        <SearchField
          label="Search by venue names, venue codes, location or contacts"
          fieldName="venuesSearchQuery"
          change={change}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${venuesText}`}
        />
        <ToastBody
          show={showAlert}
          autohide={false}
          showCloseButton={true}
          text={venueStatusMessage}
          delay={alertDelayClose}
          onClose={() => {
            setShowAlert(false);
            resetVenueStatusMessage();
          }}
          iconClassName="fal fa-check-circle icon--large"
          onCloseButtonClick={() => setShowAlert(false)}
        />
        {venueTableData.length !== 0 ? (
          <>
            <SearchResultsTable
              venuesList={venueTableData}
              refresh={change}
              searchTerm={searchTerm}
              facilityId={facilityId}
              getFacilities={getFacilities}
              facilitiesList={facilitiesList}
              setSelectedFacilityResult={setSelectedFacilityResult}
              setSelectedVenue={setSelectedVenue}
              handleShowReassign={handleShowReassign}
              showModalError={showModalError}
              handleCloseError={handleCloseError}
              setModalState={setModalState}
            />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        ) : (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>

      <ModalUpload
        show={showUpload}
        action={() => {}}
        handleClose={() => setShowUpload(false)}
        facilityId={facilityId}
        refresh={() => {
          loadFacility();
          change("");
        }}
      />

      {states ? (
        <ModalExport
          show={showExport}
          action={() => {}}
          handleClose={() => setShowExport(false)}
          facilityId={facilityId}
          states={states.filter((state) => stateCodes.includes(state.code))}
          additionalInsuredCities={additionalInsuredCities}
        />
      ) : null}

      <ModalReassignVenue
        show={showModalReassign}
        handleClose={handleCloseReassign}
        handleShowError={handleShowError}
        selectedVenue={selectedVenue}
        getFacilities={getFacilities}
        facilitiesList={facilitiesList}
        currentFacilityName={currentFacilityName}
        setResults={setSelectedFacilityResult}
        facilityCode={facilityCode}
        facilityName={facilityName}
        reassignVenueReset={reassignVenueReset}
        reassignVenue={reassignVenue}
        refresh={change}
        modalState={modalState}
        setModalState={setModalState}
        reassignedVenue={reassignedVenue}
        showAlert={showReassignedVenueAlert}
      />
    </>
  );
};

Search.propTypes = {
  venueTableData: PropTypes.arrayOf(venuePropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  facilityId: PropTypes.number.isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  stateCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  change: PropTypes.func.isRequired,
  loadFacility: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  venueStatusMessage: PropTypes.string.isRequired,
  resetVenueStatusMessage: PropTypes.func.isRequired,
  getFacilities: PropTypes.func.isRequired,
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  currentFacilityName: PropTypes.string.isRequired,
  hideFacilityInfo: PropTypes.bool.isRequired,
  setSelectedFacilityResult: PropTypes.func.isRequired,
  facilityCode: PropTypes.string.isRequired,
  facilityName: PropTypes.string.isRequired,
  reassignVenueReset: PropTypes.func.isRequired,
  reassignVenue: PropTypes.func.isRequired,
  reassignedVenue: venuePropType.isRequired,
  showReassignedVenueAlert: PropTypes.func.isRequired,
  getSearchVenues: PropTypes.func.isRequired,
  clearVenues: PropTypes.func.isRequired,
  additionalInsuredCities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Search.defaultProps = {
  facilitiesList: [],
};

const mapStateToProps = (state) => {
  let facilityCode = "";
  let facilityName = "";
  const facility = formValues(state, "facility");
  if (facility) {
    facilityName = facility.label;
    facilityCode = facility.value;
  }
  return {
    venueTableData: state.venues.venueTableData,
    pagination: state.venues.venuePagination,
    searchTerm: state.venues.searchTerm,
    showAlert: state.venues.newVenueStatus,
    states: state.app.states,
    stateCodes: state.facilities.currentFacility.states,
    venueStatusMessage: state.venues.venueStatusMessage,
    facilitiesList: state.facilities.reassignVenueFacilitiesSearchResults,
    currentFacilityName:
      state.facilities.currentFacility.otherAdditionalInsured.companyName,
    facilityCode,
    facilityName,
    reassignedVenue: state.venues.reassignedVenue,
    additionalInsuredCities:
      state.facilities.currentFacility.additionalInsuredCities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage, facilityId) =>
    debouncer.do(
      (searchInput) =>
        dispatch(getSearchVenuesAction(searchInput, page, perPage, facilityId)),
      inputValue
    ),
  loadFacility: (facilityId) => dispatch(getFacility(facilityId)),
  goToPage: (searchTerm, newPage, perPage, facilityId) => {
    dispatch(setPage(newPage));
    dispatch(getSearchVenuesAction(searchTerm, newPage, perPage, facilityId));
  },
  setShowAlert: (val) => dispatch(showVenueStatusAlert(val)),
  resetVenueStatusMessage: () => dispatch(setVenueStatusMessage("")),
  getFacilities: (searchTerm) =>
    debouncer.do(
      (searchInput) => dispatch(searchFacilitiesForVenue(searchInput)),
      searchTerm
    ),
  setSelectedFacilityResult: (facility) =>
    dispatch(setSelectedFacilityInResults(facility)),
  reassignVenueReset: (facilityId) => {
    dispatch(reset(reassignVenueFormName));
    dispatch(resetReassignedVenueData());
    dispatch(getFacility(facilityId));
  },
  reassignVenue: (
    venue,
    facilityCode,
    refresh,
    setModalState,
    handleCloseReassign,
    handleShowError
  ) =>
    dispatch(
      reassignVenueFacility(
        venue,
        facilityCode,
        refresh,
        setModalState,
        handleCloseReassign,
        handleShowError
      )
    ),
  showReassignedVenueAlert: (venue, facilityName) => {
    dispatch(
      setVenueStatusMessage(alertReassignedVenueFacility(venue, facilityName))
    );
    dispatch(showVenueStatusAlert(true));
  },
  getSearchVenues: (searchInput, page, perPage, facilityId) =>
    dispatch(getSearchVenuesAction(searchInput, page, perPage, facilityId)),
  clearVenues: () => dispatch(clearVenuesTable()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(
      inputValue,
      1,
      stateProps.pagination.perPage,
      ownProps.facilityId
    ),
  loadFacility: () => dispatchProps.loadFacility(ownProps.facilityId),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage,
      ownProps.facilityId
    ),
  reassignVenueReset: () =>
    dispatchProps.reassignVenueReset(ownProps.facilityId),
});

const SearchVenuesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchVenuesContainer;
