import React, { useState } from "react";
import { initialize, reset } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { getUrl, VENUE_PAGE } from "../../Helpers/URLParser";
import {
  editVenueInitialFormValues,
  venuePropType,
} from "../../Helpers/VenueModel";
import {
  errorResponse,
  getSearchVenues,
  setEditingAction,
  setVenueStatusMessage,
} from "../../Actions/actions";
import {
  venueFormName,
  modalUpdateServerErrorMessage,
  alertUnblocked,
  paginationProps,
} from "../../constants";
import ModalUpdateError from "../../Components/ModalUpdateError";
import VenueService from "../../Helpers/VenueService";
import API from "../../Helpers/API";
import { Button } from "@jauntin/react-ui";

// All commented items are related to archive functionality. We will be using these soon

const PopoverContent = ({
  venue,
  editReset,
  setEditing,
  blockVenue,
  setShowAlert,
  searchTerm,
  pagination,
}) => (
  <>
    <div className="mb-2">
      <NavLink
        to={getUrl(VENUE_PAGE, venue.facilityId, venue.id)}
        onClick={() => {
          editReset(venue);
          setEditing(true);
        }}
      >
        <i className="fal fa-edit mr-2 col-1 p-0 popover-item__icon" />
        <span className="col-auto p-0">Edit blocked venue</span>
      </NavLink>
    </div>
    <div>
      <Button
        className="btn popover-item__btn"
        text={
          <>
            <i className="fal fa-unlock-alt mr-2 col-1 p-0 popover-item__icon" />
            <span className="col-auto p-0">Unblock venue</span>
          </>
        }
        onClick={() => {
          blockVenue(venue, null, setShowAlert, searchTerm, pagination);
        }}
      />
    </div>
  </>
);

PopoverContent.propTypes = {
  venue: venuePropType.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  blockVenue: PropTypes.func.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  pagination: paginationProps.isRequired,
};

const SearchResults = ({
  venuesList,
  searchTerm,
  editReset,
  setEditing,
  blockVenue,
  setShowAlert,
  pagination,
}) => {
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);

  return (
    <>
      <table className="table subsection-table mt-4">
        <thead>
          <tr>
            <th scope="col">Venue</th>
            <th scope="col">Venue Code</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {venuesList.length === 0 &&
          pagination.page === pagination.totalPages + 1 ? (
            <tr>
              <td colSpan="3">&hellip;</td>
            </tr>
          ) : (
            venuesList.map((venue) => (
              <tr key={venue.id}>
                <td>
                  <Link to={getUrl(VENUE_PAGE, venue.facilityId, venue.id)}>
                    {venue.address.companyName}
                  </Link>
                  {venue.address.address1 !== "" && (
                    <>
                      <div>{venue.address.address1}</div>
                      {venue.address.address2 && (
                        <div>{venue.address.address2}</div>
                      )}
                      <div>{`${venue.address.city}, ${venue.address.state}, ${venue.address.zip}`}</div>
                    </>
                  )}
                </td>
                <td>
                  <Link to={getUrl(VENUE_PAGE, venue.facilityId, venue.id)}>
                    {`${venue.facilityCode}-${venue.venueCode}`}
                  </Link>
                </td>
                <td>
                  <PopoverTrigger
                    content={
                      <PopoverContent
                        venue={venue}
                        editReset={editReset}
                        setEditing={setEditing}
                        blockVenue={blockVenue}
                        pagination={pagination}
                        searchTerm={searchTerm}
                        setShowAlert={setShowAlert}
                      />
                    }
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

SearchResults.propTypes = {
  venuesList: PropTypes.any.isRequired,
  searchTerm: PropTypes.string.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  blockVenue: PropTypes.func.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  pagination: paginationProps.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (venue) => {
    const { otherInsured, address } = venue;

    const gl = [false, false, false, false];
    venue.glLimits.forEach((limit) => {
      if (limit.id === 1 || limit.id === 2) {
        gl[0] = true;
      } else {
        gl[limit.id - 2] = true;
      }
    });

    const googlePlaces = [];
    venue.places.map((place) => {
      return googlePlaces.push({
        description: place.description,
        placeId: { label: place.placeId, value: place.placeId },
      });
    });
    dispatch(reset(venueFormName));
    dispatch(
      initialize(
        venueFormName,
        editVenueInitialFormValues(
          venue,
          address,
          googlePlaces,
          gl,
          otherInsured
        )
      )
    );
  },
  blockVenue: (venue, blockedAt, setShowAlert, searchTerm, pagination) =>
    dispatch(() => {
      new VenueService(new API())
        .patchVenueBlockVenue(venue, blockedAt)
        .then((response) => {
          if (response.status === 204) {
            dispatch(getSearchVenues(searchTerm, pagination.page));
            dispatch(setVenueStatusMessage(alertUnblocked(venue)));
            dispatch(setShowAlert(true));
          }
        })
        .catch((err) => dispatch(errorResponse(err)));
    }),
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
