/**
 * Services for the alert keywords admin panel
 */
class AlertKeywordsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  all = () =>
    this.api.get({
      location: "/alert-keywords/all",
    });

  save = (body) =>
    this.api.post({
      location: `/alert-keywords/`,
      body,
    });
}

export default AlertKeywordsService;
