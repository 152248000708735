import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { initialize, reset } from "redux-form";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { producerPropType } from "../../Helpers/ProducerModel";
import { getUrl, PRODUCER_PAGE } from "../../Helpers/URLParser";
import { HOLD, producerFormName, DEFAULT_PRODUCER_NAME } from "../../constants";
import { setEditingAction } from "../../Actions/actions";
import { Button } from "@jauntin/react-ui";

const PopoverContent = ({ producer, editReset, setEditing }) => {
  return (
    <>
      <NavLink
        to={getUrl(PRODUCER_PAGE, producer.id)}
        onClick={() => {
          editReset(producer);
          setEditing(true);
        }}
      >
        <i className="fal fa-edit mr-2 col-1 p-0" />
        <span className="col-auto p-0">Edit producer details</span>
      </NavLink>
    </>
  );
};
PopoverContent.propTypes = {
  producer: producerPropType.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const SearchResults = ({
  producersList,
  loadProducer,
  editReset,
  setEditing,
}) => {
  return (
    <table className="table subsection-table mt-4">
      <thead>
        <tr>
          <th scope="col">Producer Name</th>
          <th scope="col">Producer Code</th>
          <th scope="col">Facilities</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {producersList.map((producer) => (
          <tr key={`row${producer.id}`}>
            <td>
              <Button
                className="btn-link btn--edit text-left"
                text={producer.name}
                onClick={() => loadProducer(producer.id)}
              />
            </td>
            <td>{producer.commissionId}</td>
            <td>{stringHelpers.commaSeparatedNumber(producer.facilities)}</td>
            <td>
              {stringHelpers.firstToUpper(
                producer.status === HOLD ? "On Hold" : producer.status
              )}
            </td>
            <td>
              {producer.name !== DEFAULT_PRODUCER_NAME && (
                <PopoverTrigger
                  content={
                    <PopoverContent
                      producer={producer}
                      editReset={editReset}
                      setEditing={setEditing}
                    />
                  }
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SearchResults.propTypes = {
  producersList: PropTypes.any.isRequired,
  loadProducer: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (producer) => {
    dispatch(reset(producerFormName));
    dispatch(
      initialize(producerFormName, {
        producerStatus: producer.status,
        producerName: producer.name,
        producerCommissionId: producer.commissionId,
        producerFullName: producer.contactName,
        producerCommissionRate: producer.commissionRate,
        producerContacts: producer.producerContacts,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
