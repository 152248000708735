import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { statePropType } from "../../constants";
import RenterField from "./FormElements/RenterField";
import CompanyTypeField from "./FormElements/CompanyTypeField";
import JointVentureField from "./FormElements/JointVentureField";
import { zipCode } from "../../Helpers/validators";
import { normalizeZip } from "../../normalizer";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import ContiguousStateField from "./FormElements/ContiguousStateField";
import { TextInput } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const Insured = ({
  editing,
  coverage,
  renterAddressType,
  insuredCompanyType,
  states,
}) => (
  <>
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Insured</strong>
      </div>
      <div className="card-body">
        {editing ? (
          <>
            <RenterField name="renterAddressType" validate={[required]} />
            {renterAddressType === "Person" && (
              <div id="insuredIndividual" className="form-row">
                <Field
                  component={TextInput}
                  validate={[required]}
                  name="renterFirstName"
                  label="First Name"
                  ariaLabel="First Name"
                  placeholder="First Name"
                  wrapperClassName="col-sm"
                  inputClassName="form-control-lg"
                />
                <Field
                  component={TextInput}
                  validate={[required]}
                  name="renterLastName"
                  label="Last Name"
                  ariaLabel="Last Name"
                  placeholder="Last Name"
                  wrapperClassName="col-sm"
                  inputClassName="form-control-lg"
                />
              </div>
            )}
            {renterAddressType !== "Person" && (
              <>
                <div id="insuredCompany" className="form-row">
                  <Field
                    component={TextInput}
                    validate={[required]}
                    name="renterCompanyName"
                    label="Company name"
                    ariaLabel="Company name"
                    placeholder="Company name"
                  />
                </div>
                <div className="row">
                  <Field
                    component={CompanyTypeField}
                    validate={[required]}
                    name="insuredCompanyType"
                    value="Corporation"
                    type="text"
                  />
                  {insuredCompanyType === "jointVenture" && (
                    <JointVentureField
                      name="jointVentureType"
                      validate={[required]}
                    />
                  )}
                </div>
              </>
            )}
            <Field
              component={TextInput}
              validate={[required]}
              name="renterAddress1"
              label="Street Address"
              ariaLabel="Address"
              placeholder="Address"
              inputClassName="form-control-lg"
            />
            <div className="form-row">
              <Field
                component={TextInput}
                validate={[required]}
                name="renterCity"
                label="City"
                ariaLabel="City"
                placeholder="City"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                component={ContiguousStateField}
                name="renterState"
                type="select"
                states={states}
              />
              <Field
                component={TextInput}
                validate={[zipCode, required]}
                name="renterZip"
                label="Zip"
                ariaLabel="Zip"
                placeholder="Zip"
                normalize={normalizeZip}
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
            </div>
          </>
        ) : (
          <>
            <p>{stringHelpers.renterName(coverage)}</p>
            <p>
              {coverage.renterAddress1}
              {coverage.renterAddress2 && (
                <>
                  <br />
                  {coverage.renterAddress2}
                </>
              )}
            </p>
            <p>{`${coverage.renterCity}, ${coverage.renterState}, ${coverage.renterZip}, ${coverage.renterCountry}`}</p>
          </>
        )}
      </div>
    </div>
  </>
);
Insured.propTypes = {
  editing: PropTypes.bool.isRequired,
  coverage: coveragePropType.isRequired,
  renterAddressType: PropTypes.string.isRequired,
  insuredCompanyType: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
};

export default Insured;
