import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { producerPropType } from "../../Helpers/ProducerModel";
import ProducersBreadcrumbs from "./ProducersBreadcrumbs";

const ProducersDetailsHeader = ({ location, producer, timezone }) => (
  <div className="subsection-menu__header">
    <ProducersBreadcrumbs
      location={location}
      commissionID={producer.commissionId}
    />
    <h3>{producer.name}</h3>
    <h6>{`#${producer.commissionId}`}</h6>
    <div>
      <span className="subsection-menu__date">
        {`Created on ${dateHelpers.lettersAndNumbers(
          producer.created,
          timezone
        )}`}
      </span>
    </div>
  </div>
);

ProducersDetailsHeader.propTypes = {
  location: PropTypes.string.isRequired,
  producer: producerPropType.isRequired,
};

const mapStateToProps = (state) => ({
  location: state.router.location.pathname,
  producer: state.producers.currentProducer,
  timezone: state.app.timezone,
});

const ProducersDetails = connect(mapStateToProps)(ProducersDetailsHeader);

export default ProducersDetails;
