import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import * as qs from "query-string";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoginLockOut from "../Login/LoginLockOut";
import { requestResetPasswordForm } from "../../constants";
import { required } from "../../Helpers/validators";
import {
  FormPropTypes,
  FieldInputProps,
  FieldMetaProps,
} from "../../Helpers/ReduxFormPropTypes";
import AuthService from "../../Helpers/AuthService";
import API from "../../Helpers/API";
import {
  setResetFormSuccessMessage,
  setLoginLockedOut,
} from "../../Actions/actions";

const passwordField = ({ input, meta, placeholder }) => (
  <div className="form-group">
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg"
      aria-label="Password"
      type="password"
      placeholder={placeholder}
    />
    {meta.touched && meta.error && (
      <div className="form-error my-1 mx-2 text-left">{meta.error}</div>
    )}
  </div>
);
passwordField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  placeholder: PropTypes.string.isRequired,
};

const confirmPassword = (value, allValues) =>
  value !== allValues.password ? "Passwords must match." : undefined;

const RequestResetPassword = ({
  valid,
  handleSubmit,
  submitting,
  error,
  successMessage,
  lockedOut,
  setShowLockout,
}) => {
  return (
    (!lockedOut && (
      <form onSubmit={handleSubmit}>
        <div className="container text-left">
          <div className="col-sm-8 col-lg-6 mx-auto">
            <br />
            <br />
            <p>
              Please enter your new password below. Your new password must be a
              valid password of 15 or more characters with at least one of each:
            </p>
            <br />
            <ul>
              <li>
                <p>lowercase letter</p>
              </li>
              <li>
                <p>uppercase letter</p>
              </li>
              <li>
                <p>number</p>
              </li>
              <li>
                <p>special character</p>
              </li>
            </ul>
            <p>
              It cannot be changed more than once a day, cannot be one of your
              12 most recently used passwords or cannot be derived from your
              user ID/email.
            </p>
            <div className="mt-3" />
            <Field
              component={passwordField}
              validate={[required]}
              name="password"
              placeholder="New Password"
            />
            <Field
              component={passwordField}
              validate={[required, confirmPassword]}
              name="confirmPassword"
              placeholder="Confirm New Password"
            />
            {error && <div className="form-error my-1 mx-2">{error}</div>}
            {successMessage && (
              <div className="form-success my-1 mx-2 form-success__label">
                {successMessage}
                Click
                <Link to="/"> here </Link>
                to return to the login screen.
              </div>
            )}

            {!successMessage && (
              <div>
                <button
                  disabled={!valid || submitting}
                  className="btn btn-primary w-100 my-3 py-2"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    )) || <LoginLockOut setShowLockout={setShowLockout} />
  );
};
const RequestResetPasswordForm = reduxForm({ form: requestResetPasswordForm })(
  RequestResetPassword
);
RequestResetPassword.propTypes = FormPropTypes;

const mapStateToProps = (state) => ({
  successMessage: state.auth.resetFormSuccessMessage,
  lockedOut: state.app.isLockedOut,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(() => {
      return new AuthService(new API())
        .resetPassword({
          ...values,
          ...qs.parse(window.location.search),
        })
        .then(() => {
          dispatch(
            setResetFormSuccessMessage(
              "Your password has been successfully updated."
            )
          );
        })
        .catch((error) => {
          const asyncErrors = {};
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const { errors } = error.response.data;
            if (errors.password) {
              [asyncErrors.password] = errors.password;
            }
          }
          const generalError = {
            _error: "Unable to complete this request",
          };
          throw new SubmissionError({ ...generalError, ...asyncErrors });
        });
    }),
  setShowLockout: (val) => dispatch(setLoginLockedOut(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResetPasswordForm);
