import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import { adminUserPropType } from "../../Helpers/UserModel";
import { CheckboxInput, Button } from "@jauntin/react-ui";

const ModalRemove = ({ show, handleRemove, handleClose, user }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const onClose = () => {
    handleClose();
    setIsConfirmed(false);
  };

  const onRemove = () => {
    handleRemove(user);
    setIsConfirmed(false);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header className="py-3 border-bottom-0" closeButton />
      <Modal.Body className="pt-4 text-center">
        <p>This action will remove this admin.</p>
        <p>Is this what you wish to do?</p>
      </Modal.Body>
      <Modal.Footer className="border-top-0 flex-column pb-4">
        <CheckboxInput
          input={{
            name: "confirmRemove",
            value: isConfirmed,
            onChange: () => setIsConfirmed(!isConfirmed),
          }}
          meta={{ touched: false, error: false }}
          labelClassName="custom-control-label custom-control-label--sm pl-2 mb-4"
          label="Yes, I wish to remove this admin."
        />
        <Form.Group className="my-3">
          <Button
            className="btn-outline-secondary text-capitalize mr-2"
            text="Keep Admin"
            onClick={onClose}
          />
          <Button
            disabled={!isConfirmed}
            className="btn-primary text-capitalize ml-2"
            onClick={onRemove}
            text="Remove Admin"
          />
        </Form.Group>
      </Modal.Footer>
    </Modal>
  );
};

ModalRemove.propTypes = {
  show: PropTypes.bool,
  user: adminUserPropType,
  handleRemove: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ModalRemove.defaultProps = {
  show: false,
  user: null,
};

export default ModalRemove;
