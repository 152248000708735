import React from "react";

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const stateA = a.name.toUpperCase();
  const stateB = b.name.toUpperCase();

  let comparison = 0;
  if (stateA > stateB) {
    comparison = 1;
  } else if (stateA < stateB) {
    comparison = -1;
  }
  return comparison;
}

export const createStateOptions = (usStates) => {
  usStates.sort(compare);
  const stateOptions = usStates.map((usState) => (
    <option key={usState.name} value={usState.code}>
      {usState.name}
    </option>
  ));
  stateOptions.unshift(
    <option value="" key="default" disabled>
      Select
    </option>
  );
  return stateOptions;
};

export const contiguousStates = (usStates) => {
  return usStates.reduce(function filterStates(prev, curr) {
    if (["HI", "PR", "VI"].indexOf(curr.code) === -1) {
      prev.push({
        code: curr.code,
        name: curr.name,
      });
    }
    return prev;
  }, []);
};
