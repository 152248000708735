import React from "react";
import { connect } from "react-redux";
import SearchPoliciesContainer from "../SearchPoliciesContainer/SearchPoliciesContainer";
import PoliciesMenu from "./PoliciesMenu";
import PolicyContainer from "../Policy/PolicyContainer";
import ContactsContainer from "../Contacts/ContactsContainer";
import NotesContainer from "../Notes/NotesContainer";
import LoadPolicyContainer from "../LoadPolicy/LoadPolicyContainer";
import { coveragePropType, emptyCoverage } from "../../Helpers/CoverageModel";
import { Switch, Route } from "react-router-dom";
import {
  getUrl,
  POLICIES_PAGE,
  POLICY_PAGE,
  POLICY_CONTACT_PAGE,
  POLICIES_NOTES_PAGE,
} from "../../Helpers/URLParser";

const Policies = ({ coverage }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(POLICIES_PAGE)}
          component={SearchPoliciesContainer}
        />
        <Route
          path={getUrl(POLICIES_PAGE)}
          render={() => (
            <LoadPolicyContainer>
              {/* LoadPolicyContainer will load the current policy when it is mounted */}
              <div className="subsection__wrapper">
                <PoliciesMenu
                  id={coverage.id || 0}
                  policyHasUnacknowledgedChanges={
                    coverage.policyHasUnacknowledgedChanges
                  }
                />
                <div className="subsection__container">
                  <div>
                    <Switch>
                      <Route
                        path={getUrl(POLICY_PAGE)}
                        component={PolicyContainer}
                      />
                      <Route
                        path={getUrl(POLICY_CONTACT_PAGE)}
                        component={ContactsContainer}
                      />
                      <Route
                        path={getUrl(POLICIES_NOTES_PAGE)}
                        component={NotesContainer}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </LoadPolicyContainer>
          )}
        />
      </Switch>
    </>
  );
};

Policies.propTypes = {
  coverage: coveragePropType,
};

Policies.defaultProps = {
  coverage: emptyCoverage,
};

const mapStateToProps = (state) => ({
  coverage: state.policies.currentCoverage,
});

const PoliciesContainer = connect(mapStateToProps, {})(Policies);

export default PoliciesContainer;
