import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const PopoverTrigger = ({ trigger, placement, styleClass, content }) => (
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    overlay={<Popover>{content}</Popover>}
    rootClose
    delay="200"
  >
    <Button
      className={`btn-link ${styleClass}`}
      text={<i className="fal fa-info-circle" />}
    />
  </OverlayTrigger>
);

PopoverTrigger.propTypes = {
  trigger: PropTypes.any,
  placement: PropTypes.any,
  styleClass: PropTypes.any,
  content: PropTypes.any,
};
PopoverTrigger.defaultProps = {
  trigger: "click",
  placement: "auto",
  styleClass: "text-dark p-1 d-inline",
  content: "",
};

export default PopoverTrigger;
