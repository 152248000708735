import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFacilityId } from "../../Helpers/URLParser";
import { getFacility, clearFacilityData } from "../../Actions/actions";
import { emptyFacility, facilityPropType } from "../../Helpers/FacilityModel";
import {
  ExclusiveAccessClaimContext,
  ExclusiveAccessClaimProvider,
} from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";
import ExclusiveAccessModal from "./ExclusiveAccessModal";

const Content = ({ children, showClaimModal, setShowClaimModal }) => {
  const { claimOwner } = useContext(ExclusiveAccessClaimContext);
  return (
    <>
      <ExclusiveAccessModal
        show={showClaimModal}
        handleClose={() => setShowClaimModal(false)}
        claimOwner={claimOwner?.name}
      />
      {children}
    </>
  );
};

const LoadFacility = ({
  children,
  id,
  facility,
  loadFacility,
  clearFacility,
}) => {
  const [showClaimModal, setShowClaimModal] = useState(false);
  const showModalTimeout = useRef(null);
  useEffect(() => {
    loadFacility(id);
    return () => {
      clearFacility();
    };
  }, [loadFacility, clearFacility, id]);
  return facility.id === emptyFacility.id ? (
    <></>
  ) : (
    <ExclusiveAccessClaimProvider
      accessType="editFacility"
      itemId={id.toString()}
      failedCheck={() => {
        setShowClaimModal(true);
        clearTimeout(showModalTimeout.current);
        showModalTimeout.current = setTimeout(
          () => setShowClaimModal(false),
          5000
        );
      }}
    >
      <Content
        children={children}
        showClaimModal={showClaimModal}
        setShowClaimModal={setShowClaimModal}
      />
    </ExclusiveAccessClaimProvider>
  );
};

LoadFacility.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  facility: facilityPropType.isRequired,
  loadFacility: PropTypes.func.isRequired,
  clearFacility: PropTypes.func.isRequired,
};

LoadFacility.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  id: getFacilityId(window.location.pathname),
  facility: state.facilities.currentFacility,
});

const mapDispatchToProps = (dispatch) => ({
  loadFacility: (id) => dispatch(getFacility(id)),
  clearFacility: () => dispatch(clearFacilityData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadFacility);
