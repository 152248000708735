import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import PolicyBreadcrumb from "./PolicyBreadcrumb";

const PolicyDetailsHeader = ({ location, coverage, timezone }) => {
  return (
    <div className="subsection-menu__header">
      <PolicyBreadcrumb
        location={location}
        policyNumber={coverage.policyNumberGll}
      />
      <h3>
        {stringHelpers.renterName(coverage)}
        {`'s`}
        {` ${coverage.eventTypeName}`}
      </h3>
      <div>
        {coverage.status === "active" && (
          <span className="subsection-menu__badge subsection-menu__badge--bound">
            Bound
          </span>
        )}
        {coverage.status === "cancelled" && (
          <span className="subsection-menu__badge subsection-menu__badge--cancelled">
            Canceled
          </span>
        )}
        {coverage.status === "expired" && (
          <span className="subsection-menu__badge subsection-menu__badge--expired">
            Expired
          </span>
        )}
        <span className="subsection-menu__date ml-2">
          {`Created on ${dateHelpers.lettersAndNumbers(
            coverage.created,
            timezone
          )}`}
        </span>
      </div>
    </div>
  );
};

PolicyDetailsHeader.propTypes = {
  location: PropTypes.string.isRequired,
  coverage: coveragePropType.isRequired,
};

const mapStateToProps = (state) => ({
  location: state.router.location.pathname,
  coverage: { ...state.policies.currentCoverage },
  timezone: state.app.timezone,
});

const PolicyDetails = connect(mapStateToProps)(PolicyDetailsHeader);

export default PolicyDetails;
