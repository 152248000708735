import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../Helpers/ReduxFormPropTypes";

const GooglePlaceIDDesc = ({ input, meta }) => (
  <>
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Google Place ID Description
      <span className="small font-italic mx-2">(optional)</span>
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}

    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg mb-3"
      aria-label="GooglePlaceIDDescription"
      type="text"
    />
  </>
);
GooglePlaceIDDesc.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default GooglePlaceIDDesc;
