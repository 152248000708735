import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

/**
 *
 * Model used to represent a `Venue`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyVenue = {
  id: 0,
  facilityId: 0,
  addressId: 0,
  address: {
    id: 0,
    contactType: "",
    placeId: "",
    contactFirstName: "",
    contactLastName: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
  },
  otherInsured: {
    id: 0,
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  },
  status: "",
  blockedAt: "",
  glLimits: [],
  created: "",
  updated: "",
  facilityCode: null,
  venueCode: null,
  contacts: [],
  damageToRentedProperty: false,
  liquorLiability: false,
  hideHostLiquor: false,
  referralLink: "",
  notes: "",
  venueNotes: [],
};

/**
 *
 * PROP TYPES
 *
 */
const addressPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  contactType: PropTypes.string.isRequired,
  placeId: PropTypes.string.isRequired,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const contactPropType = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
});
const otherInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
});
const venueNotePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
});
const eventPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
});
const venuePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  facilityId: PropTypes.number.isRequired,
  addressId: PropTypes.number.isRequired,
  address: addressPropType,
  status: PropTypes.string.isRequired,
  blockedAt: PropTypes.string,
  glLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  facilityCode: PropTypes.string,
  venueCode: PropTypes.string,
  contacts: PropTypes.arrayOf(contactPropType),
  otherInsured: otherInsuredPropType,
  damageToRentedProperty: PropTypes.bool.isRequired,
  liquorLiability: PropTypes.bool.isRequired,
  hideHostLiquor: PropTypes.bool.isRequired,
  notes: PropTypes.string.isRequired,
  venueNotes: PropTypes.arrayOf(venueNotePropType),
  eventTypes: PropTypes.arrayOf(eventPropType),
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerVenueToVenueModel = (venue) => {
  const model = cloneDeep(emptyVenue);
  model.id = venue.id;
  model.facilityId = venue.facilityId;
  model.addressId = venue.addressId;

  const venueAddress = venue.address;
  if (venueAddress) {
    model.address = {
      id: venueAddress.id,
      contactType: venueAddress.contactType,
      placeId: venueAddress.placeId || "",
      contactFirstName: venueAddress.contactFirstName || "",
      contactLastName: venueAddress.contactLastName || "",
      companyName: venueAddress.companyName || "",
      address1: venueAddress.address1,
      address2: venueAddress.address2 || "",
      city: venueAddress.city,
      state: venueAddress.state,
      country: venueAddress.country || "US",
      zip: venueAddress.zip,
    };
  }

  model.damageToRentedProperty = venue.additional_coverages.some(
    (m) => m.additionalCoverageId === 7 && m.mandatory === 1
  );
  model.hideHostLiquor = venue.hide_host_liquor;
  model.liquorLiability = venue.additional_coverages.some(
    (m) => m.additionalCoverageId === 4 && m.mandatory === 1
  );

  model.status = venue.status;
  model.blockedAt = venue.blockedAt || "";
  model.notes = venue.notes || "";
  model.eventTileShowCount = venue.eventTileShowCount;
  model.glLimits = venue.gl_limits || [];
  model.created = venue.created_at;
  model.updated = venue.updated_at;
  model.facilityCode = venue.facility.code;
  model.venueCode = venue.venueCode;
  model.places = venue.places;

  const additionalInsured = venue.additional_insured_address;
  if (additionalInsured) {
    model.otherInsured = {
      id: additionalInsured.id,
      companyName: additionalInsured.companyName,
      address1: additionalInsured.address1 || "",
      address2: additionalInsured.address2,
      city: additionalInsured.city || "",
      state: additionalInsured.state || "",
      zip: additionalInsured.zip || "",
      country: additionalInsured.country || "US",
    };
  }

  model.contacts = venue.venue_contacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    role: m.role || "",
    email: m.email,
    copyOnEmails: m.copyOnEmails,
  }));

  model.referralLink = (
    venue.links || { venueReferralLink: "" }
  ).venueReferralLink;

  model.venueNotes = (venue.venue_notes || [])
    .map((m) => ({
      id: m.id,
      message: m.message,
      type: m.type,
      created: m.created_at,
      createdBy: (m.user || { name: "" }).name,
    }))
    .sort((a, b) => (a.id > b.id ? -1 : 1));

  model.eventTypes = venue.eventTypes;

  return model;
};

const editVenueInitialFormValues = (
  venue,
  address,
  googlePlaces,
  gl,
  otherInsured
) => ({
  status: venue.status,
  blocked: venue.blockedAt,
  notes: venue.notes,
  eventTileShowCount: venue.eventTileShowCount,
  venueAddress: {
    companyName: address.companyName,
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: address.country || "US",
  },
  places: googlePlaces,
  glLimits: gl,
  otherInsured: {
    companyName: otherInsured.companyName,
    address1: otherInsured.address1,
    address2: otherInsured.address2,
    city: otherInsured.city,
    state: otherInsured.state,
    zip: otherInsured.zip,
    country: otherInsured.country || "US",
  },
  damageToRentedProperty: venue.damageToRentedProperty,
  liquorLiability: venue.liquorLiability,
  hideHostLiquor: venue.hideHostLiquor,
  contacts: venue.contacts,
});

const initialVenueFormValues = () => cloneDeep(emptyVenue);

export {
  venuePropType,
  emptyVenue,
  otherInsuredPropType,
  convertServerVenueToVenueModel,
  editVenueInitialFormValues,
  initialVenueFormValues,
};
