import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Field,
  FieldArray,
  getFormValues,
  reduxForm,
  reset,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import isEmpty from "lodash/isEmpty";
import {
  ACTIVE,
  alertNew,
  venueFormName,
  modalUpdateServerErrorMessage,
  addBlockedVenueForm,
  alertBlocked,
  statePropType,
} from "../../constants";
import ModalConfirmUpdate from "./ModalConfirmUpdate";
import {
  facilityCodeValidation,
  facilityOtherAdditionalInsuredZipCode,
  venueCodeValidation,
} from "../../Helpers/validators";
import { getUrl, BLOCKED_VENUES_PAGE } from "../../Helpers/URLParser";
import {
  setVenueStatusMessage,
  errorResponse,
  showVenueStatusAlert,
} from "../../Actions/actions";
import VenueService from "../../Helpers/VenueService";
import CoverageService from "../../Helpers/CoverageService";
import API from "../../Helpers/API";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import PlacesFields from "./FormElements/PlacesFields";
import NotesField from "../Notes/NotesField";
import VenueCodeField from "../Policy/FormElements/VenueCodeField";
import {
  normalizeFacilityCode,
  normalizeVenueCode,
  normalizeNoteMaxCharacters,
  normalizeZip,
  normalizeVenueName,
} from "../../normalizer";
import StateField from "../Venue/FormElements/StateField";
import CountryField from "../Venue/FormElements/CountryField";
import { TextInput, Button } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const formValues = formValueSelector(addBlockedVenueForm);
const allFormValues = getFormValues(addBlockedVenueForm);

const SectionHeader = ({ text }) => (
  <div className="card-header bg-transparent">
    <strong>{text}</strong>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const RadioBtn = ({ text, name, checked, action }) => (
  <div
    className="btn-group-toggle col-sm-12 col-md-4 my-4"
    data-toggle="button"
  >
    <label
      className={`btn btn-outline-tertiary btn--radio ${
        checked ? "active" : ""
      }`}
      htmlFor={name}
    >
      <Field
        name={name}
        id={name}
        component="input"
        type="radio"
        onClick={action}
      />
      <span className="w-100 text-center">{text}</span>
    </label>
  </div>
);
RadioBtn.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
};

const AddBlockedVenue = ({
  pristine,
  valid,
  states,
  goToSearchPage,
  addVenue,
  updateVenue,
  clearForm,
  textCounter,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);

  const [hasVenueCode, setHasVenueCode] = useState(false);
  const [venueCodeErrorMessage, setVenueCodeErrorMessage] = useState("");

  const emptyVenue = {
    id: "",
    blockedAt: "",
    address: {
      companyName: "",
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    venueCode: "",
    facilityCode: "",
  };

  const [validVenue, setValidVenue] = useState(emptyVenue);
  const [hasValidVenue, setHasValidVenue] = useState(false);
  const hasVenueCodeFormError = validVenue.id !== "" && !hasValidVenue;
  const [
    showModalConfirmBlockVenueUpdate,
    setShowModalConfirmBlockVenueUpdate,
  ] = useState(false);
  const handleCloseConfirm = () => setShowModalConfirmBlockVenueUpdate(false);
  const handleShowConfirm = () => setShowModalConfirmBlockVenueUpdate(true);

  const validateVenueCode = async (facilityCode, venueCode) => {
    const notFoundErrorMessage =
      "The venue location for that code could not be found.";
    const blockedErrorMessage = "This venue is currently blocked.";
    if (
      facilityCode !== undefined &&
      facilityCode.length > 2 &&
      venueCode !== undefined &&
      venueCode.length >= 3 &&
      venueCode.length <= 5
    ) {
      try {
        const coverageService = new CoverageService(new API());
        const response = await coverageService.getPresetsByVenueCode(
          facilityCode,
          venueCode
        );
        const { venue } = response.data;

        if (venue && !venue.blockedAt) {
          setValidVenue({
            id: venue.id,
            blockedAt: venue.blockedAt,
            address: {
              companyName: venue.companyName,
              address1: venue.address1,
              city: venue.city,
              state: venue.state,
              country: venue.country,
              zip: venue.zip,
            },
            facilityCode,
            venueCode,
          });
          setHasValidVenue(true);
        } else if (!venue) {
          setVenueCodeErrorMessage(notFoundErrorMessage);
          setHasValidVenue(false);
        } else {
          setVenueCodeErrorMessage(blockedErrorMessage);
          setHasValidVenue(false);
        }
      } catch (err) {
        setVenueCodeErrorMessage(notFoundErrorMessage);
        setHasValidVenue(false);
      }
    } else {
      setVenueCodeErrorMessage(notFoundErrorMessage);
      setHasValidVenue(false);
    }
  };

  const clearVenueCodeForm = () => {
    if (!isEmpty(validVenue)) {
      setValidVenue(emptyVenue);
      setHasValidVenue(false);
      clearForm();
    }
  };

  const getVenueName = (venue) => {
    let venueName = "";
    const venueAddress = venue.address;
    if (venue.address.companyName) {
      venueName = venue.address.companyName;
    } else if (venueAddress && venueAddress.address1) {
      venueName = `${venueAddress.address1}, ${venueAddress.city}, ${venueAddress.state}, ${venueAddress.city}, ${venueAddress.zip}, ${venueAddress.country}`;
    } else {
      venueName = "This venue";
    }

    return venueName;
  };

  return (
    <div className="scroll-part">
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">Add Blocked Venue</h3>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />

            <Button
              text="Save Blocked Venue"
              className="btn-primary px-4 mx-2 text-nowrap"
              onClick={() => handleShowConfirm()}
              disabled={pristine || !valid || (hasVenueCode && !hasValidVenue)}
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card w-100">
          <SectionHeader text="Venue" />
          <div className="card-body">
            <div className="form-group form-row mw--635">
              <RadioBtn
                name="noVenueCode"
                text="I do not have a venue code"
                checked={!hasVenueCode}
                action={() => {
                  setHasVenueCode(false);
                  clearVenueCodeForm();
                }}
              />
              <RadioBtn
                name="hasVenueCode"
                text="I have a venue code"
                checked={hasVenueCode}
                action={() => {
                  setHasVenueCode(true);
                  clearForm();
                }}
              />
            </div>

            {hasVenueCode ? (
              <>
                <div className="d-flex align-items-center">
                  <span
                    className={
                      hasVenueCodeFormError
                        ? "label form-error__label my-auto"
                        : "label my-auto"
                    }
                  >
                    Venue code
                  </span>
                </div>
                {hasVenueCodeFormError && (
                  <p className="form-error">{venueCodeErrorMessage}</p>
                )}
                <div className="d-flex align-items-center form-group mt-3">
                  <Field
                    component={VenueCodeField}
                    className="form-control form-control-lg form-control--mw-125 text-center"
                    errorClassName="venue-code-form-error"
                    hasError={hasVenueCodeFormError}
                    validate={[required, facilityCodeValidation]}
                    name="facilityCode"
                    type="text"
                    normalize={normalizeFacilityCode}
                    maxLength="4"
                    onChange={(e) => {
                      setHasValidVenue(false);
                      setValidVenue({
                        facilityCode: e.target.value,
                        venueCode: validVenue.venueCode,
                      });
                      validateVenueCode(e.target.value, validVenue.venueCode);
                    }}
                  />
                  <span className="col-auto text-center">&mdash;</span>
                  <Field
                    component={VenueCodeField}
                    className="form-control form-control-lg form-control--mw-125 text-center"
                    errorClassName="venue-code-form-error"
                    hasError={hasVenueCodeFormError}
                    validate={[required, venueCodeValidation]}
                    name="venueCode"
                    type="text"
                    normalize={normalizeVenueCode}
                    maxLength="5"
                    onChange={(e) => {
                      setHasValidVenue(false);
                      setValidVenue({
                        venueCode: e.target.value,
                        facilityCode: validVenue.facilityCode,
                      });
                      validateVenueCode(
                        validVenue.facilityCode,
                        e.target.value
                      );
                    }}
                  />
                </div>

                {hasValidVenue && (
                  <>
                    <div>
                      <i className="fal fa-check-circle text-primary" />
                      &nbsp;
                      <span className="font-italic">
                        {getVenueName(validVenue)} has been found.
                      </span>
                    </div>
                  </>
                )}
                <br />
              </>
            ) : (
              <>
                <div className="card w-100">
                  <SectionHeader text="Venue Information" />
                  <div className="card-body mw--635">
                    <Field
                      component={TextInput}
                      normalize={normalizeVenueName}
                      label="Venue name"
                      subtext="(optional)"
                      name="venueAddress.companyName"
                      inputClassName="form-control-lg mb-4"
                      subtextClassName="label__subtext--inline"
                    />
                    <Field
                      component={TextInput}
                      validate={required}
                      name="venueAddress.address1"
                      label="Street address"
                      ariaLabel="Street address"
                      inputClassName="form-control-lg mb-2"
                    />
                    <div className="form-row">
                      <Field
                        component={TextInput}
                        validate={required}
                        name="venueAddress.city"
                        label="City"
                        ariaLabel="City"
                        inputClassName="form-control-lg"
                        wrapperClassName="col-sm"
                      />
                      <Field
                        name="venueAddress.state"
                        component={StateField}
                        type="select"
                        states={states}
                        validate={required}
                      />
                      <Field
                        component={TextInput}
                        validate={[
                          required,
                          facilityOtherAdditionalInsuredZipCode,
                        ]}
                        normalize={normalizeZip}
                        name="venueAddress.zip"
                        label="Zipcode"
                        ariaLabel="Zipcode"
                        inputClassName="form-control-lg"
                        wrapperClassName="col-sm"
                      />
                      <div hidden>
                        <Field
                          name="venueAddress.country"
                          component={CountryField}
                          type="select"
                          input={{ disabled: true }}
                          hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <FieldArray name="places" component={PlacesFields} />
                <br />
              </>
            )}
            <div className="mb-2">
              <strong>Why is this venue being blocked?</strong>
              <span className="small font-italic mx-2">(optional)</span>
            </div>
            <Field
              component={NotesField}
              type="textarea"
              name="notes"
              normalize={normalizeNoteMaxCharacters}
            />
            <div className="text-right policy--charCount">
              {textCounter.length}
              /200 characters
            </div>
          </div>
        </div>
      </div>

      <ModalConfirmUpdate
        show={showModalConfirmBlockVenueUpdate}
        action={() =>
          hasVenueCode
            ? updateVenue(validVenue, goToSearchPage, handleShowError)
            : addVenue(goToSearchPage, handleShowError)
        }
        text="Blocking this venue will ensure that the user is not able to purchase an insurance policy at this venue. Are you sure you wish to continue blocking this venue?"
        handleClose={handleCloseConfirm}
        goToPage={goToSearchPage}
      />

      <ModalDiscardAddNew
        show={showModal}
        category={venueFormName}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </div>
  );
};

AddBlockedVenue.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addVenue: PropTypes.func.isRequired,
  updateVenue: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  textCounter: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const hasVenueContacts =
    state.form.addVenue.values && state.form.addVenue.values.contacts;
  return {
    facilityId: state.facilities.currentFacility.id,
    states: state.app.states,
    contacts: hasVenueContacts ? state.form.addVenue.values.contacts : [],
    textCounter: formValues(state, "notes") || "",
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () => {
    dispatch(reset(addBlockedVenueForm));
    dispatch(ConnectedRouter.push(getUrl(BLOCKED_VENUES_PAGE)));
  },
  clearForm: () => {
    dispatch(reset(addBlockedVenueForm));
  },
  updateVenue: (validVenue, goToSearchPage, handleShowError) => {
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const blockedAt = new Date().toISOString();
      const notes = values ? values.notes : "";
      return new VenueService(new API())
        .patchVenueBlockVenue(validVenue, blockedAt, notes)
        .then((response) => {
          if (response.status === 204) {
            dispatch(showVenueStatusAlert(true));
            dispatch(setVenueStatusMessage(alertBlocked(validVenue)));
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    });
  },
  addVenue: (goToSearchPage, handleShowError) => {
    dispatch((_, getState) => {
      const values = allFormValues(getState());

      const data = {
        status: ACTIVE, // TODO: temporary, anticipate status selector in future
        address: values.venueAddress,
        otherInsured: values.additionalInsured,
        notes: values.notes,
        hostLiquor: true,
        damageToRentedProperty: false,
        liquorLiability: false,
      };

      const places = [];
      if (values.places) {
        values.places.map((place) => {
          return places.push({
            description: place.description,
            placeId: place.placeId.value,
          });
        });
        data.places = places;
      }

      data.glLimits = [1 * 10 ** 6, 2 * 10 ** 6];
      data.blockedAt = new Date().toISOString();

      data.blockedAt = new Date().toISOString();

      return new VenueService(new API())
        .postAddNewBlockedVenueDetails(data)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showVenueStatusAlert(true));
            dispatch(
              setVenueStatusMessage(alertNew(values.venueAddress.companyName))
            );
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  addVenue: (handleShowError) =>
    dispatchProps.addVenue(dispatchProps.goToSearchPage, handleShowError),
  updateVenue: (validVenue, handleShowError) =>
    dispatchProps.updateVenue(
      validVenue,
      dispatchProps.goToSearchPage,
      handleShowError
    ),
});

const AddNewBlockedVenue = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addBlockedVenueForm,
    initialValues: {
      venueAddress: {
        companyName: "",
        address1: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      places: [
        {
          description: "",
          placeId: "",
        },
      ],
      additionalInsured: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
    },
  })
)(AddBlockedVenue);

export default AddNewBlockedVenue;
