import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

const DaysOfWeekField = ({ name, validate }) => (
  <div className="form-group weekly-recurring-picker">
    <div className="custom-control custom-control-inline">
      <div className="mr-2" aria-label="day-picker">
        <label>
          <Field
            name={`${name}[0]`}
            className="weekly-checkbox"
            value="0"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="first-day">S</p>
        </label>
        <label>
          <Field
            name={`${name}[1]`}
            id="monday-weekly-checkbox"
            className="weekly-checkbox"
            value="1"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="day-of-week">M</p>
        </label>
        <label>
          <Field
            name={`${name}[2]`}
            id="tuesday-weekly-checkbox"
            className="weekly-checkbox"
            value="2"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="day-of-week">T</p>
        </label>
        <label>
          <Field
            name={`${name}[3]`}
            id="wednesday-weekly-checkbox"
            className="weekly-checkbox"
            value="3"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="day-of-week">W</p>
        </label>
        <label>
          <Field
            name={`${name}[4]`}
            id="thursday-weekly-checkbox"
            className="weekly-checkbox"
            value="4"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="day-of-week">T</p>
        </label>
        <label>
          <Field
            name={`${name}[5]`}
            id="friday-weekly-checkbox"
            className="weekly-checkbox"
            value="5"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="day-of-week">F</p>
        </label>
        <label>
          <Field
            name={`${name}[6]`}
            id="saturday-weekly-checkbox"
            className="weekly-checkbox"
            value="6"
            validate={validate}
            component="input"
            type="checkbox"
          />
          <p className="last-day">S</p>
        </label>
      </div>
    </div>
  </div>
);
DaysOfWeekField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired,
};

export default DaysOfWeekField;
