import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

/**
 *
 * Model used to represent a `ProducerContact`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyProducerContact = {
  id: 0,
  email: "",
  fullName: "",
  created: "",
  updated: "",
  producerId: "",
  producerName: "",
  facilityAddresses: [],
};

/**
 *
 * PROP TYPES
 *
 */
const addressPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const producerContactPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  producerId: PropTypes.string.isRequired,
  producerName: PropTypes.string.isRequired,
  facilityAddresses: PropTypes.arrayOf(addressPropType).isRequired,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerProducerContactToContactModel = (contact) => {
  const model = cloneDeep(emptyProducerContact);
  model.id = contact.id;
  model.fullName = contact.fullName || "";
  model.email = contact.email;
  model.created = contact.created_at;
  model.updated = contact.updated_at;
  model.producerId = contact.producerId;
  model.producerName = contact.producer.name || "";

  const facilityAddressDetails = contact.facilities;
  if (facilityAddressDetails && facilityAddressDetails.length > 0) {
    model.facilityAddress = [];
    facilityAddressDetails.forEach((facility) => {
      model.facilityAddress.push({
        id: facility.id,
        companyName: facility.additional_insured_address.companyName || "",
        address1: facility.additional_insured_address.address1,
        address2: facility.additional_insured_address.address2 || "",
        city: facility.additional_insured_address.city,
        state: facility.additional_insured_address.state,
        country: facility.additional_insured_address.country || "US",
        zip: facility.additional_insured_address.zip,
      });
    });
  }

  return model;
};

export { producerContactPropType, convertServerProducerContactToContactModel };
