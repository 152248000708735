import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const VenueCodeField = ({
  input,
  meta,
  className,
  maxLength,
  errorClassName,
  hasError,
  isPremiumChanged,
}) => (
  <input
    {...input}
    id={input.name}
    type="text"
    pattern="\w*"
    className={`${className} ${
      (hasError || (meta.dirty && isPremiumChanged)) && errorClassName
    }`}
    maxLength={maxLength}
  />
);
VenueCodeField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  className: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  errorClassName: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  isPremiumChanged: PropTypes.bool,
};

export default VenueCodeField;
