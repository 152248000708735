import React from "react";
import PropTypes from "prop-types";
import { FieldInputProps, FieldMetaProps } from "../Helpers/ReduxFormPropTypes";
import { getAttendanceRange } from "../Helpers/AttendenceRangeHelper";
import { IntegerNumberInput } from "@jauntin/react-ui";

const GuestsField = ({
  input,
  meta,
  disabled,
  eventTotalGuests,
  isPremiumChanged,
}) => (
  <div className="guestField">
    <div className="col-sm-12 p-0">
      <span
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        How many people are you expecting?
      </span>
      <div className="label__subtext d-block">
        Include all who will attend including hosts, guests, volunteers, and any
        performers, vendors or exhibitors.
      </div>
    </div>
    <div className="guestField__input">
      <IntegerNumberInput
        input={input}
        meta={meta}
        label="Average Daily Attendance?"
        className="mw--250"
        placeholder="Avg Daily Attendance"
        min={1}
        disabled={disabled}
        required
        showErrors={(meta) =>
          (meta.touched && meta.error) || (meta.dirty && isPremiumChanged)
        }
      />
    </div>
    <div className="row px-3 flex-column">
      <div className="col-auto p-0 guestField__select mb-3"></div>
      <div className="form-row">
        <div className="col-auto">
          <span className="font-weight-bold">Total Attendance</span>:{" "}
          <span className="ml-2 mr-3">{eventTotalGuests}</span>
          <span className="font-weight-bold">Attendance Range</span>:{" "}
          <span className="ml-2 mr-3">
            {getAttendanceRange(eventTotalGuests)}
          </span>
        </div>
      </div>
    </div>
  </div>
);

GuestsField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  disabled: PropTypes.bool.isRequired,
  eventTotalGuests: PropTypes.string.isRequired,
  isPremiumChanged: PropTypes.bool.isRequired,
};

export default GuestsField;
