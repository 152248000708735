import { SET_ALERT_KEYWORDS } from "../Actions/actions";

/**
 *
 * Reducers for alert keywords
 *
 */
const initialState = () => ({ data: { words: "" } });
const alertKeywordsReducers = (state = initialState(), action) => {
  if (action.type === SET_ALERT_KEYWORDS) {
    return { ...state, ...{ data: action.payload } };
  }
  return state;
};

export default alertKeywordsReducers;
