import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Col, Row } from "react-bootstrap";
import {
  coveragePropType,
  getAdditionalInsuredInfo,
} from "../../Helpers/CoverageModel";
import {
  additionalInsuredPropTypes,
  TYPE_TEXT,
  statePropType,
  NO_AI_FACILITY,
  NO_AI_VENUE,
  NO_AI_VENUE_AI,
  NO_AI_OTHER,
  FACILITY_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_AI_GROUP_NAME,
} from "../../constants";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import AdditionalInsuredField from "./FormElements/AdditionalInsuredField";
import StateField from "./FormElements/StateField";
import CountryField from "./FormElements/CountryField";
import {
  zipCode,
  facilityOtherAdditionalInsuredZipCode,
} from "../../Helpers/validators";
import { normalizeZip, normalizeNoteMaxCharacters } from "../../normalizer";
import hasMeaningfulAddressInfo from "../../Helpers/hasMeaningfulAddressInfo";
import { TextInput, Button, SelectList } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
import { getLgSelectStyle } from "../../Helpers/ReactSelectStyle";
import { useStateOptions } from "../../Hooks/useStateOptions";
const { required } = validators;

const generateKey = (i) => {
  return `${i}`;
};

const parseAddress = (address) =>
  stringHelpers.address(
    address.address1,
    address.address2,
    address.city,
    address.state,
    address.country,
    address.zip
  );

const formatAdditionalInsuredText = (obj) => {
  if (TYPE_TEXT in obj) {
    return obj.text.split("\n").map((line, i) => {
      return <div key={generateKey(i)}>{line}</div>;
    });
  }
  return "";
};

const AdditionalInsured = ({
  coverage,
  editing,
  states,
  editFacility,
  editVenue,
  editVenueAdditionalInsured,
  setEditingFacility,
  setEditingVenue,
  setEditingVenueAdditionalInsured,
  resetFacilityAddressForm,
  resetVenueAddressForm,
  resetVenueAddressAdditionalInsuredForm,
  textCounter,
  facilityAddress,
  venueAddress,
  venueAddressAdditionalInsured,
  clearAddressGroup,
}) => {
  const facilityAddressView = parseAddress(facilityAddress);
  const venueAddressView = parseAddress(venueAddress);
  const venueAddressAdditionalInsuredView = parseAddress(
    venueAddressAdditionalInsured
  );
  const additionalInsuredText = getAdditionalInsuredInfo(coverage, TYPE_TEXT);
  const stateOptions = useStateOptions(states);

  const addressView = (address, view, noInfo, textDanger = false) => {
    if (hasMeaningfulAddressInfo(address)) {
      return (
        <>
          <div>{address.companyName}</div>
          <div>{view}</div>
        </>
      );
    }
    if (address.companyName) {
      return <div>{address.companyName}</div>;
    }
    return (
      <div className={textDanger ? "text-danger" : "additionalInsured--noInfo"}>
        {textDanger && <i className="fal fa-exclamation-triangle mr-2" />}
        {noInfo}
      </div>
    );
  };

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Additional Insured</strong>
      </div>
      <div className="card-body">
        {(editing && (
          <>
            {/* Facility */}
            <div className="row">
              <div className="col-3 h6">Facility</div>
              <div className="col-9">
                {editFacility ? (
                  <>
                    <Field
                      component={TextInput}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.companyName`}
                      validate={[required]}
                      label="Facility Name"
                      ariaLabel="Facility Name"
                      placeholder="Facility Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextInput}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      wrapperClassName="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextInput}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.address2`}
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <div className="col-sm">
                        <Field
                          component={TextInput}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.city`}
                          type="text"
                          label="City"
                          ariaLabel="City"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div className="col-sm">
                        <label
                          className="label"
                          htmlFor={`${FACILITY_ADDRESS_GROUP_NAME}.state`}
                        >
                          State
                        </label>
                        <Field
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.state`}
                          component={SelectList}
                          customStyles={getLgSelectStyle()}
                          placeholder="State"
                          options={stateOptions}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          component={TextInput}
                          validate={[facilityOtherAdditionalInsuredZipCode]}
                          normalize={normalizeZip}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.zip`}
                          type="text"
                          label="Zipcode"
                          ariaLabel="Zip"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <Button
                        onClick={() => {
                          setEditingFacility(false);
                          resetFacilityAddressForm();
                        }}
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      facilityAddress,
                      facilityAddressView,
                      NO_AI_FACILITY
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(facilityAddress) ||
                        facilityAddress.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(FACILITY_ADDRESS_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          className="btn-link btn--edit"
                          onClick={() => setEditingFacility(true)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <br />

            {/* Venue */}
            <div className="row">
              <div className="col-3 h6">Venue</div>
              <div className="col-9">
                {editVenue ? (
                  <>
                    <Field
                      component={TextInput}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.companyName`}
                      label="Venue Name"
                      ariaLabel="Venue Name"
                      placeholder="Venue Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextInput}
                      validate={[required]}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      wrapperClassName="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextInput}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.address2`}
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <Field
                        component={TextInput}
                        validate={[required]}
                        name={`${VENUE_ADDRESS_GROUP_NAME}.city`}
                        label="City"
                        ariaLabel="City"
                        placeholder="City"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                      />
                      <Field
                        component={StateField}
                        validate={[required]}
                        name={`${VENUE_ADDRESS_GROUP_NAME}.state`}
                        type="select"
                        states={states}
                      />
                      <Field
                        component={TextInput}
                        validate={[zipCode, required]}
                        normalize={normalizeZip}
                        name={`${VENUE_ADDRESS_GROUP_NAME}.zip`}
                        label="Zip"
                        ariaLabel="Zip"
                        placeholder="Zip"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                      />
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${VENUE_ADDRESS_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row ml-left">
                      <Button
                        onClick={() => {
                          setEditingVenue(false);
                          resetVenueAddressForm();
                        }}
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      venueAddress,
                      venueAddressView,
                      NO_AI_VENUE,
                      true
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(venueAddress) ||
                        venueAddress.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(VENUE_ADDRESS_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          className="btn-link btn--edit"
                          onClick={() => setEditingVenue(true)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <br />

            {/* Venue Additional Insured */}
            <div className="row">
              <div className="col-3 h6">Venue Additional Insured</div>
              <div className="col-9">
                {editVenueAdditionalInsured ? (
                  <>
                    <Field
                      component={TextInput}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.companyName`}
                      label="Venue Additional Insured Name"
                      ariaLabel="Venue Additional Insured Name"
                      placeholder="Venue Additional Insured Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextInput}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      wrapperClassName="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextInput}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.address2`}
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <div className="col-sm">
                        <Field
                          component={TextInput}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.city`}
                          type="text"
                          label="City"
                          ariaLabel="City"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div className="col-sm">
                        <label
                          className="label"
                          htmlFor={`${VENUE_ADDRESS_AI_GROUP_NAME}.state`}
                        >
                          State
                        </label>
                        <Field
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.state`}
                          component={SelectList}
                          customStyles={getLgSelectStyle()}
                          placeholder="State"
                          options={stateOptions}
                          isClearable={true}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          component={TextInput}
                          validate={[facilityOtherAdditionalInsuredZipCode]}
                          normalize={normalizeZip}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.zip`}
                          type="text"
                          label="Zipcode"
                          ariaLabel="Zip"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row ml-auto">
                      <Button
                        onClick={() => {
                          setEditingVenueAdditionalInsured(false);
                          resetVenueAddressAdditionalInsuredForm();
                        }}
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      venueAddressAdditionalInsured,
                      venueAddressAdditionalInsuredView,
                      NO_AI_VENUE_AI
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(
                        venueAddressAdditionalInsured
                      ) ||
                        venueAddressAdditionalInsured.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(VENUE_ADDRESS_AI_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          className="btn-link btn--edit"
                          onClick={() => setEditingVenueAdditionalInsured(true)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <br />

            {/* Other Additional Insured */}
            <div className="row">
              <div className="col-3 h6">Other additional insured</div>
              <div className="col-9 mb-2">
                <Field
                  component={AdditionalInsuredField}
                  type="textarea"
                  name="additionalInsured"
                  normalize={normalizeNoteMaxCharacters}
                />
                <div className="text-right policy--charCount">
                  {textCounter.length}
                  /200 characters
                </div>
              </div>
            </div>
          </>
        )) || (
          <>
            <div>
              <strong>Facility</strong>
            </div>
            <div>
              {addressView(
                facilityAddress,
                facilityAddressView,
                NO_AI_FACILITY
              )}
            </div>
            <br />

            <div>
              <strong>Venue</strong>
            </div>
            <div>{venueAddress.companyName}</div>
            <div>{venueAddressView}</div>
            <br />

            <div>
              <strong>Venue Additional Insured</strong>
            </div>
            <div>
              {addressView(
                venueAddressAdditionalInsured,
                venueAddressAdditionalInsuredView,
                NO_AI_VENUE_AI
              )}
            </div>
            <br />

            <div>
              <strong>Other additional insured</strong>
            </div>
            <div>
              {additionalInsuredText && additionalInsuredText.text ? (
                formatAdditionalInsuredText(additionalInsuredText)
              ) : (
                <span className="additionalInsured--noInfo">{NO_AI_OTHER}</span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AdditionalInsured.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  editFacility: PropTypes.bool.isRequired,
  editVenue: PropTypes.bool.isRequired,
  editVenueAdditionalInsured: PropTypes.bool.isRequired,
  setEditingFacility: PropTypes.func.isRequired,
  setEditingVenue: PropTypes.func.isRequired,
  setEditingVenueAdditionalInsured: PropTypes.func.isRequired,
  resetFacilityAddressForm: PropTypes.func.isRequired,
  resetVenueAddressForm: PropTypes.func.isRequired,
  resetVenueAddressAdditionalInsuredForm: PropTypes.func.isRequired,
  textCounter: PropTypes.string.isRequired,
  facilityAddress: additionalInsuredPropTypes.isRequired,
  venueAddress: additionalInsuredPropTypes.isRequired,
  venueAddressAdditionalInsured: additionalInsuredPropTypes.isRequired,
  clearAddressGroup: PropTypes.func.isRequired,
};

export default AdditionalInsured;
