import React from "react";
import PropTypes from "prop-types";
import { CloseButton } from "@jauntin/react-ui";

const PolicyUpdate = ({ close }) => (
  <div className="contacts__policyUpdatedAlert d-flex justify-content-between align-items-center p-3 mb-5">
    <div className="d-flex">
      <div>
        <i className="fas fa-exclamation-triangle pt-2" />
      </div>
      <div className="px-3">
        The policy has been updated. Please make sure to send the latest version
        to the policyholder and any other stakeholders mentioned below.
      </div>
    </div>
    <div>
      <CloseButton onClick={close} className="btn btn-link btn--alertClose" />
    </div>
  </div>
);
PolicyUpdate.propTypes = {
  close: PropTypes.func.isRequired,
};
export default PolicyUpdate;
