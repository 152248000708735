import { NotesContainer } from "@jauntin/react-ui";
import { connect } from "react-redux";
import { getFacility } from "../../Actions/actions";
import API from "../../Helpers/API";
import FacilityService from "../../Helpers/FacilityService";

const mapStateToProps = (state) => ({
  entityId: state.facilities.currentFacility.id,
  notes: state.facilities.currentFacility.facilityNotes,
});

const mapDispatchToProps = (dispatch) => ({
  publish: (id, message) =>
    dispatch(() => {
      return new FacilityService(new API())
        .postAddNote({ id, message })
        .then(() => dispatch(getFacility(id)));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
