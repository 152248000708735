export const getAttendanceRange = (count) => {
  if (!count) {
    return "0";
  }

  let result;

  if (count < 101) {
    result = "1 to 100";
  } else if (count < 501) {
    result = "101 to 500";
  } else if (count < 1501) {
    result = "501 to 1500";
  } else if (count < 3001) {
    result = "1501 to 3000";
  } else if (count < 5001) {
    result = "3001 to 5000";
  } else {
    result = "5000+";
  }

  return result;
};
