import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const PopoverTrigger = ({
  trigger,
  placement,
  styleClass,
  content,
  action,
}) => (
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    overlay={<Popover className="popover-content">{content}</Popover>}
    rootClose
  >
    <Button
      className={`btn-link ${styleClass}`}
      onClick={action}
      text={<i className="fa fa-ellipsis-v subsection-menu--ellipsis" />}
    />
  </OverlayTrigger>
);

PopoverTrigger.propTypes = {
  trigger: PropTypes.any,
  placement: PropTypes.any,
  styleClass: PropTypes.any,
  content: PropTypes.any,
  action: PropTypes.any,
};
PopoverTrigger.defaultProps = {
  trigger: "click",
  placement: "auto",
  styleClass: "text-dark p-1 d-inline float-right",
  content: "",
  action: "",
};

export default PopoverTrigger;
