import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { FACILITY_CONTACT_TYPE } from "../../constants";
import { Button } from "@jauntin/react-ui";

const ModalRemove = ({
  show,
  action,
  handleClose,
  cancel,
  contactType,
  email,
  onShow,
}) => (
  <Modal show={show} onHide={handleClose} onShow={onShow} size="lg">
    <Modal.Header className="py-4" closeButton>
      <h3 className="mb-0">Remove contact{!email && "s"}</h3>
    </Modal.Header>
    <Modal.Body className="pt-4 pb-5">
      <p>
        {`Are you sure you wish to remove ${
          email ?? "the selected contacts"
        } from ${
          contactType === FACILITY_CONTACT_TYPE
            ? "facilities"
            : `${contactType}s`
        }?`}
        <br />
        This action can not be undone.
      </p>
    </Modal.Body>
    <Modal.Footer className="py-4">
      <Button
        className="btn-outline-secondary"
        text="Cancel"
        onClick={() => {
          cancel();
          handleClose();
        }}
      />
      <Button
        onClick={() => action()}
        text="Yes, remove contacts"
        className="btn-primary"
      />
    </Modal.Footer>
  </Modal>
);

ModalRemove.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  email: PropTypes.string,
  onShow: PropTypes.func.isRequired,
};
ModalRemove.defaultProps = {
  show: false,
  email: null,
};

export default ModalRemove;
