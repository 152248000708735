import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import PopoverTrigger from "../../../Components/Popover";

const GovernmentEntityField = ({
  name,
  validate,
  setCanUpdatePolicy,
  setIsPristine,
}) => (
  <div className="form-group">
    <div className="mb-1">
      Is the insured a government entity, resident education, or charitable
      institution qualifying under 501(c)(3)?
      <span className="ml-1">
        <PopoverTrigger content="Some qualifying organizations, like schools, may be exempt from certain charges." />
      </span>
    </div>

    <div className="custom-control custom-radio">
      <Field
        name={name}
        value="yes"
        id={`${name}Yes`}
        validate={validate}
        component="input"
        type="radio"
        className="custom-control-input"
        onChange={() => {
          setIsPristine(true);
          setCanUpdatePolicy(0);
        }}
      />
      <label className="custom-control-label label" htmlFor={`${name}Yes`}>
        Yes
      </label>
    </div>

    <div className="custom-control custom-radio">
      <Field
        name={name}
        value="no"
        id={`${name}No`}
        validate={validate}
        component="input"
        type="radio"
        className="custom-control-input"
        onChange={() => {
          setIsPristine(true);
          setCanUpdatePolicy(0);
        }}
      />
      <label className="custom-control-label label" htmlFor={`${name}No`}>
        No
      </label>
    </div>
  </div>
);
GovernmentEntityField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func).isRequired,
  setCanUpdatePolicy: PropTypes.func.isRequired,
  setIsPristine: PropTypes.func.isRequired,
};

export default GovernmentEntityField;
