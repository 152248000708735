import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const CommissionRate = ({ input, meta, options, disabled }) => (
  <>
    <label
      htmlFor={input.name}
      className={`
        ${meta.touched && meta.error ? "label form-error__label" : "label"}
      mb-2`}
    >
      Commission %
    </label>
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    <div>
      <select
        {...input}
        id={input.name}
        className="custom-select custom-select-lg w--100"
        aria-label="Commission"
        disabled={disabled}
      >
        {options.map((rate) => (
          <option key={rate} value={rate}>
            {rate}%
          </option>
        ))}
      </select>
    </div>
  </>
);
CommissionRate.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CommissionRate;
