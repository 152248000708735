import {
  PERMISSION_MANAGE_SETTINGS,
  PERMISSION_MANAGE_USERS,
  PERMISSION_VIEW_REPORTS,
} from "../constants";

export const getPolicyTotalEventAttendees = (state) => {
  const { values } = state.form.policy;

  if (values && values.eventDates && values.eventDailyGuests) {
    const { eventDates, eventDailyGuests } = values;

    return (eventDailyGuests * eventDates.length).toString();
  }

  return null;
};

export const canManageUsers = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_USERS);

export const canManageSettings = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_SETTINGS);

export const canViewReports = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_VIEW_REPORTS);
