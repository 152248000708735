import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

const JointVentureField = ({ name, validate }) => (
  <div className="col-sm-6 form-group mt-auto pb-2">
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        type="radio"
        id={`${name}Corp`}
        name={name}
        validate={validate}
        className="custom-control-input"
        component="input"
        value="Joint Venture Corporation"
      />
      <label className="custom-control-label label" htmlFor={`${name}Corp`}>
        Corporation
      </label>
    </div>
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        type="radio"
        id={`${name}LLC`}
        name={name}
        validate={validate}
        className="custom-control-input"
        component="input"
        value="Joint Venture LLC"
      />
      <label className="custom-control-label label" htmlFor={`${name}LLC`}>
        LLC
      </label>
    </div>
  </div>
);
JointVentureField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func).isRequired,
};

export default JointVentureField;
