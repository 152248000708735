import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  reduxForm,
  Field,
  getFormValues,
  formValueSelector,
  FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  ACTIVE,
  addProducerForm,
  alertNew,
  modalUpdateServerErrorMessage,
} from "../../constants";
import StatusField from "./FormElements/StatusField";
import {
  producerCommissionId,
  contactEmailsUnique,
} from "../../Helpers/validators";
import { normalizeProducerCommissionId } from "../../normalizer";
import ProducerCommissionId from "./FormElements/ProducerCommissionIdField";
import { getUrl, PRODUCERS_PAGE } from "../../Helpers/URLParser";
import ProducerService from "../../Helpers/ProducerService";
import API from "../../Helpers/API";
import {
  showNewProducerAlert,
  setNewProducerStatusMessage,
  checkIsValidCommissionId,
  errorResponse,
} from "../../Actions/actions";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import { TextInput, Button } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
import Debounce from "../../Helpers/Debounce";
const { required } = validators;

const formValues = formValueSelector(addProducerForm);
const allFormValues = getFormValues(addProducerForm);
const debouncer = new Debounce({ period: 500 });

const AddProducer = ({
  pristine,
  valid,
  goToSearchPage,
  addProducer,
  checkAndSetValidCommissionId,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validCommissionId, setValidCommissionId] = useState(true);
  const [hasCheckedCommissionId, setHasCheckedCommissionId] = useState(false);
  return (
    <div className="scroll-part">
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Producer</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Producer"
              className="btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addProducer(handleShowError)}
              disabled={
                pristine ||
                !valid ||
                !validCommissionId ||
                !hasCheckedCommissionId
              }
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card-body">
          <Field component={StatusField} name="producerStatus" type="select" />
        </div>

        <div className="card w-100">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Producer Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Field
              component={TextInput}
              validate={[required]}
              name="producerName"
              label="Producer Name"
              ariaLabel="Name"
              errorClassName="d-inline ml-2"
              inputClassName="form-control-lg col-lg-6 mb-4"
            />
            <Field
              component={ProducerCommissionId}
              validate={[required, producerCommissionId]}
              name="producerCommissionId"
              type="text"
              normalize={normalizeProducerCommissionId}
              hasCheckedCommissionId={hasCheckedCommissionId}
              validCommissionId={validCommissionId}
              maxLength="7"
              onChange={(e) => {
                if (e.target.value.length === 7) {
                  setHasCheckedCommissionId(false);
                  debouncer.do(
                    checkAndSetValidCommissionId,
                    e.target.value,
                    setHasCheckedCommissionId,
                    setValidCommissionId
                  );
                } else if (e.target.value.length < 7) {
                  setHasCheckedCommissionId(false);
                  setValidCommissionId(true);
                }
              }}
            />
          </div>
        </div>

        <FieldArray name="producerContacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </div>
  );
};

AddProducer.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addProducer: PropTypes.func.isRequired,
  checkAndSetValidCommissionId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valid: state.producers.valid,
  commissionId: formValues(state, "producerCommissionId") || "",
  producers: state.producers.producerTableData || [],
});

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () => dispatch(ConnectedRouter.push(getUrl(PRODUCERS_PAGE))),
  addProducer: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const data = {
        status: values.producerStatus,
        name: values.producerName,
        commissionId: values.producerCommissionId,
        contacts: values.producerContacts,
      };
      return new ProducerService(new API())
        .postAddNewProducerDetails(data)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showNewProducerAlert(true));
            dispatch(
              setNewProducerStatusMessage(alertNew(values.producerName))
            );
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  checkAndSetValidCommissionId: (
    id,
    setHasCheckedCommissionId,
    setValidCommissionId
  ) =>
    dispatch(
      checkIsValidCommissionId(
        id,
        setHasCheckedCommissionId,
        setValidCommissionId
      )
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addProducer: (handleShowError) =>
    dispatchProps.addProducer(dispatchProps.goToSearchPage, handleShowError),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addProducerForm,
    initialValues: {
      producerStatus: ACTIVE,
      producerName: "",
      producerCommissionId: "",
      producerContacts: [],
    },
    validate: (values) => ({
      ...contactEmailsUnique(values, "producerContacts"),
    }),
  })
)(AddProducer);
