import React from "react";
import { FieldInputProps } from "../../../Helpers/ReduxFormPropTypes";

const EventFrequencyField = ({ input }) => (
  <div className="form-group mw--400">
    <label htmlFor={input.name} className="label mr-2">
      How often does this event occur?
    </label>
    <select
      {...input}
      id={input.name}
      className="custom-select custom-select-lg mw--400"
    >
      <option value="continuous">One Time</option>
      <option value="weekly">Weekly</option>
      <option value="custom">Custom</option>
    </select>
  </div>
);
EventFrequencyField.propTypes = {
  input: FieldInputProps.isRequired,
};

export default EventFrequencyField;
