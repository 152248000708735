import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { ConnectedRouter, useTimeTravel } from "@jauntin/react-ui";
import { DefaultTimezoneProvider } from "@jauntin/react-ui";
import "./App.scss";
import AdminContainer from "./Containers/Admin/AdminContainer";
import LoginContainer from "./Containers/Login/LoginContainer";
import ScrollToTop from "./Helpers/ScrollToTop";
import {
  getUrl,
  ADMIN_PAGE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  TWO_FACTOR_PAGE,
} from "./Helpers/URLParser";
import RequestResetPasswordContainer from "./Containers/RequestResetPassword/RequestResetPasswordContainer";
import ResetPasswordContainer from "./Containers/ResetPassword/ResetPasswordContainer";
import {
  loginStatus,
  getAppInfo as getAppInfoAction,
  getSortableEventTypes,
} from "./Actions/actions";
import MaintenanceMode from "./Components/MaintenanceMode";
import { canManageSettings as canManageSettingsSelector } from "./Helpers/StoreSelectors";
import TwoFactorContainer from "./Containers/Login/TwoFactorContainer";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginStatus());
    dispatch(getAppInfoAction());
    dispatch(getSortableEventTypes());
  }, [dispatch]);

  const { isLoggedIn, isDataLoaded, timezone, maintenanceMode } = useSelector(
    ({ app }) => app
  );

  const canManageSettings = useSelector(canManageSettingsSelector);

  useTimeTravel(
    canManageSettings && process.env.REACT_APP_ENV !== "production"
  );

  const location = useLocation();
  const history = useHistory();
  const pushUrl = useSelector((state) => state.router.pushUrl);
  return (
    <>
      {maintenanceMode ? (
        <MaintenanceMode />
      ) : (
        <ConnectedRouter.Router
          history={history}
          location={location}
          pushUrl={pushUrl}
          dispatch={dispatch}
        >
          {isDataLoaded ? (
            <DefaultTimezoneProvider timezone={timezone}>
              <ScrollToTop>
                <Switch>
                  <Route
                    path={getUrl(ADMIN_PAGE)}
                    render={({ props }) => (
                      <AdminContainer {...props} isLoggedIn={isLoggedIn} />
                    )}
                  />
                  <Route
                    exact
                    path={getUrl(TWO_FACTOR_PAGE)}
                    render={({ props }) => <TwoFactorContainer {...props} />}
                  />
                  <Route
                    exact
                    path={getUrl(REQUEST_RESET_PASSWORD)}
                    render={({ props }) => (
                      <RequestResetPasswordContainer {...props} />
                    )}
                  />
                  <Route
                    exact
                    path={getUrl(RESET_PASSWORD)}
                    render={({ props }) => (
                      <ResetPasswordContainer {...props} />
                    )}
                  />
                  <Route component={LoginContainer} />
                </Switch>
              </ScrollToTop>
            </DefaultTimezoneProvider>
          ) : (
            <></>
          )}
        </ConnectedRouter.Router>
      )}
    </>
  );
};

export default App;
