import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { producerCommissionId } from "../../Helpers/validators";
import Debounce from "../../Helpers/Debounce";
import ProducerCommissionId from "./FormElements/ProducerCommissionIdField";
import { normalizeProducerCommissionId } from "../../normalizer";
import { producerPropType } from "../../Helpers/ProducerModel";
import { TextInput } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const debouncer = new Debounce({ period: 500 });

const ProducerInformation = ({
  editing,
  producer,
  validCommissionId,
  setValidCommissionId,
  hasCheckedCommissionId,
  setHasCheckedCommissionId,
  checkAndSetValidCommissionId,
}) => (
  <div className="card w-100">
    {editing ? (
      <>
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Producer Information</strong>
          </div>
        </div>
        <div className="card-body">
          <Field
            component={TextInput}
            validate={[required]}
            name="producerName"
            label="Producer Name"
            ariaLabel="Name"
            errorClassName="d-inline ml-2"
            inputClassName="form-control-lg col-lg-6 mb-4"
          />
          <Field
            component={ProducerCommissionId}
            validate={[required, producerCommissionId]}
            name="producerCommissionId"
            type="text"
            normalize={normalizeProducerCommissionId}
            maxLength="7"
            hasCheckedCommissionId={hasCheckedCommissionId}
            validCommissionId={validCommissionId}
            onChange={(e) => {
              if (e.target.value.length === 7) {
                if (e.target.value === producer.commissionId) {
                  setHasCheckedCommissionId(true);
                  setValidCommissionId(true);
                } else {
                  setHasCheckedCommissionId(false);
                  debouncer.do(
                    checkAndSetValidCommissionId,
                    e.target.value,
                    setHasCheckedCommissionId,
                    setValidCommissionId,
                    producer.commissionId
                  );
                }
              } else if (e.target.value.length < 7) {
                setHasCheckedCommissionId(false);
                setValidCommissionId(true);
              }
            }}
          />
        </div>
      </>
    ) : (
      <>
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Producer Information</strong>
          </div>
        </div>
        <div className="card-body">
          <p className="label">Producer Name</p>
          <p>{producer.name}</p>
          <p className="mb-4" />
          <p className="label">Producer Code</p>
          <p>{producer.commissionId}</p>
          <p className="mb-4" />
        </div>
      </>
    )}
  </div>
);

ProducerInformation.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
  validCommissionId: PropTypes.bool.isRequired,
  setValidCommissionId: PropTypes.func.isRequired,
  hasCheckedCommissionId: PropTypes.bool.isRequired,
  setHasCheckedCommissionId: PropTypes.func.isRequired,
  checkAndSetValidCommissionId: PropTypes.func.isRequired,
};

export default ProducerInformation;
