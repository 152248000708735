import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import SearchFacilitiesContainer from "../SearchFacilitiesContainer/SearchFacilitiesContainer";
import { emptyFacility, facilityPropType } from "../../Helpers/FacilityModel";
import LoadFacilityContainer from "../LoadFacility/LoadFacilityContainer";
import FacilitiesMenu from "./FacilitiesMenu";
import FacilityContainer from "../Facility/FacilityContainer";
import FacilityNotesContainer from "../Notes/FacilityNotesContainer";
import LoadVenueContainer from "../LoadVenue/LoadVenueContainer";
import VenuesContainer from "../Venues/VenuesContainer";
import VenueContainer from "../Venue/VenueContainer";
import AddNewVenueContainer from "../Venue/AddNewVenueContainer";
import AddNewFacilityContainer from "../Facility/AddNewFacilityContainer";
import {
  getUrl,
  ADD_FACILITY_PAGE,
  ADD_VENUE_PAGE,
  FACILITIES_PAGE,
  FACILITY_NOTES_PAGE,
  FACILITY_PAGE,
  VENUES_PAGE,
  VENUE_PAGE,
} from "../../Helpers/URLParser";

const Facilities = ({ facility }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(FACILITIES_PAGE)}
          component={SearchFacilitiesContainer}
        />
        <Route
          path={getUrl(ADD_FACILITY_PAGE)}
          component={AddNewFacilityContainer}
        />
        <Route
          path={getUrl(FACILITIES_PAGE)}
          render={() => (
            <LoadFacilityContainer>
              {/* LoadFacilityContainer will load the current facility when it is mounted */}
              <div className="subsection__wrapper">
                <FacilitiesMenu id={facility.id || 0} />
                <div className="subsection__container">
                  <div>
                    <Switch>
                      <Route
                        path={getUrl(VENUE_PAGE)}
                        render={() => (
                          <LoadVenueContainer>
                            <VenueContainer />
                          </LoadVenueContainer>
                        )}
                      />
                      <Route
                        path={getUrl(FACILITY_PAGE)}
                        component={FacilityContainer}
                      />
                      <Route
                        path={getUrl(FACILITY_NOTES_PAGE)}
                        component={FacilityNotesContainer}
                      />
                      <Route
                        path={getUrl(VENUES_PAGE)}
                        component={VenuesContainer}
                      />
                      <Route
                        path={getUrl(ADD_VENUE_PAGE)}
                        render={({ props }) => (
                          <AddNewVenueContainer
                            {...props}
                            facilityId={facility.id || 0}
                          />
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </LoadFacilityContainer>
          )}
        />
      </Switch>
    </>
  );
};

Facilities.propTypes = {
  facility: facilityPropType,
};

Facilities.defaultProps = {
  facility: emptyFacility,
};

const mapStateToProps = (state) => ({
  facility: state.facilities.currentFacility,
});

const FacilitiesContainer = connect(mapStateToProps)(Facilities);

export default FacilitiesContainer;
