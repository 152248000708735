/**
 * Middleware used to handle changes in location
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import { clearVenueAlert } from "../Actions/actions";
import { getPageType } from "../Helpers/URLParser";

// Sets a body class related to page type
// e.g. page-login, page-policies, etc
const setBodyClass = (location) => {
  const page = getPageType(location);
  document.body.classList.forEach((n) => {
    if (/^page-/.test(n)) {
      document.body.classList.remove(n);
    }
  });
  if (page) {
    document.body.classList.add(`page-${page}`);
  }
};

const NavigationMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    if (state.venues.newVenueStatus === true) {
      // clear the venue migration success alert/toast notification. Watches for location change and clears the alert/toast.
      store.dispatch(clearVenueAlert());
    }
    setBodyClass(action.payload.location.pathname);
  }
  return next(action);
};

export default NavigationMiddleware;
