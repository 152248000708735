import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, Card } from "react-bootstrap";
import { MAX_PRODUCER_CONTACTS } from "../../../constants";
import { contactEmailsUniqueMessage } from "../../../Helpers/validators";
import { TextInput, EmailInput } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
const { email, required } = validators;
const { normalizeEmail } = normalizers;

const ContactFields = ({ fields }) => (
  <>
    {fields.map((contact, i) => {
      return (
        <Card
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="w-100 mt-4"
        >
          <Card.Header className="bg-transparent d-flex justify-content-between font-weight-bold">
            <div>{`Producer Contact #${i + 1}`}</div>
            <Button
              variant="link"
              className="btn-link--delete"
              onClick={() => fields.remove(i)}
            >
              Delete
            </Button>
          </Card.Header>
          <Card.Body>
            <Field
              component={TextInput}
              name={`${contact}.fullName`}
              label="Full name"
              ariaLabel="Full name"
              inputClassName="form-control-lg col-lg-6 mb-4"
            />
            <Field
              component={EmailInput}
              validate={[required, email]}
              normalize={normalizeEmail}
              name={`${contact}.email`}
              errorClassName="mb-2"
              inputClassName="form-control-lg col-lg-6 mb-4"
              showErrors={(meta) =>
                (meta.error && meta.touched) ||
                meta.error === contactEmailsUniqueMessage
              }
            />
          </Card.Body>
        </Card>
      );
    })}
    <div className="mt-4">
      {fields.length < MAX_PRODUCER_CONTACTS && (
        <Button
          variant="outline-secondary"
          className="px-4 mt-4"
          onClick={() => fields.push({})}
          block
        >
          Add contact
        </Button>
      )}
    </div>
  </>
);

ContactFields.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
};

export default ContactFields;
