import { Modal } from "react-bootstrap";

const ExclusiveAccessModal = ({ claimOwner, show, handleClose }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Body>This facility is being edited by {claimOwner}</Modal.Body>
    </Modal.Header>
  </Modal>
);
export default ExclusiveAccessModal;
