import React from "react";
import { Note } from "@jauntin/react-ui";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { venueNotesFormName } from "../../constants";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../Helpers/ReduxFormPropTypes";
import { required } from "../../Helpers/validators";
import { venuePropType } from "../../Helpers/VenueModel";
import VenueService from "../../Helpers/VenueService";
import API from "../../Helpers/API";
import { getVenue } from "../../Actions/actions";

const MessageField = ({ input, meta }) => (
  <div className="col-sm form-group">
    <label
      htmlFor={input.name}
      className={`${
        meta.touched && meta.error ? "label form-error__label" : "label"
      } mb-2`}
    >
      Add a manual note
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <textarea
      {...input}
      id={input.name}
      className="form-control"
      aria-label="Add a manual note"
      placeholder="Write your message here"
      rows="4"
    />
  </div>
);
MessageField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

const VenueNotesContainer = ({
  venue,
  pristine,
  valid,
  submitting,
  handleSubmit,
  addNote,
  reset,
}) => (
  <div className="card w-100 venue-notes">
    <div className="card-header bg-transparent d-flex justify-content-between">
      <div className="my-auto">
        <strong>Notes</strong>
      </div>
    </div>
    <div className="card-body my-2">
      <form onSubmit={handleSubmit((values) => addNote({ values, reset }))}>
        <div className="form-row">
          <Field
            disabled={submitting}
            name="message"
            component={MessageField}
            validate={[required]}
          />
        </div>
        <div className="form-row">
          <button
            disabled={pristine || submitting || !valid}
            className="btn btn-primary ml-auto px-4 mx-2"
            type="submit"
          >
            Publish
          </button>
        </div>
      </form>
      <hr />
      <div className="font-weight-bold mb-2">History</div>
      <div className="border rounded p-3 venue-notes__history">
        {(venue.venueNotes || []).map(
          ({ id, message, type, created, createdBy }) => (
            <Note
              key={id}
              message={message}
              type={type}
              created={created}
              userName={createdBy}
            />
          )
        )}
      </div>
    </div>
  </div>
);

VenueNotesContainer.propTypes = {
  venue: venuePropType.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  venue: state.venues.currentVenue,
});

const mapDispatchToProps = (dispatch) => ({
  addNote: ({ values, reset }) => {
    return dispatch((_, getState) => {
      const facility = getState().facilities.currentFacility;
      const venue = getState().venues.currentVenue;
      return new VenueService(new API())
        .postAddNote(facility.id, venue.id, values)
        .catch(() => {
          // Do nothing
        })
        .then(() => {
          dispatch(getVenue(facility.id, venue.id));
        })
        .finally(reset);
    });
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: venueNotesFormName,
  })
)(VenueNotesContainer);
