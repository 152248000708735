import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./Reducers/reducers";
import priceChangeObserver from "./Helpers/PriceChangeObserver";
import NavigationMiddleware from "./Middleware/NavigationMiddleware";
import InitializeDamageToRentedPropertyMiddleware from "./Middleware/InitializeDamageToRentedPropertyMiddleware";
import InitializeLiquorLiabilityMiddleware from "./Middleware/InitializeLiquorLiabilityMiddleware";

export default function configureStore() {
  const store = createStore(
    createRootReducer(),
    composeWithDevTools(
      applyMiddleware(
        NavigationMiddleware,
        InitializeDamageToRentedPropertyMiddleware,
        InitializeLiquorLiabilityMiddleware,
        thunkMiddleware
      )
    )
  );

  priceChangeObserver(store);

  return store;
}
