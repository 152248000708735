import React, { createContext, useEffect, useCallback, useState } from "react";
import ClaimsService from "../../Helpers/ClaimsService";
import API from "../../Helpers/API";

const claimsService = new ClaimsService(new API());

export const ExclusiveAccessClaimContext = createContext();
export const ExclusiveAccessClaimProvider = ({
  children,
  accessType,
  itemId,
  failedCheck = () => {},
  seconds = 20,
  pollPeriodSeconds = 15,
}) => {
  const [claimValid, setClaimValid] = useState(false);
  const [claimOwner, setClaimOwner] = useState(null);
  const [hasChecked, setHasChecked] = useState(false);
  const makeClaim = useCallback(async () => {
    clearTimeout(window[`ExclusiveAccessClaimContext${accessType}${itemId}`]);
    const response = await claimsService.postClaim({
      accessType,
      itemId,
      seconds,
    });

    window[`ExclusiveAccessClaimContext${accessType}${itemId}`] = setTimeout(
      makeClaim,
      pollPeriodSeconds * 1000
    );
    if (response.status === 403) {
      setClaimValid(false);
      setClaimOwner(response.data.claimOwner);
    } else {
      setClaimValid(true);
      setClaimOwner(null);
    }
    setHasChecked(true);
  }, [accessType, itemId, pollPeriodSeconds, seconds]);

  const releaseClaim = useCallback(async () => {
    clearTimeout(window[`ExclusiveAccessClaimContext${accessType}${itemId}`]);
    claimsService.postRelease({ accessType, itemId, seconds });
  }, [accessType, itemId, seconds]);

  const checkClaim = useCallback(
    (callback) => {
      if (claimValid) {
        callback();
      } else {
        failedCheck();
      }
    },
    [claimValid, failedCheck]
  );

  useEffect(() => {
    makeClaim();
    return releaseClaim;
  }, [releaseClaim, makeClaim, accessType, itemId, seconds, pollPeriodSeconds]);

  return (
    <ExclusiveAccessClaimContext.Provider
      value={{ checkClaim, claimValid, claimOwner, hasChecked }}
    >
      {children}
    </ExclusiveAccessClaimContext.Provider>
  );
};
