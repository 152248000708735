import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormValues,
  reduxForm,
  reset,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  ACTIVE,
  addFacilityForm,
  alertNew,
  DEFAULT_PRODUCER_CODE,
  modalUpdateServerErrorMessage,
  producerContactsPropType,
  producersPropType,
  statePropType,
} from "../../constants";
import FacilityCode from "./FormElements/FacilityCode";
import {
  contactEmailsUnique,
  facilityCode,
  facilityOtherAdditionalInsuredZipCode,
  facilityProducer,
} from "../../Helpers/validators";
import Producer from "./FormElements/Producer";
import {
  normalizeZip,
  normalizeFacilityCode,
  setCommissionRate,
} from "../../normalizer";
import {
  ADD_PRODUCER_PAGE,
  FACILITIES_PAGE,
  getUrl,
} from "../../Helpers/URLParser";
import {
  searchProducersForFacility,
  checkIsValidFacilityCode,
  setSelectedProducerInResults,
  setNewFacilityStatusMessage,
  errorResponse,
  setProducerSearchResults,
  showFacilityStatusAlert,
  getGeneratedFacilityCodeAction,
  setGeneratedFacilityCode,
  setDefaultAddFacilityCommissionRate,
} from "../../Actions/actions";
import Debounce from "../../Helpers/Debounce";
import FacilityService from "../../Helpers/FacilityService";
import API from "../../Helpers/API";
import CountryField from "./FormElements/CountryField";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import CommissionRate from "./FormElements/CommissionRateField";
import ProducerContacts from "./FormElements/ProducerContactsField";
import {
  TextInput,
  CheckboxInput,
  Button,
  SelectList,
} from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
import { getLgSelectStyle } from "../../Helpers/ReactSelectStyle";
import { useStateOptions } from "../../Hooks/useStateOptions";

const { required } = validators;

const debouncer = new Debounce({ period: 500 });
const allFormValues = getFormValues(addFacilityForm);
const formValues = formValueSelector(addFacilityForm);

const SectionHeader = ({ text }) => (
  <div className="card-header bg-transparent">
    <strong>{text}</strong>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const AddFacility = ({
  pristine,
  valid,
  states,
  goToSearchPage,
  checkAndSetValidFacilityCode,
  getProducers,
  producersList,
  producerContacts,
  getGeneratedFacilityCode,
  setSelectedProducerResult,
  addFacility,
  producerCode,
  commissionRateOptions,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validFacilityCode, setValidFacilityCode] = useState(true);
  const [hasCheckedFacilityCode, setHasCheckedFacilityCode] = useState(false);

  const stateOptions = useStateOptions(states);

  // Clears producer search results on page load
  useEffect(() => {
    setSelectedProducerResult("", commissionRateOptions);
    getGeneratedFacilityCode(setHasCheckedFacilityCode, setValidFacilityCode);
  }, [
    setSelectedProducerResult,
    commissionRateOptions,
    getGeneratedFacilityCode,
  ]);

  return (
    <div className="scroll-part">
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Facility</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Facility"
              className="btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addFacility(handleShowError)}
              disabled={
                pristine ||
                !valid ||
                !validFacilityCode ||
                !hasCheckedFacilityCode
              }
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card w-100">
          <SectionHeader text="Facility Information" />
          <div className="card-body">
            <Row>
              <Col md="auto">
                <Field
                  component={FacilityCode}
                  validate={[required, facilityCode]}
                  name="facilityCode"
                  type="text"
                  maxLength="4"
                  normalize={normalizeFacilityCode}
                  onChange={(e) => {
                    if (
                      e.target.value.length === 3 ||
                      e.target.value.length === 4
                    ) {
                      setHasCheckedFacilityCode(false);
                      debouncer.do(
                        checkAndSetValidFacilityCode,
                        e.target.value,
                        setHasCheckedFacilityCode,
                        setValidFacilityCode
                      );
                    } else if (e.target.value.length < 3) {
                      setHasCheckedFacilityCode(false);
                      setValidFacilityCode(true);
                    }
                  }}
                  hasCheckedFacilityCode={hasCheckedFacilityCode}
                  validFacilityCode={validFacilityCode}
                />
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <div className="my-4">
                  As it should appear on all certificates of insurance:
                </div>
                <Field
                  component={TextInput}
                  validate={[required]}
                  label="Facility name"
                  ariaLabel="Facility name"
                  name="otherInsured.companyName"
                  inputClassName="form-control-lg mb-4"
                  errorClassName="d-inline ml-2"
                />
                <Field
                  component={TextInput}
                  name="otherInsured.address1"
                  label="Street address"
                  ariaLabel="Street address"
                  inputClassName="form-control-lg mb-2"
                  wrapperClassName="mb-0"
                />
                <Field
                  component={TextInput}
                  name="otherInsured.address2"
                  ariaLabel="Address Line 2"
                  inputClassName="form-control-lg mb-2"
                />
                <div className="form-row">
                  <div className="col-sm">
                    <Field
                      component={TextInput}
                      name="otherInsured.city"
                      type="text"
                      label="City"
                      ariaLabel="City"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="col-sm">
                    <label className="label" htmlFor="otherInsured.state">
                      State
                    </label>
                    <Field
                      name="otherInsured.state"
                      component={SelectList}
                      customStyles={getLgSelectStyle()}
                      placeholder="State"
                      options={stateOptions}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      component={TextInput}
                      validate={[facilityOtherAdditionalInsuredZipCode]}
                      normalize={normalizeZip}
                      name="otherInsured.zip"
                      type="text"
                      label="Zipcode"
                      ariaLabel="Zip"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div hidden>
                    <Field
                      name="otherInsured.country"
                      component={CountryField}
                      type="select"
                      input={{ disabled: true }}
                      hidden="true"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className="mt-3 form-row">
                  <div className="col-sm form-group">
                    <Field
                      component={CheckboxInput}
                      className="d-flex align-items-center"
                      name="hideFacilityInfo"
                      type="checkbox"
                      ariaLabel="Do not print Facility Information on Certificates"
                      label="Do not print Facility Information on Certificates"
                      labelClassName="pl-2 label hideFacilityInfo"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="card mt-4">
          <SectionHeader text="Producer" />
          <div className="card-body">
            <Row>
              <Col md={7}>
                <Field
                  component={Producer}
                  validate={[facilityProducer]}
                  name="producer"
                  type="text"
                  getProducers={getProducers}
                  producersList={producersList}
                  setResults={setSelectedProducerResult}
                  rateOptions={commissionRateOptions}
                />
                <div className="mb-4">
                  Not finding the producer&apos;s name or code?
                  <a
                    href={getUrl(ADD_PRODUCER_PAGE)}
                    className="font-weight-normal ml-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a new producer
                  </a>
                </div>

                {producerContacts.length > 0 && (
                  <FieldArray
                    name="producer.producerContacts"
                    component={ProducerContacts}
                    producerContacts={producerContacts}
                  />
                )}

                <Field
                  component={CommissionRate}
                  validate={[required]}
                  name="facilityCommissionRate"
                  type="text"
                  normalize={setCommissionRate(producerCode)}
                  maxLength="2"
                  options={commissionRateOptions}
                  disabled={producerCode === DEFAULT_PRODUCER_CODE}
                />
              </Col>
            </Row>
          </div>
        </div>

        <FieldArray name="contacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </div>
  );
};

AddFacility.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  getProducers: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType),
  getGeneratedFacilityCode: PropTypes.func.isRequired,
  setSelectedProducerResult: PropTypes.func.isRequired,
  addFacility: PropTypes.func.isRequired,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
  producerCode: PropTypes.string.isRequired,
  commissionRateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

AddFacility.defaultProps = {
  producersList: [],
  producerContacts: [],
};

const mapStateToProps = (state) => {
  const hasProducerContacts =
    state.form.addFacility.values &&
    state.form.addFacility.values.producer &&
    state.form.addFacility.values.producer.producerContacts;
  return {
    states: state.app.states,
    producersList: state.facilities.producersSearchResults,
    producerContacts: hasProducerContacts
      ? state.form.addFacility.values.producer.producerContacts
      : [],
    producerCode: formValues(state, "producer")
      ? formValues(state, "producer").value
      : "",
    commissionRateOptions: state.facilities.commissionRates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () => {
    dispatch(reset(addFacilityForm));
    dispatch(ConnectedRouter.push(getUrl(FACILITIES_PAGE)));
  },
  getProducers: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(searchProducersForFacility(searchInput)),
      inputValue
    ),
  checkAndSetValidFacilityCode: (
    id,
    setHasCheckedCommissionId,
    setValidCommissionId
  ) =>
    dispatch(
      checkIsValidFacilityCode(
        id,
        setHasCheckedCommissionId,
        setValidCommissionId
      )
    ),
  getGeneratedFacilityCode: (setHasCheckedFacilityCode, setValidFacilityCode) =>
    dispatch(
      getGeneratedFacilityCodeAction(
        setHasCheckedFacilityCode,
        setValidFacilityCode
      )
    ),
  setSelectedProducerResult: (producer, rateOptions) => {
    dispatch(setSelectedProducerInResults(producer));
    dispatch(
      setDefaultAddFacilityCommissionRate(
        producer && producer.value === DEFAULT_PRODUCER_CODE
          ? rateOptions[0]
          : rateOptions[rateOptions.length - 1]
      )
    );
  },
  addFacility: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const emailProducerContacts = values.producer.producerContacts.filter(
        (contact) => contact.copyOnEmails
      );
      const data = {
        status: ACTIVE, // TODO: temporary, anticipate status selector in future
        code: values.facilityCode,
        hideFacilityInfo: values.hideFacilityInfo,
        otherInsured: values.otherInsured,
        producerCommissionId: values.producer.value,
        emailProducerContacts,
        commissionRate: values.facilityCommissionRate / 100,
        contacts: values.contacts,
      };

      return new FacilityService(new API())
        .postAddNewFacilityDetails(data)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showFacilityStatusAlert(true));
            dispatch(
              setNewFacilityStatusMessage(
                alertNew(values.otherInsured.companyName)
              )
            );
            dispatch(setProducerSearchResults());
            dispatch(setGeneratedFacilityCode());
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addFacility: (handleShowError) =>
    dispatchProps.addFacility(dispatchProps.goToSearchPage, handleShowError),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addFacilityForm,
    initialValues: {
      hideFacilityInfo: false,
      facilityCode: "",
      producer: "",
      facilityCommissionRate: "5",
      otherInsured: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      contacts: [
        {
          fullName: "",
          role: "",
          email: "",
          copyOnEmails: false,
        },
      ],
    },
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(AddFacility);
