import React from "react";
import { connect } from "react-redux";
import SearchProducersContainer from "../SearchProducersContainer/SearchProducersContainer";
import { producerPropType, emptyProducer } from "../../Helpers/ProducerModel";
import LoadProducerContainer from "../LoadProducer/LoadProducerContainer";
import ProducersMenu from "./ProducersMenu";
import ProducerContainer from "../Producer/ProducerContainer";
import ProducerNotesContainer from "../Notes/ProducerNotesContainer";
import { Switch, Route } from "react-router-dom";
import {
  getUrl,
  PRODUCERS_PAGE,
  ADD_PRODUCER_PAGE,
  PRODUCER_NOTES_PAGE,
  PRODUCER_PAGE,
} from "../../Helpers/URLParser";
import AddNewProducerContainer from "../Producer/AddNewProducerContainer";

const Producers = ({ producer }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(PRODUCERS_PAGE)}
          component={SearchProducersContainer}
        />
        <Route
          path={getUrl(ADD_PRODUCER_PAGE)}
          component={AddNewProducerContainer}
        />
        <Route
          path={getUrl(PRODUCERS_PAGE)}
          render={() => (
            <LoadProducerContainer>
              {/* LoadProducerContainer will load the current producer when it is mounted */}
              <div className="subsection__wrapper">
                <ProducersMenu id={producer.id || 0} />
                <div className="subsection__container">
                  <div>
                    <Switch>
                      <Route
                        path={getUrl(PRODUCER_PAGE)}
                        component={ProducerContainer}
                      />
                      <Route
                        path={getUrl(PRODUCER_NOTES_PAGE)}
                        component={ProducerNotesContainer}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </LoadProducerContainer>
          )}
        />
      </Switch>
    </>
  );
};

Producers.propTypes = {
  producer: producerPropType,
};

Producers.defaultProps = {
  producer: emptyProducer,
};

const mapStateToProps = (state) => ({
  producer: state.producers.currentProducer,
});

const ProducersContainer = connect(mapStateToProps)(Producers);

export default ProducersContainer;
