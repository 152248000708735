import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const CompanyTypeField = ({ input, meta }) => (
  <div className="col-sm-6 form-group">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Type of Company
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg"
    >
      <option value="Individual">Individual</option>
      <option value="Corporation">Corporation</option>
      <option value="jointVenture">Joint Venture</option>
      <option value="Partnership">Partnership</option>
      <option value="LLC">LLC</option>
      <option value="Other">Other</option>
    </select>
  </div>
);
CompanyTypeField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default CompanyTypeField;
