import React, { useContext, useRef, useState } from "react";
import { initialize, reset } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { getUrl, FACILITY_PAGE } from "../../Helpers/URLParser";
import { editFacilityInitialFormValues } from "../../Helpers/FacilityModel";
import {
  setEditingAction,
  setSelectedProducerInResults,
} from "../../Actions/actions";
import { facilityFormName, DEFAULT_FACILITY_CODE } from "../../constants";
import { Button } from "@jauntin/react-ui";
import {
  ExclusiveAccessClaimContext,
  ExclusiveAccessClaimProvider,
} from "../../Providers/ExclusiveAccessClaim/ExclusiveAccessClaimProvider";
import ExclusiveAccessModal from "../LoadFacility/ExclusiveAccessModal";

const PopoverContent = ({
  facility,
  showClaimModal,
  setShowClaimModal,
  editReset,
  setEditing,
}) => {
  const history = useHistory();
  const { checkClaim, claimOwner } = useContext(ExclusiveAccessClaimContext);
  return (
    <>
      <ExclusiveAccessModal
        show={showClaimModal}
        handleClose={() => setShowClaimModal(false)}
        claimOwner={claimOwner?.name}
      />
      <Button
        className="popover-item__btn"
        onClick={() =>
          checkClaim(() => {
            editReset(facility);
            setEditing(true);
            history.push(getUrl(FACILITY_PAGE, facility.id));
          })
        }
        text={
          <>
            <i className="fal fa-edit mr-2 col-1 p-0" />
            <span className="col-auto p-0">Edit facility details</span>
          </>
        }
      />
    </>
  );
};

const SearchResults = ({
  facilitiesList,
  loadFacility,
  editReset,
  setEditing,
}) => {
  const [showClaimModal, setShowClaimModal] = useState(false);
  const showModalTimeout = useRef(null);

  return (
    <table className="table subsection-table mt-4">
      <thead>
        <tr>
          <th scope="col" width="264">
            Facility Name
          </th>
          <th scope="col" width="123">
            ID
          </th>
          <th scope="col">Producer</th>
          <th scope="col" width="130">
            Venues
          </th>
          <th scope="col" width="161">
            Date Modified
          </th>
          <th scope="col" className="px-0" />
        </tr>
      </thead>
      <tbody>
        {facilitiesList.map((facility) => (
          <tr key={`row${facility.id}`}>
            <td>
              <Button
                className="btn-link btn--edit text-left"
                text={facility.otherAdditionalInsured.companyName}
                onClick={() => loadFacility(facility.id)}
              />
            </td>
            <td>{facility.code}</td>
            <td>
              {facility.producerName}
              <br />
              {facility.producerCommissionId}
            </td>
            <td className="text-right pr-5">
              {stringHelpers.commaSeparatedNumber(facility.venuesCount)}
            </td>
            <td>
              <TimezoneFormatted
                date={facility.updated}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </td>
            <td className="pl-0">
              {facility.code !== DEFAULT_FACILITY_CODE && (
                <PopoverTrigger
                  styleClass="d-inline-block pt-0"
                  content={
                    <ExclusiveAccessClaimProvider
                      accessType="editFacility"
                      itemId={facility.id.toString()}
                      failedCheck={() => {
                        setShowClaimModal(true);
                        clearTimeout(showModalTimeout.current);
                        showModalTimeout.current = setTimeout(
                          () => setShowClaimModal(false),
                          5000
                        );
                      }}
                    >
                      <PopoverContent
                        facility={facility}
                        showClaimModal={showClaimModal}
                        setShowClaimModal={setShowClaimModal}
                        editReset={editReset}
                        setEditing={setEditing}
                      />
                    </ExclusiveAccessClaimProvider>
                  }
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SearchResults.propTypes = {
  facilitiesList: PropTypes.any.isRequired,
  loadFacility: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (facility) => {
    const otherInsured = facility.otherAdditionalInsured;
    dispatch(reset(facilityFormName));
    dispatch(
      initialize(
        facilityFormName,
        editFacilityInitialFormValues(facility, otherInsured)
      )
    );
    dispatch(
      setSelectedProducerInResults({
        value: facility.producerCommissionId,
        label: `${facility.producerName} — ${facility.producerCommissionId}`,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
