/**
 *
 * Reducers related to data on the Facilities page.
 *
 */
import {
  emptyFacility,
  convertServerFacilityToFacilityModel,
} from "../Helpers/FacilityModel";
import {
  SET_NEW_FACILITY_NAME,
  SET_PAGE,
  UPDATE_FACILITIES_SEARCH_TERM,
  UPDATE_FACILITIES_TABLE,
  UPDATE_FACILITY_DATA,
  SET_PRODUCER_SEARCH_RESULTS,
  SET_SELECTED_PRODUCERS_IN_RESULTS,
  SET_EDITING,
  SHOW_FACILITY_STATUS_ALERT,
  UPDATE_REASSIGN_VENUE_FACILITIES_TABLE,
  SET_SELECTED_FACILITIES_IN_RESULTS,
  CLEAR_FACILITY_DATA,
} from "../Actions/actions";

const initialState = () => ({
  facilityTableData: [],
  searchTerm: "",
  facilityPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  currentFacility: emptyFacility,
  valid: false,
  newFacilityStatus: false,
  newFacilityStatusMessage: "",
  producersSearchResults: [],
  editing: false,
  reassignVenueFacilitiesSearchResults: [],
  commissionRates: [0, 5],
});

const updateFacilitiesTable = (state, facilities) => {
  const updated = { ...state };
  updated.facilityPagination.count = facilities.count;
  updated.facilityTableData = facilities.data.map((m) =>
    convertServerFacilityToFacilityModel(m)
  );
  updated.facilityPagination.page = facilities.page;
  updated.facilityPagination.perPage = facilities.perPage;
  updated.facilityPagination.total = facilities.total;
  updated.facilityPagination.totalPages = facilities.totalPages;
  if (updated.facilityTableData.length === 0) {
    updated.facilityTableData = [];
  }
  return updated;
};

const updateFacilityData = (state, facilities) => {
  const updated = {
    ...state,
    currentFacility: { ...convertServerFacilityToFacilityModel(facilities) },
  };
  return updated;
};

const updateFacilitiesSearchTerm = (state, searchTerm) => {
  return {
    ...state,
    searchTerm,
  };
};

const setPage = (state, newPage) => {
  const updated = { ...state };
  updated.facilityPagination.page = newPage;
  return updated;
};

const showFacilityStatusAlert = (state, data) => {
  const updated = { ...state };
  updated.newFacilityStatus = data;
  return updated;
};

const setNewFacilityStatusMessage = (state, data) => {
  const updated = { ...state };
  updated.newFacilityStatusMessage = data;
  return updated;
};

const setProducerSearchResults = (state, producers) => {
  const updated = { ...state };
  const searchResults = [];
  if (producers.data && Object.keys(producers.data).length) {
    producers.data.map((producer) => {
      const producerContacts = [];
      producer.producer_contacts.map((contact) =>
        producerContacts.push({
          producerContactId: contact.id,
          name: contact.fullName,
          email: contact.email,
        })
      );
      return searchResults.push({
        value: producer.commissionId,
        label: `${producer.name} — ${producer.commissionId}`,
        producerContacts,
      });
    });
  }
  updated.producersSearchResults = searchResults;
  return updated;
};

const setSelectedProducerInResults = (state, data) => {
  const updated = { ...state };
  updated.producersSearchResults = data
    ? [data]
    : initialState().producersSearchResults;
  return updated;
};

const setEditing = (state, data) => {
  const updated = { ...state };
  updated.editing = data;
  return updated;
};

const updateReassignVenueFacilitiesTable = (state, facilities) => {
  const updated = { ...state };
  updated.reassignVenueFacilitiesSearchResults = facilities.data
    .filter((m) => m.code !== state.currentFacility.code)
    .map((m) => ({
      value: m.code,
      label: m.additional_insured_address.companyName,
    }));

  if (updated.reassignVenueFacilitiesSearchResults.length === 0) {
    updated.reassignVenueFacilitiesSearchResults = [];
  }
  return updated;
};

const setSelectedFacilityInResults = (state, data) => {
  const updated = { ...state };
  updated.reassignVenueFacilitiesSearchResults = data
    ? [data]
    : initialState().reassignVenueFacilitiesSearchResults;
  return updated;
};

const clearFacilityData = (state) => {
  const updated = {
    ...state,
    currentFacility: initialState().currentFacility,
  };
  return updated;
};

const facilitiesReducers = (state = initialState(), action) => {
  if (action.type === UPDATE_FACILITIES_TABLE) {
    return { ...updateFacilitiesTable(state, { ...action.payload }) };
  }
  if (action.type === UPDATE_FACILITY_DATA) {
    return { ...updateFacilityData(state, { ...action.payload }) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === UPDATE_FACILITIES_SEARCH_TERM) {
    return { ...updateFacilitiesSearchTerm(state, action.payload) };
  }
  if (action.type === SHOW_FACILITY_STATUS_ALERT) {
    return { ...showFacilityStatusAlert(state, action.payload) };
  }
  if (action.type === SET_NEW_FACILITY_NAME) {
    return { ...setNewFacilityStatusMessage(state, action.payload) };
  }
  if (action.type === SET_PRODUCER_SEARCH_RESULTS) {
    return { ...setProducerSearchResults(state, { ...action.payload }) };
  }
  if (action.type === SET_SELECTED_PRODUCERS_IN_RESULTS) {
    return { ...setSelectedProducerInResults(state, action.payload) };
  }
  if (action.type === SET_EDITING) {
    return { ...setEditing(state, action.payload) };
  }
  if (action.type === UPDATE_REASSIGN_VENUE_FACILITIES_TABLE) {
    return { ...updateReassignVenueFacilitiesTable(state, action.payload) };
  }
  if (action.type === SET_SELECTED_FACILITIES_IN_RESULTS) {
    return { ...setSelectedFacilityInResults(state, action.payload) };
  }
  if (action.type === CLEAR_FACILITY_DATA) {
    return { ...clearFacilityData(state) };
  }
  return state;
};

export default facilitiesReducers;
