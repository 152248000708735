import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import PropTypes from "prop-types";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  getUrl,
  POLICIES_PAGE,
  FACILITIES_PAGE,
  PRODUCERS_PAGE,
  LOGIN_PAGE,
  BLOCKED_VENUES_PAGE,
  VENUES_SEARCH_PAGE,
  REPORTS_PAGE,
  CONTACTS_PAGE,
  MAILCATCHER_PAGE,
  USERS_PAGE,
  ALERT_KEYWORDS_PAGE,
  SETTINGS_PAGE,
  // PARTNERS_PAGE,
  // CUSTOMERS_PAGE,
} from "../../Helpers/URLParser";
import GatherGuard from "../../Assets/Images/intact-gatherguard.png";
import { logout as logoutAction } from "../../Actions/actions";
import { Button } from "@jauntin/react-ui";
import {
  canManageSettings,
  canManageUsers,
} from "../../Helpers/StoreSelectors";

const MainMenu = ({
  goToLogin,
  logout,
  editing,
  canManageUsers,
  canManageSettings,
}) => {
  return (
    <SidebarMenu exclusiveExpand={false} collapseOnSelect={false}>
      <SidebarMenu.Collapse>
        <SidebarMenu.Header>
          <SidebarMenu.Brand className="main-menu__title">
            <NavLink to={getUrl(POLICIES_PAGE)}>
              <img src={GatherGuard} alt="Logo" className="img-fluid px-3" />
            </NavLink>
          </SidebarMenu.Brand>
          <SidebarMenu.Toggle />
        </SidebarMenu.Header>
        <div className="menu-wrapper">
          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(POLICIES_PAGE)}
                activeClassName="active"
                title="Policies"
              >
                <i className="fa fa-file" aria-hidden="true"></i>
                <span>Policies</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(FACILITIES_PAGE)}
                activeClassName="active"
                title="Facilities"
              >
                <i className="fa fa-users" aria-hidden="true"></i>
                <span>Facilities</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(VENUES_SEARCH_PAGE)}
                activeClassName="active"
                title="Venues"
              >
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>Venues</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(PRODUCERS_PAGE)}
                activeClassName="active"
                title="Producers"
              >
                <i className="fa fa-male" aria-hidden="true"></i>
                <span>Producers</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(CONTACTS_PAGE)}
                activeClassName="active"
                title="Contacts"
              >
                <i className="fa fa-address-book" aria-hidden="true"></i>
                <span>Contacts</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(BLOCKED_VENUES_PAGE)}
                activeClassName="active"
                title="Blocked Venues"
              >
                <i className="fa fa-lock" aria-hidden="true"></i>
                <span>Blocked Venues</span>
              </NavLink>
            </SidebarMenu.Nav>
            {canManageUsers && (
              <SidebarMenu.Nav>
                <NavLink
                  className="menu-container__item"
                  to={getUrl(USERS_PAGE)}
                  activeClassName="active"
                  title="Admin Users"
                >
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>Admin Users</span>
                </NavLink>
              </SidebarMenu.Nav>
            )}
            {process.env.REACT_APP_MAILCATCHER_LOCATION && (
              <SidebarMenu.Nav>
                <NavLink
                  className="menu-container__item"
                  to={getUrl(MAILCATCHER_PAGE)}
                  activeClassName="active"
                  title="MailCatcher"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>MailCatcher</span>
                </NavLink>
              </SidebarMenu.Nav>
            )}
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(REPORTS_PAGE)}
                activeClassName="active"
                title="Reports"
              >
                <i className="fa fa-file-upload" aria-hidden="true"></i>
                <span>Reports</span>
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(ALERT_KEYWORDS_PAGE)}
                activeClassName="active"
                title="Alert Keywords"
              >
                <i className="fa fa-file-exclamation" aria-hidden="true"></i>
                <span>Alert Keywords</span>
              </NavLink>
            </SidebarMenu.Nav>
            {canManageSettings &&
              process.env.REACT_APP_ENV !== "production" && (
                <SidebarMenu.Nav>
                  <NavLink
                    className="menu-container__item"
                    to={getUrl(SETTINGS_PAGE)}
                    activeClassName="active"
                    title="Settings"
                  >
                    <i className="fa fa-cog" aria-hidden="true"></i>
                    <span>Settings</span>
                  </NavLink>
                </SidebarMenu.Nav>
              )}
            {/*
            <SidebarMenu.Nav>
              <NavLink
                className="menu-container__item"
                to={getUrl(PARTNERS_PAGE)}
                activeClassName="active"
                title="Partners"
              >
                Partners
              </NavLink>
             </SidebarMenu.Nav>
            */}
          </SidebarMenu.Body>
          <Button
            className="btn-link menu-container__item menu-container__item--logout font-weight-bold"
            onClick={editing ? goToLogin : logout}
            text="Log out"
          />
        </div>
      </SidebarMenu.Collapse>
    </SidebarMenu>
  );
};

MainMenu.propTypes = {
  goToLogin: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  canManageUsers: PropTypes.bool,
  canManageSettings: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  editing:
    state.facilities.editing && state.venues.editing && state.producers.editing,
  canManageUsers: canManageUsers(state),
  canManageSettings: canManageSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  goToLogin: () => dispatch(ConnectedRouter.push(getUrl(LOGIN_PAGE))),
  logout: () => dispatch(logoutAction()),
});

const MainMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default MainMenuContainer;
