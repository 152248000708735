import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";

const ProducerCommissionId = ({
  input,
  meta,
  hasCheckedCommissionId,
  validCommissionId,
}) => (
  <>
    <label
      htmlFor={input.name}
      className={
        (meta.touched && meta.error) ||
        (hasCheckedCommissionId && !validCommissionId)
          ? "label form-error__label"
          : "label"
      }
    >
      Producer Code
    </label>
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    {!meta.error && !hasCheckedCommissionId && (
      <span className="form-error ml-2">Validating code...</span>
    )}
    {hasCheckedCommissionId && !validCommissionId && (
      <div className="form-row">
        <div className="col-sm form-group form-error">
          The Producer Code has already been taken.
        </div>
      </div>
    )}
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg col-md-4 mb-4"
      aria-label="Code"
      type="text"
    />
  </>
);
ProducerCommissionId.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  hasCheckedCommissionId: PropTypes.bool.isRequired,
  validCommissionId: PropTypes.bool.isRequired,
};

export default ProducerCommissionId;
