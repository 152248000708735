import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import appReducers from "./appStateReducers";
import policiesReducers from "./policiesReducers";
import formReducers from "./formReducers";
import authReducers from "./authReducers";
import producersReducers from "./producersReducers";
import facilitiesReducers from "./facilitiesReducers";
import venuesReducers from "./venuesReducers";
import contactsReducers from "./contactsReducers";
import usersReducers from "./usersReducers";
import alertKeywordsReducers from "./alertKeywordsReducers";

const reducers = () =>
  combineReducers({
    router: ConnectedRouter.routerReducer,
    app: appReducers,
    policies: policiesReducers,
    facilities: facilitiesReducers,
    venues: venuesReducers,
    producers: producersReducers,
    contacts: contactsReducers,
    users: usersReducers,
    auth: authReducers,
    alertKeywords: alertKeywordsReducers,
    form: formReducer.plugin(formReducers),
  });

export default reducers;
