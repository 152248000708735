/**
 *
 * Middleware that gets damageToRentedPropertyCoverageAmount value from policies reducer state and add it to form reducer state
 *
 */
import { SET_VALID_DRP, setDamageToRentedProperty } from "../Actions/actions";

const InitializeDamageToRentedPropertyMiddleware =
  (store) => (next) => (action) => {
    const state = store.getState();
    if (action.type === SET_VALID_DRP) {
      store.dispatch(
        setDamageToRentedProperty(
          state.policies.currentCoverage.damageToRentedPropertyCoverageAmount
        )
      );
      return next(action);
    }
    return next(action);
  };

export default InitializeDamageToRentedPropertyMiddleware;
