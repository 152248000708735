/**
 *
 * Services provided by the onebeacon server related to `Auth`
 *
 */
class AuthService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  login = (values) =>
    this.api.post({
      location: `/auth/login`,
      body: values,
    });

  authLogin = () =>
    this.api.get({
      location: `/auth/me`,
    });

  requestPasswordReset = (values) =>
    this.api.post({
      location: `/auth/request-password-reset`,
      body: values,
    });

  resetPassword = ({ email, token, password }) =>
    this.api.post({
      location: `/auth/reset-password`,
      body: { email, token, password },
    });

  authLogout = () =>
    this.api.post({
      location: `/auth/logout`,
    });

  verifyTwoFactor = (values) =>
    this.api.post({
      location: `/auth/two-factor/verify`,
      body: values,
    });

  resendTwoFactor = (values) =>
    this.api.post({
      location: `/auth/two-factor/resend`,
      body: values,
    });
}

export default AuthService;
