import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";
import { statePropType } from "../../../constants";
import { createStateOptions } from "../../../Helpers/StateSelectOptions";

const StateField = ({ input, meta, states, isPremiumChanged }) => (
  <div className="col-sm form-group">
    <label
      htmlFor={input.name}
      className={
        (meta.touched && meta.error) || (meta.dirty && isPremiumChanged)
          ? "label form-error__label"
          : "label"
      }
    >
      State
    </label>
    {((meta.touched && meta.error) || (meta.dirty && isPremiumChanged)) && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg"
      required
    >
      {createStateOptions(states)}
    </select>
  </div>
);

StateField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  isPremiumChanged: PropTypes.bool,
};

export default StateField;
