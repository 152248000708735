/**
 *
 * Reducers for general app state
 *
 */
import {
  API_ERROR,
  STORE_STATES,
  UPDATE_LOGIN_STATUS,
  LOGIN_LOCKED_OUT,
  SET_CURRENT_USER,
  SET_DATA_LOADED,
  SET_SORTABLE_EVENT_TYPES,
} from "../Actions/actions";

const initialState = () => ({
  isLoggedIn: false,
  isDataLoaded: false,
  maintenanceMode: false,
  states: [],
  eventTypes: [],
  timezone: null,
});

const updateLoginStatus = (state, status) => {
  const updated = { ...state };
  updated.isLoggedIn = status;
  return updated;
};

const setLoginLockedOut = (state, status) => {
  const lockedOut = { ...state };
  lockedOut.isLockedOut = status;
  return lockedOut;
};

const errorResponse = (state, error) => {
  if (
    error?.response?.status === 503 &&
    error?.response?.data?.message === "Maintenance"
  ) {
    return {
      ...state,
      maintenanceMode: true,
    };
  }

  return { ...state };
};

const appStateReducers = (state = initialState(), action) => {
  if (action.type === API_ERROR) {
    return errorResponse(state, action.error);
  }
  if (action.type === STORE_STATES) {
    return { ...state, ...{ states: action.payload } };
  }
  if (action.type === UPDATE_LOGIN_STATUS) {
    return { ...updateLoginStatus(state, action.payload) };
  }
  if (action.type === LOGIN_LOCKED_OUT) {
    return { ...setLoginLockedOut(state, action.payload) };
  }
  if (action.type === SET_CURRENT_USER) {
    return { ...state, currentUser: action.payload };
  }
  if (action.type === SET_DATA_LOADED) {
    return { ...state, ...action.payload, isDataLoaded: true };
  }
  if (action.type === SET_SORTABLE_EVENT_TYPES) {
    return { ...state, eventTypes: action.payload };
  }
  return state;
};

export default appStateReducers;
