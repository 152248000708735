import React, { Component } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import PropTypes from "prop-types";
import { toNumber } from "lodash";
import {
  getSearchPolicies,
  setPage,
  filterPoliciesWithUnknownVenues,
  disablePoliciesUnknownVenuesFilter,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import { getUrl, POLICY_PAGE, POLICIES_PAGE } from "../../Helpers/URLParser";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });

class Search extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  componentDidUpdate(prevProps) {
    const { page: prevPage } = prevProps;
    const { page, onPageChange } = this.props;
    if (page !== prevPage) {
      onPageChange(prevPage);
    }
  }

  render() {
    const {
      policyTableData,
      pagination,
      searchTerm,
      change,
      goToPolicy,
      goToPage,
      filterPolicies,
      showUnknownVenuesOnly,
    } = this.props;

    let resultsMessage = "";
    let policiesText = "";
    if (pagination.total === 0) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    policiesText = pagination.total === 1 ? "policy" : "policies";
    return (
      <div className="p-5 scroll-part">
        <h1>Policies</h1>
        <SearchField
          label="Search by policy number, insured name, event name or venue."
          fieldName="policiesSearchQuery"
          change={change}
          value={searchTerm}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${policiesText}`}
        />
        <div className="custom-control custom-checkbox mb-4">
          <input
            id="show-unknown-venues"
            className="custom-control-input"
            type="checkbox"
            checked={showUnknownVenuesOnly}
            onChange={filterPolicies}
          />
          <label
            htmlFor="show-unknown-venues"
            className="custom-control-label font-weight-bold pl-2"
          >
            Show Unknown Venues Only
          </label>
        </div>
        {policyTableData.length !== 0 ? (
          <>
            <SearchResultsTable
              policiesList={policyTableData}
              loadPolicy={goToPolicy}
            />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        ) : (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  policyTableData: PropTypes.arrayOf(coveragePropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  showUnknownVenuesOnly: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  goToPolicy: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  filterPolicies: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  policyTableData: state.policies.policyTableData,
  pagination: state.policies.policyPagination,
  searchTerm: state.policies.searchTerm,
  showUnknownVenuesOnly: state.policies.showUnknownVenuesOnly,
  page: toNumber(state.router.location.query.page),
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) => {
    dispatch(ConnectedRouter.push(`${getUrl(POLICIES_PAGE)}?page=${page}`));
    debouncer.do(
      (searchInput) => dispatch(getSearchPolicies(searchInput, page, perPage)),
      inputValue
    );
  },
  goToPolicy: (id) => dispatch(ConnectedRouter.push(getUrl(POLICY_PAGE, id))),
  goToPage: (searchTerm, newPage, perPage) => {
    dispatch(setPage(newPage));
    dispatch(ConnectedRouter.push(`${getUrl(POLICIES_PAGE)}?page=${newPage}`));
    dispatch(getSearchPolicies(searchTerm, newPage, perPage));
  },
  loadData: (searchTerm, newPage, perPage) => {
    dispatch(getSearchPolicies(searchTerm, newPage, perPage));
  },
  filterPolicies: (page) => {
    dispatch(ConnectedRouter.push(`${getUrl(POLICIES_PAGE)}?page=${page}`));
    dispatch(filterPoliciesWithUnknownVenues());
  },
  disableFilter: () => {
    dispatch(disablePoliciesUnknownVenuesFilter());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(inputValue, 1, stateProps.pagination.perPage),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage
    ),
  onMount: () => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (!page) {
      dispatchProps.disableFilter();
    }
    dispatchProps.loadData(term, page || 1, pagination.perPage);
  },
  onPageChange: (prevPage) => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (
      (page && page !== prevPage && page !== pagination.page) ||
      (!page && prevPage > 1)
    ) {
      dispatchProps.loadData(term, page || 1, pagination.perPage);
    }
  },
  filterPolicies: () => dispatchProps.filterPolicies(1),
});

const SearchPoliciesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchPoliciesContainer;
