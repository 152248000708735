import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  facilityCode,
  facilityOtherAdditionalInsuredZipCode,
} from "../../Helpers/validators";
import FacilityCode from "./FormElements/FacilityCode";
import {
  additionalInsuredPropType,
  facilityPropType,
} from "../../Helpers/FacilityModel";
import { normalizeFacilityCode, normalizeZip } from "../../normalizer";
import { statePropType } from "../../constants";
import CountryField from "./FormElements/CountryField";
import { TextInput, CheckboxInput, SelectList } from "@jauntin/react-ui";
import { validators } from "@jauntin/utilities";
import Debounce from "../../Helpers/Debounce";
import { getLgSelectStyle } from "../../Helpers/ReactSelectStyle";
import { useStateOptions } from "../../Hooks/useStateOptions";
const { required } = validators;

const debouncer = new Debounce({ period: 500 });

const Information = ({
  editing,
  facility,
  validFacilityCode,
  setValidFacilityCode,
  hasCheckedFacilityCode,
  setHasCheckedFacilityCode,
  checkAndSetValidFacilityCode,
  otherInsured,
  states,
}) => {
  const stateOptions = useStateOptions(states);

  return (
    <div className="card w-100">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Facility Information</strong>
        </div>
      </div>
      {editing ? (
        <>
          <div className="card-body">
            <Row>
              <Col md={10}>
                <Field
                  component={FacilityCode}
                  validate={[required, facilityCode]}
                  name="facilityCode"
                  type="text"
                  maxLength="4"
                  normalize={normalizeFacilityCode}
                  onChange={(e) => {
                    if (
                      (e.target.value.length === 3 ||
                        e.target.value.length === 4) &&
                      e.target.value !== facility.code
                    ) {
                      setHasCheckedFacilityCode(false);
                      debouncer.do(
                        checkAndSetValidFacilityCode,
                        e.target.value,
                        setHasCheckedFacilityCode,
                        setValidFacilityCode
                      );
                    } else if (e.target.value.length < 3) {
                      setHasCheckedFacilityCode(false);
                      setValidFacilityCode(true);
                    }
                  }}
                  hasCheckedFacilityCode={hasCheckedFacilityCode}
                  validFacilityCode={validFacilityCode}
                />
                <div className="my-4">
                  As it should appear on all certificates of insurance:
                </div>
                <Field
                  component={TextInput}
                  validate={[required]}
                  name="otherInsured.companyName"
                  label="Facility Name"
                  ariaLabel="Facility Name"
                  inputClassName="form-control-lg col-lg-6 mb-4"
                />
                <Field
                  component={TextInput}
                  name="otherInsured.address1"
                  label="Street address"
                  ariaLabel="Street address"
                  inputClassName="form-control-lg mb-2"
                  wrapperClassName="mb-0"
                />
                <Field
                  component={TextInput}
                  name="otherInsured.address2"
                  ariaLabel="Address Line 2"
                  inputClassName="form-control-lg mb-2"
                />
                <div className="form-row">
                  <div className="col-sm">
                    <Field
                      component={TextInput}
                      name="otherInsured.city"
                      type="text"
                      label="City"
                      ariaLabel="City"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="col-sm">
                    <label className="label" htmlFor="otherInsured.state">
                      State
                    </label>
                    <Field
                      name="otherInsured.state"
                      component={SelectList}
                      customStyles={getLgSelectStyle()}
                      placeholder="State"
                      options={stateOptions}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      component={TextInput}
                      validate={[facilityOtherAdditionalInsuredZipCode]}
                      normalize={normalizeZip}
                      name="otherInsured.zip"
                      type="text"
                      label="Zipcode"
                      ariaLabel="Zip"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div hidden>
                    <Field
                      name="otherInsured.country"
                      component={CountryField}
                      validate={[required]}
                      type="select"
                      input={{ disabled: true }}
                      hidden="true"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className="mt-3 form-row">
                  <div className="col-sm form-group">
                    <Field
                      component={CheckboxInput}
                      className="d-flex align-items-center"
                      name="hideFacilityInfo"
                      type="checkbox"
                      ariaLabel="Do not print Facility Information on Certificates"
                      label="Do not print Facility Information on Certificates"
                      labelClassName="pl-2 label hideFacilityInfo"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="card-body">
            <p className="label">Facility ID</p>
            <p className="mb-4">{facility.code}</p>

            <p className="label">Facility Name</p>
            <p className="mb-4">{otherInsured.companyName}</p>

            {!!otherInsured.id && (
              <p>
                <span className="d-flex">
                  {`${otherInsured.address1}${
                    otherInsured.address1 && otherInsured.address2 ? `,` : ""
                  } ${otherInsured.address2}`}
                </span>
                <span className="d-flex">
                  {`${otherInsured.city}${
                    otherInsured.city && otherInsured.state ? `,` : ""
                  } ${otherInsured.state} ${otherInsured.zip}`}
                </span>
              </p>
            )}
            <p className="mb-4" />
            <p className="label">
              Do not print Facility Information on Certificates
            </p>
            <p>{facility.hideFacilityInfo ? "Yes" : "No"}</p>
          </div>
        </>
      )}
    </div>
  );
};

Information.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
  validFacilityCode: PropTypes.bool.isRequired,
  setValidFacilityCode: PropTypes.func.isRequired,
  hasCheckedFacilityCode: PropTypes.bool.isRequired,
  setHasCheckedFacilityCode: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
};

export default Information;
