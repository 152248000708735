import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import AlertStatus from "../../Components/AlertStatus";
import DropzoneUploader from "../DropzoneUploader/DropzoneUploaderContainer";
import LoadingSpinner from "../../Components/LoadingSpinner";
import { Button } from "@jauntin/react-ui";

const ModalUpload = ({ show, handleClose, facilityId, refresh }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const clearAllAlerts = () => {
    setShowSuccess(false);
    setShowError(false);
    setShowWarning(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onExited={() => clearAllAlerts()}
      size="lg"
      backdrop={submitting ? "static" : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload venues by XLS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertStatus
          show={showSuccess}
          setShowAlert={setShowSuccess}
          message={successMessage}
          status="success"
        />
        <AlertStatus
          show={showError}
          setShowAlert={setShowError}
          message={errorMessage}
          status="danger"
        />
        <AlertStatus
          show={showWarning}
          setShowAlert={setShowWarning}
          message={warningMessage}
          status="warning"
        />
        <DropzoneUploader
          setSuccessMessage={setSuccessMessage}
          setShowSuccess={setShowSuccess}
          setErrorMessage={setErrorMessage}
          setShowError={setShowError}
          setWarningMessage={setWarningMessage}
          setShowWarning={setShowWarning}
          clearAllAlerts={clearAllAlerts}
          handleClose={handleClose}
          facilityId={facilityId}
          refresh={refresh}
          submitting={submitting}
          setSubmitting={setSubmitting}
        />
      </Modal.Body>
      <Modal.Footer className="">
        <Button
          className="btn-outline-secondary"
          text="Cancel"
          onClick={handleClose}
          disabled={submitting}
        />
      </Modal.Footer>
      {submitting && (
        <div className="dropzone__overlay">
          <div className="dropzone__overlay-inner">
            <LoadingSpinner />
            <div className="mt-2">Processing...</div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ModalUpload.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
};
ModalUpload.defaultProps = {
  show: false,
};

export default ModalUpload;
