import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { facilityPropType } from "../../Helpers/FacilityModel";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { Button } from "@jauntin/react-ui";

const ModalArchive = ({ show, handleCloseArchive, facility, archive }) => (
  <Modal show={show} size="lg">
    <Modal.Body className="pb-4">
      <Modal.Header className="w-100 border-0 pb-0" />
      <p className="mt-4 text-center">
        {facility.venuesCount
          ? `Archiving this facility will also archive ${stringHelpers.commaSeparatedNumber(
              facility.venuesCount
            )} ${facility.venuesCount === 1 ? "venue" : "venues"}.`
          : ""}
      </p>
      <p className="text-center">
        Are you sure you wish to continue with this change?
      </p>
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4">
      <Button
        text="Cancel"
        className="btn-outline-secondary px-4 mx-2"
        onClick={handleCloseArchive}
      />
      <Button
        text="Yes, archive this facility"
        className="btn-primary px-4 mx-2"
        onClick={archive}
      />
    </Modal.Footer>
  </Modal>
);

ModalArchive.propTypes = {
  show: PropTypes.bool,
  handleCloseArchive: PropTypes.func.isRequired,
  facility: facilityPropType.isRequired,
  archive: PropTypes.func.isRequired,
};

ModalArchive.defaultProps = {
  show: false,
};

export default ModalArchive;
