import React from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";

const ButtonCollapse = ({ clickFunction, showList, title }) => (
  <Button
    text={`${showList ? `-` : `+`} ${title}`}
    className="btn-link p-0 font-weight-bold text-left"
    onClick={clickFunction}
  />
);

ButtonCollapse.propTypes = {
  clickFunction: PropTypes.func.isRequired,
  showList: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ButtonCollapse;
