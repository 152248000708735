/**
 *
 * Services provided by the onebeacon server related to `Contacts`
 *
 */
class ContactsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getContacts = (input = "", contactType, page, perPage) => {
    let apiParams = { page, perPage };
    const location = `/${contactType}/contacts`;

    if (input.trim()) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }
    return this.api.get({
      location,
      params: apiParams,
    });
  };

  deleteContacts = (data) =>
    this.api.post({
      location: `/contact/${data.contactType}/bulkRemove`,
      body: { contacts: data.contactsToDelete },
    });

  updateContacts = (data) =>
    this.api.post({
      location: `/contact/${data.contactType}/bulkUpdate`,
      body: {
        contacts: data.contactsToUpdate,
        newContact: data.newContactData,
      },
    });
}

export default ContactsService;
