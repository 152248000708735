import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { zipCode } from "../../Helpers/validators";
import CountryField from "./FormElements/CountryField";
import RenterField from "./FormElements/RenterField";
import { normalizeZip } from "../../normalizer";
import { statePropType } from "../../constants";
import ContiguousStateField from "../Policy/FormElements/ContiguousStateField";
import ContactSameField from "../../Components/ContactSameField";
import { TextInput, EmailInput, PhoneInput } from "@jauntin/react-ui";
import { validators, normalizers } from "@jauntin/utilities";
const { required, email, phoneNumber } = validators;
const { normalizePhone, normalizeEmail } = normalizers;

const InsuranceContactBody = ({
  states,
  insuranceContactType,
  contactSame,
}) => {
  return (
    <>
      <div className="form-group">
        <ContactSameField name="contactSame" />
      </div>
      <div className="form-row">
        <Field
          component={TextInput}
          validate={[required]}
          name="insuranceContactFirstName"
          label="First Name"
          ariaLabel="First Name"
          placeholder="First Name"
          wrapperClassName="col-sm"
          inputClassName="form-control-lg"
        />
        <Field
          component={TextInput}
          validate={[required]}
          name="insuranceContactLastName"
          label="Last Name"
          ariaLabel="Last Name"
          placeholder="Last Name"
          wrapperClassName="col-sm"
          inputClassName="form-control-lg"
        />
      </div>
      <div className="form-row">
        <Field
          component={PhoneInput}
          validate={[required, phoneNumber]}
          normalize={normalizePhone}
          name="insuranceContactPhone"
          subtext="This will only be used for customer service."
          wrapperClassName="col-sm-6"
          errorClassName="mb-2"
          inputClassName="form-control-lg"
        />
        <Field
          component={EmailInput}
          validate={[required, email]}
          normalize={normalizeEmail}
          name="insuranceContactEmail"
          subtext="This is where the policy information will be sent."
          wrapperClassName="col-sm-6"
          errorClassName="mb-2"
          inputClassName="form-control-lg"
        />
      </div>
      {contactSame !== "yes" && (
        <>
          <RenterField name="insuranceContactType" validate={[required]} />
          {insuranceContactType !== "Person" && (
            <div className="form-row">
              <Field
                component={TextInput}
                validate={[required]}
                name="insuranceContactCompanyName"
                label="Company name"
                ariaLabel="Company name"
                placeholder="Company name"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
            </div>
          )}
          <Field
            component={TextInput}
            validate={[required]}
            name="insuranceContactAddress1"
            subtext="Mailing address, including apartment, suite, unit, building, floor, etc.."
            label="Street Address"
            ariaLabel="Address"
            placeholder="Address"
            inputClassName="form-control-lg"
          />
          <div className="form-row">
            <Field
              component={TextInput}
              validate={[required]}
              name="insuranceContactCity"
              label="City"
              ariaLabel="City"
              placeholder="City"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
            <Field
              component={ContiguousStateField}
              validate={[required]}
              name="insuranceContactState"
              type="select"
              states={states}
            />
            <Field
              component={TextInput}
              validate={[zipCode, required]}
              normalize={normalizeZip}
              name="insuranceContactZip"
              label="Zip"
              ariaLabel="Zip"
              placeholder="Zip"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
          </div>
          <div className="form-row" hidden>
            <Field
              type="hidden"
              component="input"
              name="insuranceContactCountry"
            />
            <Field
              component={CountryField}
              name="insuranceContactCountryPlaceholder"
              type="select"
              input={{ disabled: true }}
            />
          </div>
        </>
      )}
    </>
  );
};
InsuranceContactBody.propTypes = {
  states: PropTypes.arrayOf(statePropType).isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  contactSame: PropTypes.string.isRequired,
};

export default InsuranceContactBody;
