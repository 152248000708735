import React from "react";
import PropTypes from "prop-types";
import { producerPropType } from "../../Helpers/ProducerModel";
import { DEFAULT_PRODUCER_NAME } from "../../constants";
import { Button } from "@jauntin/react-ui";

const Header = ({
  editing,
  handleShowDiscard,
  handleShowUpdate,
  setEditing,
  editReset,
  pristine,
  valid,
  hasCheckedCommissionId,
  validCommissionId,
  producer,
}) => (
  <div className="content__header col-auto">
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="m-0 font-weight-bold">Producer Overview</h4>
      <div className="d-flex float-right">
        {editing ? (
          <>
            <Button
              text="Discard Changes"
              className="btn-outline-secondary px-4 mx-2"
              onClick={handleShowDiscard}
            />
            <Button
              text="Save Producer"
              className="btn-primary px-4 mx-2 text-nowrap"
              onClick={handleShowUpdate}
              disabled={
                pristine ||
                !valid ||
                !hasCheckedCommissionId ||
                !validCommissionId
              }
            />
          </>
        ) : (
          <>
            {/* TODO: use commented button below for archive functionality */}
            {/* <Button
              text="Archive Producer"
              className="btn-outline-secondary px-4 mx-2 text-nowrap"
              // TODO: action to be updated upon instructions from Rain/OBI
              onClick={() => true}
            /> */}
            {producer.name !== DEFAULT_PRODUCER_NAME && (
              <Button
                text="Edit Producer"
                className="btn-primary px-4 mx-2 text-nowrap"
                onClick={() => {
                  editReset();
                  setEditing(true);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  </div>
);

Header.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleShowDiscard: PropTypes.func.isRequired,
  handleShowUpdate: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  hasCheckedCommissionId: PropTypes.bool.isRequired,
  validCommissionId: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
};

export default Header;
