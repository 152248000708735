import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../../Helpers/ReduxFormPropTypes";
import customStyles from "../../../Helpers/ReactSelectStyle";

const SelectField = ({
  input,
  meta,
  options,
  className,
  placeholder,
  label,
}) => {
  return (
    <>
      {label && (
        <>
          <label
            htmlFor={input.name}
            className={
              meta.touched && meta.error ? "label form-error__label" : "label"
            }
          >
            {label}
          </label>
          {meta.touched && meta.error && (
            <div className="form-error mb-2">{meta.error}</div>
          )}
        </>
      )}
      <Select
        className={className}
        styles={customStyles}
        value={input.value}
        isSearchable={false}
        options={options}
        onChange={(value) => {
          input.onChange(value);
        }}
        onBlur={() => input.onBlur(input.value)}
        aria-label={placeholder}
        placeholder={placeholder}
      />
    </>
  );
};
SelectField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};
SelectField.defaultProps = {
  className: "",
  placeholder: "",
  label: "",
};

export default SelectField;
