import PropTypes from "prop-types";

// PropTypes for forms
const FormPropTypes = {
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dirty: PropTypes.bool,
  error: PropTypes.any,
  form: PropTypes.string,
  invalid: PropTypes.bool,
  initialized: PropTypes.bool,
  initialValues: PropTypes.object,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitting: PropTypes.bool,
  submitAsSideEffect: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  valid: PropTypes.bool,
  warning: PropTypes.any,
  array: PropTypes.shape({
    insert: PropTypes.func,
    move: PropTypes.func,
    pop: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
    removeAll: PropTypes.func,
    shift: PropTypes.func,
    splice: PropTypes.func,
    swap: PropTypes.func,
    unshift: PropTypes.func,
  }),
  asyncValidate: PropTypes.func,
  autofill: PropTypes.func,
  blur: PropTypes.func,
  change: PropTypes.func,
  clearAsyncError: PropTypes.func,
  clearFields: PropTypes.func,
  clearSubmitErrors: PropTypes.func,
  destroy: PropTypes.func,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  reset: PropTypes.func,
  resetSection: PropTypes.func,
  touch: PropTypes.func,
  submit: PropTypes.func,
  untouch: PropTypes.func,
  triggerSubmit: PropTypes.bool,
  clearSubmit: PropTypes.func,
  lockedOut: PropTypes.bool,
  setShowLockout: PropTypes.func.isRequired,
};
// PropTypes for objects provided by Redux-Form - input and meta.
const FieldMetaProps = PropTypes.shape({
  active: PropTypes.bool,
  autofilled: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  form: PropTypes.string,
  initial: PropTypes.any,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
  warning: PropTypes.string,
});
const FieldInputProps = PropTypes.shape({
  name: PropTypes.string,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  value: PropTypes.any,
});

export { FormPropTypes, FieldMetaProps, FieldInputProps };
