import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "../../Helpers/ReduxFormPropTypes";

const LoginField = ({ input, meta }) => (
  <div className="form-group">
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg"
      placeholder="Username"
      aria-label="Username"
      type="text"
    />
    {meta.touched && meta.error && (
      <div className="form-error my-1 ml-2 text-left">{meta.error}</div>
    )}
  </div>
);
LoginField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default LoginField;
