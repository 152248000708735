import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import copyToClipboard from "../../Helpers/CopyToClipboard";
import { venuePropType } from "../../Helpers/VenueModel";
import { venueFormName } from "../../constants";
import StatusField from "./FormElements/StatusField";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { ToastBody } from "@jauntin/react-ui";

const alertDelayClose = 3000; // milliseconds

const VenueStatus = ({ venue, editing }) => {
  const { referralLink } = venue;
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div className="card border-0">
        <Form.Row className="align-items-center mb-4">
          <Col xs="auto">
            <span className="label mr-2">Code:</span>
            <span>{venue.venueCode.toString().padStart(3, "0")}</span>
          </Col>
          <Col xs="auto" className="ml-4">
            {venue.blockedAt && (
              <span className="subsection-table__badge subsection-table__badge--blocked">
                Blocked
              </span>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Referral link:</strong>
              <em>{` ${referralLink}`}</em>
            </div>
            <div className="my-auto">
              <Button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  copyToClipboard(referralLink);
                  setShowToast(true);
                }}
              >
                Copy
              </Button>
            </div>
          </Col>
        </Form.Row>
        <Form.Row className="mb-4">
          <Col xs="auto">
            {editing ? (
              <Field
                component={StatusField}
                name="status"
                type="select"
                value={venue.status}
                selected={venue.status}
              />
            ) : (
              <>
                <strong className="mr-2">Status:</strong>
                <span className="text-capitalize">{venue.status}</span>
              </>
            )}
          </Col>
        </Form.Row>
      </div>
      <ToastBody
        show={showToast}
        autohide={true}
        showCloseButton={false}
        text={
          <div>
            <div>
              <strong>
                {`${stringHelpers.firstToUpper(venueFormName)} referral link`}
              </strong>
            </div>
            <div>Copied!</div>
          </div>
        }
        delay={alertDelayClose}
        onClose={() => setShowToast(false)}
        iconClassName="fal fa-check icon--large"
        className="referralToast"
        toastBodyClassName="referralToast__body"
      />
    </>
  );
};

VenueStatus.propTypes = {
  venue: venuePropType.isRequired,
  editing: PropTypes.bool.isRequired,
};

export default VenueStatus;
